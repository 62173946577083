import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, chatboxEllipses, chatbubbles, documentAttach, informationCircle, medical } from 'ionicons/icons';
import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import {useAuth} from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';
import usePageUnloadHandler from '../../../components/PageUnloadHandler';

interface Inquiry {
    _id: string,
    title: string,
    type: string,
    student?: Student,
    students?: string[],
    messages: Message[],
    updatedAt: Date
}

interface Message {
    timestamp: Date
}

interface Student {
    studentID: string,
    name: string
}

const ProcessedInquiries: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [inquiries, setInquiries] = useState < Inquiry[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);
    const [skip, setSkip] = useState < number > (0);
    const [total, setTotal] = useState < number > (0);
    const [isNot, setIsNot] = useState < boolean > (false);
    const [scrollPosition, setScrollPosition] = useState < number > (0);
    const scrollContent = useRef<HTMLIonContentElement | null> (null);
    const [initialRender, setInitialRender] = useState < boolean > (true);

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });
   
    const handleScrollEnd = async () => {
        const elem = await scrollContent.current?.getScrollElement();
        if(elem)
        {
            setScrollPosition(elem.scrollTop);
        }
    };

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const loadItems = async () => {
        let limit = skip+30;

        try {

            if ((authInfo.user.role === 'Teacher') || (authInfo.user.role === 'Employee' && !authInfo.user.roles.includes("Academic") && authInfo.user.roles.includes("Teacher")))
            {
                const result = await api.get(`/inquiries/listProcessedTeacher/${authInfo.user._id}?skip=${limit}`);
                if(result.data.inquiries && result.data.inquiries.length)
                {
                    setInquiries([...inquiries, ...result.data.inquiries]);
                    setSkip(limit);
                }
            }
            if ((authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic")))
            {
                const result = await api.get(`/inquiries/listProcessedInstitute/${authInfo.user.instituteID}?skip=${limit}`);
                if(result.data.inquiries && result.data.inquiries.length)
                {
                    setInquiries([...inquiries, ...result.data.inquiries]);
                    setSkip(limit);
                }
            }

        } catch (error: any) {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        }
        
    }

    useEffect(() => {

        const storedState = sessionStorage.getItem(history.location.pathname);
        if (history.action === "POP" && storedState) {
            const { inquiries, total, skip, scrollPosition } = JSON.parse(storedState);
            // Data is present in local storage, use it to initialize state
            setInquiries(inquiries);
            setTotal(total);
            setSkip(skip);
            
            setTimeout(() => scrollContent.current?.scrollToPoint(0, scrollPosition, 500), 500);
        } 
        else 
        {

            const fetchUp = async () => {
                setShowLoading(true);
                try {
                    if ((authInfo.user.role === 'Teacher') || (authInfo.user.role === 'Employee' && !authInfo.user.roles.includes("Academic") && authInfo.user.roles.includes("Teacher")))
                    {
                        const teacher = await api.get(`/employees/getByUID/${authInfo.user._id}`);
                        if(teacher.data.classID === "none")
                        {
                            setIsNot(true);
                        }
                        else
                        {
                            const result = await api.get(`/inquiries/listProcessedTeacher/${authInfo.user._id}`);
                            setInquiries(result.data.inquiries);
                            setTotal(result.data.total);
                        }
                    }
                    if ((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic")))
                    {
                        const result = await api.get(`/inquiries/listProcessedInstitute/${authInfo.user.instituteID}`);
                        setInquiries(result.data.inquiries);
                        setTotal(result.data.total);
                        setSkip(0);
                    }

                } catch (error: any) {
                    setMessage(error.response?.data.message || t("something_went_wrong"));
                    setIserror(true);
                } finally {
                    setShowLoading(false);
                }
                
            };

            fetchUp();
        }
        setInitialRender(false);

    }, [authInfo, refreshed, history.action, history.location.pathname]);

    // Save state to local storage whenever it changes
    useEffect(() => {
        if (!initialRender) 
        {
            const currentState = {
                inquiries,
                total,
                skip,
                scrollPosition
            };
            sessionStorage.setItem(history.location.pathname, JSON.stringify(currentState));
        
        }
    }, [inquiries, total, skip, scrollPosition, history.location.pathname, initialRender]);

    // Effect to handle page reload 
    usePageUnloadHandler();

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/communication`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("processed_inquiries")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                       <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade" ref={scrollContent} scrollEvents={true} onIonScrollEnd={handleScrollEnd}>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("processed_inquiries")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert
                    cssClass="first-alert"
                    isOpen={isNot}
                    onDidDismiss={() => {
                        history.replace("/app");
                    }}
                    header={t("unauthorized")}
                    message={t("unauth_msg")}
                    buttons={[`${t("close")}`]}
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>
                
                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}
                <IonGrid className="ion-no-padding mb-60">
                    <div className="ion-table">

                        <IonRow className="table-title ion-padding">
                            <IonCol size="2">
                                <IonIcon 
                                    icon={informationCircle}
                                    color="light"
                                    className='table-icon'
                                    />
                            </IonCol>
                            <IonCol sizeLg="4" size="9" className='table-heading'>
                                {t("title")}
                            </IonCol>

                            <IonCol sizeLg="2" size="0" className='table-heading ion-hide-lg-down'>
                                {t("type")}
                            </IonCol>
                            <IonCol sizeLg="3" size="0" className='table-heading ion-hide-lg-down'>
                                {t("student")}
                            </IonCol>
                            <IonCol size="1">
                                <IonIcon 
                                    icon={chatboxEllipses}
                                    color="light"
                                    className='table-icon'
                                    />
                            </IonCol>
                        </IonRow>

                            {(inquiries.length > 0) ? (inquiries.map((inquiry, i) => {
                                return (
                                    <IonRow key={inquiry._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}
                                        onClick={() => history.push(`/app/inquiry/${inquiry._id}`)}>
                                        
                                            <IonCol size="2" className='table-field'>
                                                <IonIcon
                                                    icon={((inquiry.type === "classwork") || (inquiry.type ===  "homework")) ? documentAttach : (inquiry.type ===  "leave") ? medical : chatbubbles}
                                                    className='table-icon'
                                                    color='secondary' />
                                            </IonCol>
                                            <IonCol sizeLg="4" size="9" className='table-field' >
                                                <IonRow>
                                                    <IonCol>
                                                        {inquiry.title}
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className='ion-hide-lg-up vid-responsive-field-1 text-capitalize'>
                                                    <IonCol>
                                                        <IonLabel>
                                                         {inquiry.student ? inquiry.student.name : `${t("students")}: ${inquiry.students!.length}`} &#8226; {inquiry.type}
                                                        </IonLabel>
                                                            
                                                    </IonCol>
                                                </IonRow>
                                                
                                            </IonCol>
                                         
                                            
                                            <IonCol sizeLg="2" size="0" className='table-field ion-hide-lg-down text-capitalize'>
                                                {inquiry.type}
                                            </IonCol>
                                            <IonCol sizeLg="3" size="0" className='table-field ion-hide-lg-down text-capitalize'>
                                                {inquiry.student ? inquiry.student.name : inquiry.students!.length}
                                            </IonCol>
                                            <IonCol size="1" className='table-field'>
                                                <IonBadge className=''>{inquiry.messages.length+1}</IonBadge>
                                            </IonCol>
                                        
                                    </IonRow>
                                )
                            })) : ( 
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_processed_inq")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )
                        } 
                    </div>

                    {(inquiries.length > 0) &&
                        <IonRow>
                            <IonCol>
                                <IonInfiniteScroll
                                    onIonInfinite={async (ev) => {
                                        await loadItems();
                                        ev.target.complete();
                                    }}
                                    >
                                    <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                            </IonCol>
                        </IonRow>
                    }

                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                <IonCol className="ion-padding-start footer-stats">
                                    {t("showing")} {total === 0 ? t("no_records") : `1 - ${inquiries.length} ${t("of")} ${total}`} 
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-vertical">
                                <IonCol className=''>
                                    <IonButton 
                                        onClick={
                                            () => history.push(`/app/inquiries/new`)
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("make_inquiry")}</IonButton>
                                    
                                    <IonButton 
                                        onClick={
                                            () => history.push(`/app/inquiries/pending`)
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("pending_inquiries")}</IonButton>

                                </IonCol>
                            </IonRow>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default ProcessedInquiries;