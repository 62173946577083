import { IonAlert, IonContent, IonLoading } from '@ionic/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import {useAuth} from '../../../components/AuthContext';
import CommonFormOne from './CommonFormOne';
import CommonFormTwo from './CommonFormTwo';
import CommonFormThree from './CommonFormThree';
import CommonFormFour from './CommonFormFour';

//classID is class/batch Name

interface ParamTypes {
    schoolID: string,
    classID: string,
    studentID: string
}

interface AdmnField {
    name: string,
    category: string,
    options?: string[],
    isMandatory: boolean,
    isActive: boolean,
    order: number
}


const CommonForm: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const {schoolID, classID, studentID} = useParams < ParamTypes > ();
    const [admnFields, setAdmnFields] = useState < AdmnField[] > ([]);
    const [step, setStep] = useState < number > (1);
    const [formData, setFormData] = useState < any > ({});

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
          'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const handleNext = (data: any) => {
        setFormData((prevFormData: any) => ({ ...prevFormData, ...data }));
        setStep((prevStep) => prevStep + 1);
    };
    
    const handlePrevious = (data: any) => {
        setFormData((prevFormData: any) => ({ ...prevFormData, ...data }));
        setStep((prevStep) => prevStep - 1);
    };
    
    const handleSubmit = (data: any) => {

        const inputData = { ...formData, ...data };
        setFormData(inputData);

        //Validate here email mobile pincode etc
        //Save details to admissionUser page, add application and proceed to checkout with returned application ID

        const addApplication = (formData: FormData) => {
            return new Promise((resolve, reject) => {
              api.post('/applications/add', formData).then(res => {
                return resolve(res.data.applicationID);
              }).catch(err => reject(err));
            });
        }

        let sendData = new FormData();

        sendData.append('instituteID', schoolID);
        sendData.append('userID', authInfo.user._id);
        sendData.append('childID', studentID);
        sendData.append('classID', classID);

        const keys = Object.keys(inputData);
        keys.forEach((key, index) => {
            if (inputData[key] instanceof Blob)
            {
                sendData.append('document[]', inputData[key], key);
            }
            else if (inputData[key] !== "")
            {
                sendData.append(key, inputData[key]);
            }
        });


        setShowLoading(true);
        addApplication(sendData)
        .then(data => {
            setShowLoading(false);
            history.push(`/app/application/${data}`);
        })
        .catch(error => {
            if (error.response !== undefined) 
                setMessage(error.response.data.message);
            else 
                setMessage(t("something_went_wrong"));
            setIserror(true);
            setShowLoading(false);
        });

    }

    useEffect(() => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {

                const insti = await api.get(`/institutes/${schoolID}`);

                let allFields: AdmnField[] = [];
                if(insti.data.admnFields && insti.data.admnFields.length > 0)
                {
                    allFields = insti.data.admnFields;
                }
                else
                {
                    const result = await api.get(`/masters/getFields`);
                    allFields = result.data.admissionFields;
                }
                setAdmnFields(allFields);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo, history, schoolID]);

    return (
        <IonContent>
            <IonLoading isOpen={showLoading}
                cssClass="first-loading"
                spinner="circular"
                message={t("please_wait")}
            />

            <IonAlert isOpen={iserror}
                cssClass="first-alert"
                onDidDismiss={
                    () => setIserror(false)
                }
                header={t("error")}
                message={message}
                buttons={
                    [`${t("close")}`]
                }
            />

            {step === 1 && <CommonFormOne onNext={handleNext} allFields={admnFields} formData={formData} studentID={studentID} schoolID={schoolID} classID={classID} />}
            {step === 2 && <CommonFormTwo onNext={handleNext} onPrevious={handlePrevious} allFields={admnFields} formData={formData} />}
            {step === 3 && <CommonFormThree onNext={handleNext} onPrevious={handlePrevious} allFields={admnFields} formData={formData} />}
            {step === 4 && <CommonFormFour onPrevious={handlePrevious} onSubmit={handleSubmit} allFields={admnFields} formData={formData} studentID={studentID} />}
            
        </IonContent>
    );
};

export default CommonForm;
