import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCheckbox,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import { InAppBrowser, InAppBrowserObject, InAppBrowserOptions } from '@ionic-native/in-app-browser';
import { checkboxOutline } from 'ionicons/icons';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../components/AuthContext";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';

interface Due {
    _id: string,
    remarks: string,
    amount: number,
    dueDate: Date,
    isChecked: boolean
}

interface Student {
    _id: string,
    name: string,
    classInfo: ClassInfo,
    isActive: boolean
}

interface ClassInfo {
    className: string,
    session: string
}

const Checkout: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [dues, setDues] = useState < Due[] > ([]);
    const [advance, setAdvance] = useState < number > (0);
    const [credit, setCredit] = useState < number > (0);
    const [adjusted, setAdjusted] = useState < number > (0);
    const [student, setStudent] = useState < Student > ();
    const [fileName, setFileName] = useState < string > ("");
    const [institute, setInstitute] = useState < string > ("");

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const formatDated = (value: Date) => {

        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
 
    };

    const selectAll = () => {

        let duess = [...dues];
        const value = duess[0].isChecked ? false : true;
        for (let i = 0; i < duess.length; i++)
        {
            duess[i].isChecked = value;
        }
        setDues(duess);

    }

     const handleChecked = (id : number) => {

        let comps = [...dues];
        comps[id].isChecked = !comps[id].isChecked;
        setDues(comps);

    }

    const getTotal = () => {
        let sum: number = 0;
        for (let i = 0; i < dues.length; i++)
        {
            if (dues[i].isChecked)
            {
                sum += dues[i].amount;
            }
        }

        sum += advance;
        return sum;
    }

    const options: InAppBrowserOptions = {
        zoom: 'no',
        location: 'no',
        toolbar: 'no',
        hidden: 'no',
        hideurlbar: 'yes',
        hidenavigationbuttons: 'yes'
    }

    const handleSubmit = () => {

        if (!student) {
            setMessage(t("payment_initiate_error"));
            setIserror(true);
            return;
        }

        if (getTotal() === 0)
        {
            setMessage(t("no_collection"));
            setIserror(true);
            return;
        }

        const userID = authInfo.user._id;
        const netPayable = getTotal() - adjusted;
        const studentID = student._id;

        const doPay = () => {
            return new Promise((resolve, reject) => {
              api.post('/payments/new', { userID, netPayable, studentID, dues, advance, adjusted, 'type': 'due' }).then(res => {
                return resolve(res.data);
              }).catch(err => reject(err));
            });
        }
            setShowLoading(true);

            doPay()
            .then((data: any) => {
            setShowLoading(false);

            let browser : InAppBrowserObject;

            // _blank for iphone, _self for others
            if (isPlatform('capacitor') && isPlatform('ios'))
            {
                browser = InAppBrowser.create(String(data.URLpay), '_blank', options);
            }
            else
            {
                browser = InAppBrowser.create(String(data.URLpay), '_self', options);
            }

            browser.on('loadstart').subscribe(event => {

                const res = event.url.split("/"); 
                if(res[4] === 'success' || res[4] === 'failed')
                {
                    history.replace(`/app/${res[4]}/${res[5]}`);
                    browser.close();
                    
                }
            
            });

            browser.on('loaderror').subscribe(() => {
                history.replace(`/app/failed/${data.paymentID}`);
                browser.close();

            });

            browser.on('exit').subscribe(() => {
                if(window.location.pathname === '/app/pay')
                {
                    history.replace(`/app/failed/${data.paymentID}`);
                }
            });

        })
        .catch(error => {
            if (error.response !== undefined)
            {
                setMessage(error.response.data.message);
                setIserror(true);
            }
            setShowLoading(false);

        });
    }

    // For Adjustments from Student Credits
    useEffect(() => {
    
        let sum: number = 0;
        for (let i = 0; i < dues.length; i++)
        {
            if (dues[i].isChecked)
            {
                sum += dues[i].amount;
            }
        }
        
        if (sum <= credit)
        {
            setAdjusted(sum);
        }
        else
        {
            setAdjusted(credit);
        }

    }, [dues, credit]);
   
    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const res = await api.get(`/institutes/${authInfo.user.instituteID}`);
                setFileName(res.data.logo);
                setInstitute(res.data.name);

                const result = await api.get(`/students/getByParent/${authInfo.user._id}`);
                setStudent(result.data);
                if(result.data.credit > 0) setCredit(result.data.credit);

                const stud = await api.get(`/dues/pending/${result.data._id}`);

                let duess = stud.data;

                for (let i = 0; i < duess.length; i++)
                {
                    duess[i].isChecked = true;
                }
                setDues(duess);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchData();

    }, [authInfo]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/home`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("checkout")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("checkout")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className='mb-60'>
                  
                    {student && (
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard className="ion-margin-vertical class-card">

                                <IonCardContent className="ion-no-padding">
                                        <IonRow>
                                            <IonCol size="9">
                                                <IonRow className="pb-08">
                                                    <IonCol>
                                                        <p><span className="class-name" >{institute}</span></p>
                                                        
                                                        <p><span className="class-info">{student.classInfo.className} ({student.classInfo.session})</span></p><br/>
                                                        <p><span className="class-info-2 text-capitalize">{t("paying_for")} {student.name}</span></p>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCol>
                                            <IonCol size="3" className="ion-text-center">
                                            <div className="vertical-container">
                                                <div className="vertical-center"> 
                                                {fileName && (fileName !== "") ?
                                                    (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${fileName}`} alt={institute} width="60" />)
                                                    : (<img src={`assets/images/institute.png`} alt="Logo" width="60" />)
                                                }
                                                </div>
                                            </div>        
                                        </IonCol>
                                        </IonRow>
                                </IonCardContent>
                            </IonCard>

                        </IonCol>
                    </IonRow>
                    )}

                    <IonRow className="ion-margin-bottom">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12" className="ion-no-padding">
                      
                            <IonCard className="ion-no-margin">
                                <IonCardContent className="ion-no-padding">
                                    <IonRow className="table-title">
                                        <IonCol size="6" className='table-heading mt-05'>
                                            <IonIcon 
                                                icon={checkboxOutline}
                                                color="light"
                                                className='table-icon'
                                                onClick={selectAll}
                                            />
                                            <IonText className='ion-margin-start'>{t("remarks")}</IonText>
                                        </IonCol>
                                        <IonCol size="3" className='table-heading mt-05'>
                                                {t("due_date")}
                                        </IonCol>
                                        <IonCol size="3" className='table-heading ion-text-right mt-05 ion-padding-end'>
                                                {t("fee_amount")}
                                        </IonCol>
                                    </IonRow>
                                    {(dues.length > 0) && (dues.map((due, i) => {
                                        return (
                                            <IonRow key={due._id} className={((i%2) === 0) ? 'row-table' : 'row-table-alt'}>
                                                    <IonCol size="6" className='table-field' >
                                                        <IonCheckbox labelPlacement="end" value={due._id} checked={due.isChecked}
                                                            onClick={e => handleChecked(i)}>
                                                            <span className="ion-text-wrap">{due.remarks}</span>
                                                        </IonCheckbox>
                                                    </IonCol>

                                                    <IonCol size="3" className='table-field' >
                                                        {formatDated(due.dueDate)}
                                                    </IonCol>

                                                    <IonCol size="3" className='table-field ion-text-right ion-padding-end' >
                                                        {due.amount}
                                                    </IonCol>
                                              
                                            </IonRow>
                                        )
                                    }))}
                                    <IonRow className='row-table'>
                                        
                                        <IonCol size="8" className='table-field ion-padding-end'>
                                           {t("paying_advance")}
                                        </IonCol>
                                        
                                        <IonCol size="4" className='table-field'>
                                            <IonItem className='fee-comp-height'>
                                                <IonInput type="number"
                                                    className='ion-text-right'
                                                    value={advance}
                                                    onIonInput={
                                                        (e) => e.detail.value && setAdvance(Math.abs(parseInt(e.detail.value)))
                                                    }
                                                    />
                                            </IonItem>
                                        </IonCol>
    
                                    </IonRow>
                                    <IonRow className='row-table'>
                                        
                                        <IonCol size="8" className='table-field ion-padding-end'>
                                            {t("amount_adjusted")}
                                        </IonCol>
                                        
                                        <IonCol size="4" className='table-field'>
                                            <IonItem className='fee-comp-height'>
                                                <IonInput type="number"
                                                    className='ion-text-right'
                                                    value={adjusted}
                                                    disabled={true}
                                                    />
                                            </IonItem>
                                        </IonCol>
    
                                    </IonRow>
                                 
                                </IonCardContent>
                            </IonCard>
                                      
                        </IonCol>
                    </IonRow>


                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol size="4" className='ion-text-center'>
                                
                            <p className='checkout-label'>
                            {t("total_amount")}
                            </p>
                            <p className='checkout-amt'>
                            &#8377; {getTotal()-adjusted}
                            </p>
                               

                        </IonCol>
                        <IonCol size="8" className="ion-text-right">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear"
                                >{t("make_payment")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
}

export default Checkout;
