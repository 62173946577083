import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCheckbox,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { addCircle, alertCircleOutline, closeCircle, informationCircle, receipt, trashOutline } from 'ionicons/icons';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';
import usePageUnloadHandler from '../../../components/PageUnloadHandler';

interface Invoice {
    invoiceDate?: string,
    dueDate?: string,
    dueComponents: Component[],
    isActive: boolean
}

interface Tran {
    _id: string,
    amount: number,
    invoiceDate: Date,
    dueDate: Date,
    dueComponents: Component[],
    paidAt?: Date
}

interface Component {
    amount: number,
    head?: FeeHead,
    frequency?: string,
    duration?: string,
    remarks?: string,
    isChecked?: boolean
}

interface FeeHead {
    headID: string,
    name: string
}

interface Session {
    _id: string,
    name: string,
    isActive: boolean
}

interface ClassInterface {
    _id: string,
    name: string,
    year: string,
    isActive: boolean
}

interface Student {
    _id: string,
    name: string,
    isActive: boolean
}

interface ParamTypes {
    studentID?: string
}

const AddDues: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {studentID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [session, setSession] = useState < string > ("");
    const [classes, setClasses] = useState < ClassInterface[] > ([]);
    const [classi, setClassi] = useState < string > ("");
    const [students, setStudents] = useState < Student[] > ([]);
    const [selectedStudent, setSelectedStudent] = useState <string> ("");
    const [components, setComponents] = useState < Component[] > ([]);
    const [invoices, setInvoices] = useState < Invoice[] > ([{invoiceDate: (new Date(Date.now())).toISOString(), isActive: true, dueComponents: []}]);
    const [transactions, setTransactions] = useState < Tran[] > ([]);
    const [initialRender, setInitialRender] = useState < boolean > (true);
    const [ispass2, setIspass2] = useState < boolean > (false);
    const [pass2, setPass2] = useState < string > ("");
    const [isOpen, setIsOpen] = useState(false);
    const [text, setText] = useState < string > ("");

    // Create an instance of axios with default settings
    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });
    
    const formatDated = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB');
    };

    const formatDate = (value: string | string[] | undefined) => {
        if (typeof value === "string") 
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
        else
        {
            return t("pick_date");
        }
    };

    const handleRemoval = (index: number) => {
        let comps = [...invoices];
        if (index > -1) {
            comps.splice(index, 1); // 2nd parameter means remove one item only
          }
          setInvoices(comps);
    };

    const handleNew = () => {

        let dueComponents: Component[] = [];
        for (let id=0; id < components.length; id++)
        {
            if (components[id].isChecked)
            {
                dueComponents.push({
                    head: components[id].head,
                    frequency: components[id].frequency,
                    amount: components[id].amount
                });
            }
        }
        
        let comps = [...invoices];
        let modifiedComps = comps.map(obj => ({ ...obj, isActive: false }));
        modifiedComps.push({invoiceDate: (new Date(Date.now())).toISOString(), isActive: true, dueComponents});
        setInvoices(modifiedComps);
    };

    const handleActive = (index: number) => {
        let comps = [...invoices];
        let modifiedComps = comps.map(obj => ({ ...obj, isActive: false }));
        modifiedComps[index].isActive = true;
        setInvoices(modifiedComps);
    };

    const handleInvoiceDate = (value: string | string[] | undefined, index: number) => {
        if (typeof value === "string") 
        {
            let studs = [...invoices];
            studs[index].invoiceDate = value;
            setInvoices(studs);
        }
    };

    const handleDueDate = (value: string | string[] | undefined, index: number) => {
        if (typeof value === "string") 
        {
            let studs = [...invoices];
            studs[index].dueDate = value;
            setInvoices(studs);
        }
    };

    const handleChecked = (value: boolean | undefined, id : number) => {

        if (value)
        {
            let invs = [...invoices];
            let invoiceIndex = invs.findIndex(val => val.isActive === true);
            if (invoiceIndex > -1) {
                let index = invs[invoiceIndex].dueComponents.findIndex(val => val.head === components[id].head);
                if (index > -1) {
                    invs[invoiceIndex].dueComponents.splice(index, 1); // 2nd parameter means remove one item only
                }
            }
            setInvoices(invs);

            let compos = [...components];
            compos[id].isChecked = false;
            setComponents(compos);
        }

        if (!value)
        {
            let invs = [...invoices];
            let invoiceIndex = invs.findIndex(val => val.isActive === true);
            if (invoiceIndex > -1) {
                let index = invs[invoiceIndex].dueComponents.findIndex(val => val.head === components[id].head);
                if (index < 0) {
                    invs[invoiceIndex].dueComponents.push({
                        head: components[id].head,
                        frequency: components[id].frequency,
                        amount: components[id].amount
                    });
                }
            }
            setInvoices(invs);

            let compos = [...components];
            compos[id].isChecked = true;
            setComponents(compos);
        }
    };

    const handleAmount = (amt: string, index: number, invoiceIndex: number) => {
        let comps = [...invoices];
        comps[invoiceIndex].dueComponents[index].amount = parseInt(amt);
        setInvoices(comps);
    };

    const handleDuration = (dur: string, index: number, invoiceIndex: number) => {
        let comps = [...invoices];
        comps[invoiceIndex].dueComponents[index].duration = dur;
        setInvoices(comps);
    };
        
    const handleRemarks = (freq: string, index: number, invoiceIndex: number) => {
        let comps = [...invoices];
        comps[invoiceIndex].dueComponents[index].remarks = freq;
        setInvoices(comps);
    };

    const handleDelete = (index: number, invoiceIndex: number) => {
        let comps = [...invoices];
        if (index > -1) {
            comps[invoiceIndex].dueComponents!.splice(index, 1); // 2nd parameter means remove one item only
          }
        setInvoices(comps);
    };

    const handleAdd = (index: number) => {
        let compos = [...invoices];
        compos[index].dueComponents.push({remarks: "", amount: 0});
        setInvoices(compos);
    };

    const handleSubmit = () => {

        if(!selectedStudent || selectedStudent === "")
        {
            setMessage(t("student_mandatory"));
            setIserror(true);
            return;
        }

        if (invoices.length < 1)
        {
            setMessage(t("invoices_mandatory"));
            setIserror(true);
            return;
        }

        for (let i = 0; i < invoices.length; i++)
        {
            if (typeof invoices[i].dueDate !== "string")
            {
                setMessage(t("due_date_mandatory"));
                setIserror(true);
                return;
            }
    
            if (typeof invoices[i].invoiceDate !== "string")
            {
                setMessage(t("invoice_date_mandatory"));
                setIserror(true);
                return;
            }

            // check for due date less than invoice date

            for (let j = 0; j < invoices[i].dueComponents.length; j++)
            {
                if (invoices[i].dueComponents[j].amount < 1)
                {
                    setMessage(t("dues_invalid"));
                    setIserror(true);
                    return;
                }
                if (invoices[i].dueComponents[j].head)
                {
                    if (!invoices[i].dueComponents[j].duration || invoices[i].dueComponents[j].duration === "") {
                        setMessage(t("dues_invalid"));
                        setIserror(true);
                        return;
                    } 
                }
                if (!invoices[i].dueComponents[j].head)
                {
                    if (!invoices[i].dueComponents[j].remarks || invoices[i].dueComponents[j].remarks === "") {
                        setMessage(t("dues_invalid"));
                        setIserror(true);
                        return;
                    }
                }
            }
        }

        const studentName = students.find(val => val._id === selectedStudent)!.name;
  
        setShowLoading(true);
        api.post(`/invoices/add`, { 'instituteID': authInfo.user.instituteID, 'studentID': selectedStudent, studentName, 'adminID': authInfo.user._id, invoices })
        .then(res => {
          
          setPass(t("invoices_generated")+res.data.total);
          setIspass(true);
        })
        .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));
        
    }

    const handleSession = (sessionName : string) => {

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${sessionName}`);
                setClasses(result.data);
                setShowLoading(false);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();
        setSession(sessionName);
        setClassi("");
        setSelectedStudent("");
        setStudents([]);
        setComponents([]);
    }

    const handleClassi = (classID : string) => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/students/getByClass/${classID}`);
                setStudents(studs.data);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        fetchUp();
        setClassi(classID);
        setSelectedStudent("");
        setComponents([]);
    }

    const handleStudent = (studentID : string) => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const stud = await api.get(`/students/${studentID}`);
                if (stud.data.ledgerInfo) {
                    setText(stud.data.ledgerInfo);
                } else {
                    setText("");
                }
                if (stud.data.feeComponents)
                {
                    setComponents(stud.data.feeComponents);
                }
                const inv = await api.get(`/invoices/getFiveByStudent/${studentID}`);
                setTransactions(inv.data);


            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        fetchUp();
        setSelectedStudent(studentID);
    }

    const handleLedger = () => {

        if (!text || text === "") {
            setMessage(t("remarks_mandatory"));
            setIserror(true);
            return;
        }

        setShowLoading(true);
        api.put(`/students/${selectedStudent}`, { ledgerInfo: text })
        .then(res => {
            setPass2(t("ledger_remarks")+t("has_been_updated"));
            setIspass2(true);
        })
        .catch((error: any) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));
    }

    useEffect(() => {

        const storedState = sessionStorage.getItem(history.location.pathname);
        if (history.action === "POP" && storedState) {
            const { students, sessions, classes, session, classi, selectedStudent, components, transactions, invoices, text } = JSON.parse(storedState);
            // Data is present in local storage, use it to initialize state
            setStudents(students);
            setClasses(classes);
            setSessions(sessions);
            setSession(session);
            setClassi(classi);
            setSelectedStudent(selectedStudent);
            setComponents(components);
            setTransactions(transactions);
            setInvoices(invoices);
            setText(text);
        } 
        else 
        {

            const fetchData = async () => {
                setShowLoading(true);
                try {

                    const res = await api.get(`/sessions/getAllInstitute/${authInfo.user.instituteID}`);
                    setSessions(res.data);

                    if (studentID)
                    {
                        const stud = await api.get(`/students/${studentID}`);
                        if (stud.data.ledgerInfo) {
                            setText(stud.data.ledgerInfo);
                        }
                        if (stud.data.feeComponents)
                        {
                            setComponents(stud.data.feeComponents);
                        }
                        const classInfo = await api.get('/classes/' + stud.data.classID);
                        setSession(classInfo.data.sessionID);

                        const query = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${classInfo.data.sessionID}`);
                        setClasses(query.data);
                        setClassi(stud.data.classID);
                        const studs = await api.get(`/students/getByClass/${stud.data.classID}`);
                        setStudents(studs.data);
                        setSelectedStudent(studentID);

                        const inv = await api.get(`/invoices/getFiveByStudent/${studentID}`);
                        setTransactions(inv.data);
                    }
                    else
                    {
                        setComponents([]);
                        setSession("");
                        setClasses([]);
                        setClassi("");
                        setStudents([]);
                        setSelectedStudent("");
                        setTransactions([]);
                        setText("");
                    }

                } catch (error: any) {
                    setMessage(error.response?.data.message || t("something_went_wrong"));
                    setIserror(true);
                } finally {
                    setShowLoading(false);
                }
            };

        fetchData();
        }
     
        setInitialRender(false);

    }, [authInfo, studentID, history.action, history.location.pathname]);
    
    // Save state to local storage whenever it changes
     useEffect(() => {
        if (!initialRender) 
        {
            const currentState = {
                students,
                sessions,
                classes,
                session,
                classi,
                selectedStudent,
                components,
                transactions,
                invoices,
                text
            };
            sessionStorage.setItem(history.location.pathname, JSON.stringify(currentState));
        }
    }, [students, sessions, classes, session, classi, selectedStudent, components, transactions, invoices, text, history.location.pathname, initialRender]);

    // Effect to handle page reload 
    usePageUnloadHandler();
  
    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/accounting`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("add_dues_student")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("add_dues_student")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                
                 <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => (studentID ? history.push(`/app/collection/${studentID}`) : history.goBack())
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass2}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass2(false)
                    }
                    header={t("success")}
                    message={pass2}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

            <IonModal isOpen={isOpen} showBackdrop={true} onDidDismiss={() => setIsOpen(false)}
                initialBreakpoint={0.6} breakpoints={[0, 0.6]} handle={true}>

                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                    <IonToolbar>
                        <IonTitle>{t("ledger_remarks")}</IonTitle>
                    </IonToolbar>
                    
                    
                    <IonRow>
                        <IonCol>
                            <IonCard className='ion-no-margin ion-margin-horizontal'>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        <IonLabel position="floating" className="input-label" color="secondary">{t("ledger_remarks")}</IonLabel>
                                            <IonTextarea rows={6} value={text} onIonInput={(e) => setText(e.detail.value!)}></IonTextarea>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ion-padding-horizontal ion-text-center">
                            <IonButton onClick={handleLedger}
                                className="first-button"
                                fill="clear"
                                >{t("save")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonContent>
                
            </IonModal>

                <IonGrid className="ion-no-padding mb-60">
                    <IonRow className="max-xxl">
                        <IonCol sizeLg="6" size="12" className='ion-padding-horizontal'>
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("select_student")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className='mt-30'>
                                <IonCol>
                                    <IonRow>
                                        <IonCol size="6">
                                            <IonCard>
                                                <IonCardContent className="ion-no-padding">
                                                    <IonItem>
                                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                            {t("class_session")}</IonLabel>
                                                            <IonSelect value={session}
                                                                    className="input-field"
                                                                    cancelText={t("cancel")}
                                                                    okText={t("ok")}
                                                                    placeholder={t("class_session")}
                                                                    onIonChange={
                                                                        (e) => handleSession(e.detail.value)
                                                                }>

                                                                    {sessions.map((session) => (
                                                                        (session.isActive) && 
                                                                        (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                                    ))}

                                                            </IonSelect>
                                                        </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        <IonCol size="6">
                                            <IonCard>
                                                <IonCardContent className="ion-no-padding">
                                                    <IonItem>
                                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}</IonLabel>
                                                            <IonSelect value={classi}
                                                                    className="input-field"
                                                                    cancelText={t("cancel")}
                                                                    okText={t("ok")}
                                                                    placeholder={(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}
                                                                    onIonChange={
                                                                        (e) => handleClassi(e.detail.value)
                                                                }>
                                                                    
                                                                    {classes.map((classy) => (
                                                                        (classy.isActive) &&
                                                                        (<IonSelectOption key={classy._id} value={`${classy._id}`}>{classy.name}</IonSelectOption>)
                                                                    ))}
                                                            </IonSelect>
                                                        </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>

                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">                   
                                            <IonItem
                                            detail={false}
                                            lines='full'
                                            button={false}>
                                                <IonLabel position="stacked" className="input-label" color="secondary">{t("select_student")}</IonLabel>
                                                <IonSelect value={selectedStudent}
                                                            className="input-field text-capitalize"
                                                            cancelText={t("cancel")}
                                                            okText={t("ok")}
                                                            placeholder={t("select_student")}
                                                            onIonChange={
                                                                (e) => e.detail.value && handleStudent(e.detail.value)
                                                        }>
                                                        { students.map((student) => 
                                                                (student.isActive) &&
                                                               (<IonSelectOption key={student._id} value={`${student._id}`} className="text-capitalize">{student.name}</IonSelectOption>)
                                                        )}
                                                </IonSelect>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("fee_struct_comps")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="mt-30">
                                <IonCol>
                                <div className="ion-table">

                                    <IonRow className="table-title ion-padding">
                                        <IonCol size="5" className='table-heading heading-fixed mt-05'>
                                                {t("fee_head")}
                                        </IonCol>
                                        <IonCol offset="1" size="3" className='table-heading mt-05'>
                                                {t("fee_frequency")}
                                        </IonCol>
                                        <IonCol size="3" className='table-heading ion-text-right mt-05 ion-padding-end'>
                                                {t("fee_amount")}
                                        </IonCol>
                                    </IonRow>

                                    {(components.length > 0) ? (components.map((component, i) => 
                                        (component.head) && 
                                        (
                                            <IonRow key={component.head.headID} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}>
                                                    <IonCol size="5" className='table-field overflow-hide' >
                                                        
                                                            <IonCheckbox labelPlacement="end" value={component.head.headID} checked={component.isChecked} 
                                                                onClick={e => handleChecked(component.isChecked, i)}>
                                                                {component.head.name}
                                                            </IonCheckbox>
                                                       
                                                    </IonCol>

                                                    <IonCol offset="1" size="3" className='table-field text-capitalize' >
                                                        {component.frequency}
                                                    </IonCol>

                                                    <IonCol size="3" className='table-field ion-text-right ion-padding-end' >
                                                        {component.amount}
                                                    </IonCol>
                                              
                                            </IonRow>
                                        )
                                    )) : ( 
                                    <IonRow>
                                        <IonCol>
                                            <IonCard>
                                                <IonCardContent>
                                                    <IonItem lines="none">
                                                        <IonIcon icon={alertCircleOutline}
                                                            slot="start"
                                                            color="danger" />
                                                        <IonLabel className="list-title"><h3>{t("no_fee_comps")}</h3></IonLabel>

                                                    </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                    )
                                } 

                                </div>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol className="ion-text-center">
                                    <IonButton  color="primary" onClick={() => history.push(`/app/fees/student/${selectedStudent}`)}>{t("edit_fee_struct")}</IonButton> 
                                    {(selectedStudent !== "") && (<IonButton fill="clear" onClick={() => setIsOpen(true)}>{t("ledger_remarks")}</IonButton> )}
                                </IonCol>
                            </IonRow>
                        </IonCol>
                        <IonCol sizeLg="6" size="12" className='ion-padding-horizontal'>
                            <IonRow className='mt-30'>
                                <IonCol className='border-bottom-sec ion-padding-bottom'>
                                
                                    <IonRow>
                                        <IonCol size="10" className='ion-padding-horizontal mt-05'>
                                            <IonText className='list-title'>
                                            {t("new_invoices")}
                                            </IonText>
                                        </IonCol>
                                        
                                        <IonCol size="2" className='ion-text-center'>
                                            
                                                <IonIcon size="large"
                                                    icon={addCircle}
                                                    color='secondary'
                                                    onClick={handleNew}
                                                />
                                            
                                            
                                        </IonCol>
                                    </IonRow>
                                        
                                </IonCol>
                            </IonRow>

                            {(invoices.length > 0) ? (invoices.map((invoice, index) => {
                            return (
                            <IonRow key={index}>
                                <IonCol>
                                    <IonCard className='ion-no-margin'>
                                        {!(invoice.isActive) && (
                                        <IonCardHeader onClick={() => handleActive(index)}>
                                            <IonCardSubtitle className='info-subtitle'>
                                                <IonRow>
                                                    <IonCol size="8" className='mt-05 text-bold'>
                                                        {t("invoice")} #{index+1}
                                                    </IonCol>
                                                    <IonCol size="3" className='ion-text-right mt-05 ion-padding-end'>
                                                        {t("rs")} {invoice.dueComponents.reduce((acc, curr) => { 
                                                            return (acc + curr.amount);
                                                        }, 0)} 
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardSubtitle>
                                        </IonCardHeader>
                                        )}
                                        {(invoice.isActive) && (
                                        <>
                                        <IonCardHeader>
                                                <IonCardSubtitle className='info-subtitle text-bold'>{t("invoice")} #{index+1}
                                                <div className='title-icon-right'>
                                                    <IonIcon icon={trashOutline} color="danger" onClick={() => handleRemoval(index)} />
                                                </div>
                                            </IonCardSubtitle>
                                        </IonCardHeader>
                                        <IonCardContent className="ion-no-padding ion-margin-bottom">
                                            <IonRow>
                                                <IonCol size="6">
                                                    <IonCard>
                                                        <IonCardContent className="ion-no-padding">
                                                            <IonItem detail={false}
                                                                    lines='full'
                                                                    button={false}
                                                                    id={`open-invoice-${index}`}>
                                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                                        {t("invoice_date")}*
                                                                    </IonLabel>
                                                                    <IonInput value={formatDate(invoice.invoiceDate)} className="input-field" readonly={true} />
                                                                    <IonModal trigger={`open-invoice-${index}`} showBackdrop={true} 
                                                                        initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                                        <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                                            <IonToolbar>
                                                                                <IonTitle>{t("invoice_date")}</IonTitle>
                                                                                
                                                                            </IonToolbar>
                                                                            <IonDatetime
                                                                                value={invoice.invoiceDate}
                                                                                presentation="date"
                                                                                cancelText={t("cancel")}
                                                                                doneText={t("done")}
                                                                                onIonChange={ev => handleInvoiceDate(ev.detail.value!, index)}
                                                                                showDefaultButtons={true}
                                                                                size="cover"
                                                                            />
                                                                        </IonContent>
                                                                        
                                                                    </IonModal>
                                                                </IonItem>
                                                        </IonCardContent>
                                                    </IonCard>
                                                </IonCol>
                                                <IonCol size="6">
                                                    <IonCard>
                                                        <IonCardContent className="ion-no-padding">
                                                            <IonItem detail={false}
                                                                    lines='full'
                                                                    button={false}
                                                                    id={`open-due-${index}`}>
                                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                                        {t("due_date")}*
                                                                    </IonLabel>
                                                                    <IonInput value={formatDate(invoice.dueDate)} className="input-field" readonly={true} />
                                                                    <IonModal trigger={`open-due-${index}`} showBackdrop={true} 
                                                                        initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                                        <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                                            <IonToolbar>
                                                                                <IonTitle>{t("due_date")}</IonTitle>
                                                                                
                                                                            </IonToolbar>
                                                                            <IonDatetime
                                                                                value={invoice.dueDate}
                                                                                presentation="date"
                                                                                cancelText={t("cancel")}
                                                                                doneText={t("done")}
                                                                                onIonChange={ev => handleDueDate(ev.detail.value!, index)}
                                                                                showDefaultButtons={true}
                                                                                size="cover"
                                                                            />
                                                                        </IonContent>
                                                                        
                                                                    </IonModal>
                                                                </IonItem>
                                                        </IonCardContent>
                                                    </IonCard>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className="ion-margin-top">
                                                <IonCol>
                                                    <div className="ion-table">

                                                    <IonRow className="table-title ion-padding">
                                                        <IonCol size="4" className='table-heading mt-05'>
                                                                {t("due_head")}
                                                        </IonCol>
                                                        <IonCol size="4" className='table-heading mt-05'>
                                                                {t("due_duration")}
                                                        </IonCol>
                                                        <IonCol size="3" className='table-heading ion-text-right mt-05 ion-padding-end'>
                                                                {t("due_amount")}
                                                        </IonCol>

                                                        <IonCol size="1">
                                                            <IonIcon 
                                                                icon={addCircle}
                                                                color="light"
                                                                className='table-icon ml-05'
                                                                size="large"
                                                                onClick={() => handleAdd(index)}
                                                                />
                                                        </IonCol>
                                                    </IonRow>
                                                
                                                    {(invoice.dueComponents.length > 0) ? (invoice.dueComponents.map((due, i) => 
                                                        (
                                                            <IonRow key={i} className='row-table ion-padding-horizontal'>
                                                                {due.head && (
                                                                    <>
                                                                    <IonCol size="4" className='table-field ion-padding-top' >
                                                                    {due.head.name}
                                                                    </IonCol>
                                                                    <IonCol size="4" className='table-field ion-padding-end' >
                                                                        <IonItem>
                                                                            {(due.frequency === "monthly") && (
                                                                            <IonSelect placeholder={t("due_duration")}
                                                                                className='ion-no-padding' 
                                                                                style={{'maxWidth': '100%'}} 
                                                                                cancelText={t("cancel")}
                                                                                okText={t("ok")}
                                                                                value={due.duration}
                                                                                onIonChange={
                                                                                    (e) => handleDuration(e.detail.value!, i, index)
                                                                                }
                                                                                >
                                                                                <IonLabel>{t("due_duration")}</IonLabel>
                                                                                <IonSelectOption value="Jan">{t("jan")}</IonSelectOption>
                                                                                <IonSelectOption value="Feb">{t("feb")}</IonSelectOption>      
                                                                                <IonSelectOption value="Mar">{t("mar")}</IonSelectOption>   
                                                                                <IonSelectOption value="Apr">{t("apr")}</IonSelectOption>   
                                                                                <IonSelectOption value="May">{t("may")}</IonSelectOption>
                                                                                <IonSelectOption value="Jun">{t("jun")}</IonSelectOption>      
                                                                                <IonSelectOption value="Jul">{t("jul")}</IonSelectOption>   
                                                                                <IonSelectOption value="Aug">{t("aug")}</IonSelectOption>   
                                                                                <IonSelectOption value="Sep">{t("sep")}</IonSelectOption>
                                                                                <IonSelectOption value="Oct">{t("oct")}</IonSelectOption>      
                                                                                <IonSelectOption value="Nov">{t("nov")}</IonSelectOption>   
                                                                                <IonSelectOption value="Dec">{t("dec")}</IonSelectOption>   
                                                                            </IonSelect>
                                                                            )}
                                                                            {(due.frequency === "quaterly") && (
                                                                            <IonSelect placeholder={t("due_duration")}
                                                                                className='ion-no-padding' 
                                                                                style={{'maxWidth': '100%'}} 
                                                                                cancelText={t("cancel")}
                                                                                okText={t("ok")}
                                                                                value={due.duration}
                                                                                onIonChange={
                                                                                    (e) => handleDuration(e.detail.value!, i, index)
                                                                                }
                                                                                >
                                                                                <IonLabel>{t("due_duration")}</IonLabel>
                                                                                <IonSelectOption value="Jan-Mar">{t("q4")}</IonSelectOption>
                                                                                <IonSelectOption value="Apr-Jun">{t("q1")}</IonSelectOption>
                                                                                <IonSelectOption value="Jul-Sep">{t("q2")}</IonSelectOption>      
                                                                                <IonSelectOption value="Oct-Dec">{t("q3")}</IonSelectOption>
                                                                                <IonSelectOption value="Feb-Apr">{t("q5")}</IonSelectOption>
                                                                                <IonSelectOption value="Mar-May">{t("q6")}</IonSelectOption>      
                                                                                <IonSelectOption value="May-Jul">{t("q7")}</IonSelectOption>   
                                                                                <IonSelectOption value="Jun-Aug">{t("q8")}</IonSelectOption> 
                                                                                <IonSelectOption value="Aug-Oct">{t("q9")}</IonSelectOption>
                                                                                <IonSelectOption value="Sep-Nov">{t("q10")}</IonSelectOption>
                                                                                <IonSelectOption value="Nov-Jan">{t("q11")}</IonSelectOption>      
                                                                                <IonSelectOption value="Dec-Feb">{t("q12")}</IonSelectOption>  
                                                                                
                                                                            </IonSelect>
                                                                            )}
                                                                            {(due.frequency === "annually") && (
                                                                            <IonSelect placeholder={t("due_duration")}
                                                                                className='ion-no-padding' 
                                                                                style={{'maxWidth': '100%'}} 
                                                                                cancelText={t("cancel")}
                                                                                okText={t("ok")}
                                                                                value={due.duration}
                                                                                onIonChange={
                                                                                    (e) => handleDuration(e.detail.value!, i, index)
                                                                                }
                                                                                >
                                                                                <IonLabel>{t("due_duration")}</IonLabel>
                                                                                {sessions.map((session) => (
                                                                                    (session.isActive) && 
                                                                                    (<IonSelectOption key={session._id} value={session.name}>{session.name}</IonSelectOption>)
                                                                                ))}
                                                                            </IonSelect>
                                                                            )}
                                                                            {(due.frequency === "once") && (
                                                                            <IonInput type="text"
                                                                                 placeholder={t("due_remarks")}
                                                                                 value={due.duration}
                                                                                 onIonInput={
                                                                                     (e) => handleDuration(e.detail.value!, i, index)
                                                                             }></IonInput>
                                                                            
                                                                            )}
                                                                            
                                                                        </IonItem>
                                                                    
                                                                    </IonCol>
                                                                    </>
                                                                )}
                                                                {!due.head && (
                                                                <IonCol size="8" className='table-field ion-padding-end'>
                                                                    <IonItem className='fee-comp-height'>
                                                                        <IonInput type="text"
                                                                            placeholder={t("due_remarks")}
                                                                            value={due.remarks}
                                                                            onIonInput={
                                                                                (e) => handleRemarks(e.detail.value!, i, index)
                                                                        }></IonInput>
                                                                    </IonItem>
                                                                </IonCol>
                                                                )}
                                                                <IonCol size="3" className='table-field'>
                                                                    <IonItem className='fee-comp-height'>
                                                                        <IonInput type="number"
                                                                            className='ion-text-right'
                                                                            placeholder='0'
                                                                            value={due.amount}
                                                                            onIonInput={
                                                                                (e) => e.detail.value && handleAmount(e.detail.value, i, index)
                                                                        }></IonInput>
                                                                    </IonItem>
                                                                </IonCol>
                                                                    
                                                                <IonCol size="1" >
                                                                        <IonIcon 
                                                                        className='mt-15 ml-05'
                                                                            icon={closeCircle}
                                                                            color='dark'
                                                                            onClick={() => handleDelete(i, index)}
                                                                            size="large"
                                                                        />

                                                                </IonCol>
                                                                
                                                            </IonRow>
                                                        )
                                                    )) : ( 
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonCard>
                                                                <IonCardContent>
                                                                    <IonItem lines="none">
                                                                        <IonIcon icon={alertCircleOutline}
                                                                            slot="start"
                                                                            color="danger" />
                                                                        <IonLabel className="list-title"><h3>{t("no_dues_added")}</h3></IonLabel>

                                                                    </IonItem>
                                                                </IonCardContent>
                                                            </IonCard>
                                                        </IonCol>
                                                    </IonRow>
                                                    )
                                                } 
                                                {invoice.dueComponents && (invoice.dueComponents.length > 0) && (
                                                <IonRow className="table-title ion-padding">
                                                    <IonCol size="8" className='table-heading heading-fixed mt-05'>
                                                            {t("total")}
                                                    </IonCol>
                                                    <IonCol size="3" className='table-heading ion-text-right mt-05 ion-padding-end'>
                                                            {invoice.dueComponents.reduce((acc, curr) => { 
                                                                    return (acc + curr.amount);
                                                                }, 0)}
                                                    </IonCol>
                                                </IonRow>
                                                )}
                                                </div>
                                                
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                        </>
                                        )}
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                                )}

                            )) : ( 
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_new_invoices")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                                )
                            } 

                    </IonCol>
                    <IonCol sizeLg="6" size="12" className='ion-padding-horizontal'>
                        <IonRow className="ion-margin-top">
                                <IonCol>
                                    <IonCard>
                                        <IonCardHeader>
                                            <IonCardSubtitle className='info-subtitle'>{t("recent_invoices")}</IonCardSubtitle>
                                        </IonCardHeader>
                                        <IonCardContent className="ion-no-padding ion-margin-bottom">
                                        {(transactions.length > 0) ? (
                                        <IonRow>
                                            <IonCol>
                                                {(transactions.map((tran, i) => {
                                                    return (
                                                        <IonCard className='border-radius-10' key={tran._id}>
                                                            <IonCardContent className="ion-no-padding ion-no-margin">
                                                                <IonGrid className="ion-no-padding ion-no-margin">
                                                                    <IonRow className="tran-pad tran-row-1">
                                                                        <IonCol size="11">
                                                                        
                                                                            <IonText className='tran-label-1'>{t("invoice_date")}<br/></IonText>
                                                                            <IonText className='tran-text'>
                                                                                {formatDated(tran.invoiceDate)}
                                                                                </IonText>
                                                                        

                                                                        </IonCol>
                                                                        <IonCol size="1" className='mt-10'>
                                                                                <IonIcon className='tran-icon-2'
                                                                                    icon={informationCircle}
                                                                                    color='dark'
                                                                                    onClick={() => history.push(`/app/invoice/${tran._id}`)}
                                                                                />
                                                                        </IonCol>
                                                                    </IonRow>
                                                                    <IonRow className='ion-padding border-top'>
                                                                        <IonCol size="2">
                                                                            <IonIcon className='tran-icon'
                                                                                icon={receipt}
                                                                                color={(tran.paidAt !== undefined) ? 'success' : ((new Date(tran.dueDate)) < (new Date(Date.now()))) ? 'danger' : 'secondary'} 
                                                                                />
                                                                                <IonText className='tran-mode'><br/> {(tran.paidAt !== undefined) ? t("cleared") : ((new Date(tran.dueDate)) < (new Date(Date.now()))) ? t("past_due") : ((new Date(tran.invoiceDate)) <= (new Date(Date.now()))) ? t("uncleared") : t("scheduled")}</IonText>
                                                                            </IonCol>
                                                                            <IonCol size="10" className='ion-padding-horizontal'>
                                                                                <IonText className='action-text'> 
                                                                                {t("rs")} {Math.abs(tran.amount)}<br/> 
                                                                                </IonText>
                                                                                <IonText className='tran-rem'> 
                                                                                    {t("due_on")} {formatDated(tran.dueDate)}
                                                                                
                                                                                </IonText>
                                                                            </IonCol>
                                                                        
                                                                    </IonRow>
                                                                
                                                                
                                                                </IonGrid>
                                                            </IonCardContent>
                                                        </IonCard>
                                                    )
                                                        })) 
                                                    } 
                                                </IonCol>
                                            </IonRow>
                                            ) : (
                                            <IonRow className="ion-padding-vertical">
                                                <IonCol>
                                                    <IonItem lines="none" className='red-card ion-margin'>
                                                        <IonIcon icon={alertCircleOutline}
                                                        slot="start"
                                                        color="danger" />
                                                        <IonLabel className="list-title">
                                                                <h3>{t("no_invoices")}</h3></IonLabel>

                                                    </IonItem>
                                                </IonCol>
                                            </IonRow>
                                            )}
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                           
                            
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("generate_invoice")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default AddDues;