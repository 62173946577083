import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, checkboxOutline, construct } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';

interface Department {
    _id: string,
    name: string,
    employees: string[],
    isActive: boolean
}

const Departments: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [departments, setDepartments] = useState < Department[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [current, setCurrent] = useState < string > ("");
    const [skip, setSkip] = useState < number > (0);
    const [total, setTotal] = useState < number > (0);

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const loadItems = async () => {

        let limit = skip + 30;

        try {
            const result = await api.get(`/departments/listInstitute/${authInfo.user.instituteID}?skip=${limit}`);

            if(result.data.departments && result.data.departments.length)
            {
                setDepartments([...departments, ...result.data.departments]);
                setSkip(limit);
            }
                

        } catch (error: any) {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        }
    }

    useEffect(() => {

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.get(`/departments/listInstitute/${authInfo.user.instituteID}`);
                setDepartments(result.data.departments);
                setTotal(result.data.total);
                setSkip(0);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/staff`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("manage_departments")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("manage_departments")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                
                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}
                
                <IonGrid className="ion-no-padding mb-60">
                    <div className="ion-table">

                        <IonRow className="table-title ion-padding">

                            <IonCol size="7" className='table-heading'>
                                <IonIcon 
                                    icon={checkboxOutline}
                                    color="light"
                                    className='table-icon'
                                />
                                <IonText className='ion-margin-start'>{t("name")}</IonText>
                            </IonCol>
                            <IonCol size="4" className='table-heading'>
                                {t("employees")}
                            </IonCol>
                            <IonCol size="1" className='ion-text-right ion-padding-end'>
                                <IonIcon 
                                    icon={construct}
                                    color="light"
                                    className='table-icon'
                                    />
                            </IonCol>
                        </IonRow>
                        <IonRadioGroup value={current} onIonChange={
                                            (e) => setCurrent(e.detail.value!)
                                    }>

                        {(departments.length > 0) ? (departments.map((dep, i) => {
                            return (
                                <IonRow key={dep._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}
                                onClick={
                                    () => setCurrent(dep._id)
                                }
                                onDoubleClick={
                                    () => history.push(`/app/department/${dep._id}`)
                                }>
                                    
                                        <IonCol size="7" className='table-field' >
                                            <IonRadio value={dep._id} className='table-icon' color="secondary" />
                                            <IonText className='ion-margin-start'>{dep.name} </IonText>
                                        </IonCol>
                                        <IonCol size="4" className='table-field'>
                                            {dep.employees.length}
                                        </IonCol>
                                        <IonCol size="1" className='ion-text-right ion-padding-end'>
                                            <IonIcon 
                                                icon={construct}
                                                color={dep.isActive ? "dark" : "danger"}
                                                className='table-icon mt-10'
                                                onClick={
                                                    () => history.push(`/app/department/${dep._id}`)
                                                }
                                            />
                                            
                                        </IonCol>
                                    
                                </IonRow>
                            )
                        })) : ( 
                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonCardContent>
                                        <IonItem lines="none">
                                            <IonIcon icon={alertCircleOutline}
                                                slot="start"
                                                color="danger" />
                                            <IonLabel className="list-title"><h3>{t("no_deps")}</h3></IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        )
                        } 
                    </IonRadioGroup>
                    </div>

                    {(departments.length > 0) &&
                    <IonRow>
                        <IonCol>
                            <IonInfiniteScroll
                                onIonInfinite={async (ev) => {
                                    await loadItems();
                                    ev.target.complete();
                                }}
                            >
                                <IonInfiniteScrollContent></IonInfiniteScrollContent>
                            </IonInfiniteScroll>
                        </IonCol>
                   </IonRow>
                    }
                    
                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                <IonCol className="ion-padding-start footer-stats">
                            {t("showing")} {total === 0 ? t("no_records") : `1 - ${departments.length} ${t("of")} ${total}`} 
                        </IonCol>
                        </IonRow>
                            <IonRow className="ion-padding-vertical overflow-x">
                                <IonCol className='min-lg'>

                                    <IonButton 
                                        onClick={
                                            () => {
                                                if (current === "")
                                                {
                                                    setMessage(t("select_dep"));
                                                    setIserror(true);
                                                }
                                                else
                                                {
                                                    history.push(`/app/employees/${current}`)
                                                }
                                            }
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("view_employees")}
                                    </IonButton>
                                    
                                    <IonButton 
                                        onClick={() => history.push(`/app/departments/add`)}
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("add_department")}
                                    </IonButton>
                                    
                                    <IonButton 
                                        onClick={
                                            () => {
                                                if (current === "")
                                                {
                                                    setMessage(t("select_dep"));
                                                    setIserror(true);
                                                }
                                                else
                                                {
                                                    history.push(`/app/department/${current}`)
                                                }

                                            }
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("manage_dep")}
                                    </IonButton>

                                </IonCol>
                            </IonRow>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default Departments;