import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonReorder,
    IonReorderGroup,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToggle,
    IonToolbar,
    ItemReorderEventDetail,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import { addCircle, alertCircleOutline, arrowUndoCircle, closeCircle, constructOutline, informationCircleOutline, reorderThree } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';


interface AdmnClass {
    masterID: string,
    name: string,
    applicationFees: number,
    annualCost: string,
    isActive: boolean,
    photo?: string
}

interface AllClass {
    _id: string,
    name: string,
    category: string,
    isActive: boolean,
    order: number
}

interface Session {
    _id: string,
    name: string,
    isActive: boolean
}

const ClassSetup: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const history = useHistory();
    const [type, setType] = useState < string > ("");
    const [admnClasses, setAdmnClasses] = useState < AdmnClass[] > ([]);
    const [allClasses, setAllClasses] = useState < AllClass[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [admnFees, setAdmnFees] = useState < number > (0);
    const [selectedClass, setSelectedClass] = useState < string > ("");
    const [cost, setCost] = useState < string > ("");
    const [pop, setPop] = useState < boolean > (false);
    const [refreshed, setRefreshed] = useState < number > (0);
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [session, setSession] = useState < string > ("");

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const handleAdd = () => {

        //for schools

        if (!selectedClass || selectedClass === "") {
            setMessage(t("class_mandatory"));
            setIserror(true);
            return;
        }

        if (admnClasses.find(e => e.masterID === selectedClass)) {
            setMessage(t("class_exists"));
            setIserror(true);
            return;
            }

        if (!cost || cost === "") {
            setMessage(t("annual_cost_mandatory"));
            setIserror(true);
            return;
        }

        let classes: AdmnClass[] = [];
        
        const classDetails = allClasses.find(item => item._id === selectedClass)!;

        classes = [...admnClasses];
        classes.push({'masterID': selectedClass, 'name': classDetails.name, 'applicationFees': admnFees, 'annualCost': cost, isActive: true});
        setAdmnClasses(classes);

        setPop(false);
    }

    const handleDelete = (index: number) => {

        //for schools
        
        let classes = [...admnClasses];
        if (index > -1) {
            classes.splice(index, 1); // 2nd parameter means remove one item only
          }
        setAdmnClasses(classes);
    }

    const handleCost = (value: string, index: number) => {

        if (value !== "")
        {
            let classes = [...admnClasses];
            classes[index].annualCost = value;
            setAdmnClasses(classes);
        }

    }

    const handleFee = (value: number, index: number) => {

        let classes = [...admnClasses];
        classes[index].applicationFees = value;
        setAdmnClasses(classes);
    }

    const handleActive = (value: boolean, index: number) => {

        let classes = [...admnClasses];
        classes[index].isActive = value;
        setAdmnClasses(classes);
    }

    const doReorder = (event: CustomEvent<ItemReorderEventDetail>) => {

        let from = event.detail.from;
        let to = event.detail.to;
        let toCopy = event.detail.to;

        let studs = [...admnClasses];

      
        if (to < from)
        {
            studs[to] = {...admnClasses[from]};
            to = to + 1;
            while (to <= from)
            {
                studs[to] = {...admnClasses[to-1]};
                to = to + 1;
            }
        }

        if (toCopy > from)
        {
            studs[toCopy] = {...admnClasses[from]};
            toCopy = toCopy - 1;
            while (toCopy >= from)
            {
                studs[toCopy] = {...admnClasses[toCopy+1]};
                toCopy = toCopy - 1;
            }
        }
        setAdmnClasses(studs);
        
        event.detail.complete();
    }

    const handleSubmit = () => {

        if (!session || session === "") {
            setMessage(t("session_mandatory"));
            setIserror(true);
            return;
        }

        const sessionName = sessions.find(val=> val._id === session)!.name;
  
        const upClass = (classes: AdmnClass[]) => {
          return new Promise((resolve, reject) => {
            api.post('/institutes/updateClasses', { 'instituteID': authInfo.user.instituteID, session, sessionName, classes }).then(res => {
      
              return resolve(res.data.institute.name);
              
            }).catch(err => reject(err));
  
          });
        }
    
        setShowLoading(true);
        upClass(admnClasses)
        .then(data => {
  
            setPass(data+t("classes_updated"));
            setIspass(true);
          
        })
        .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));
        
    }
    
    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/institutes/' + authInfo.user.instituteID);

                setType(result.data.type);

                if(result.data.admnClasses && result.data.admnClasses.length > 0)
                {
                    setAdmnClasses(result.data.admnClasses);
                }
                else
                {
                    setAdmnClasses([]);
                }

                if (result.data.type === "school")
                {
                    const allClass = await api.get(`/masters/getClasses`);
                    setAllClasses(allClass.data.admissionClasses);
                }

                const res = await api.get(`/sessions/getAllInstitute/${authInfo.user.instituteID}`);
                setSessions(res.data);

                if (result.data.admnSession)
                {
                    setSession(result.data.admnSession.sessionID);
                }
                else
                {
                    setSession("");
                }
                
            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchData();

    }, [authInfo, refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/admission`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{(type === "school") ? t("admn_classes") : t("admn_courses")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{(type === "school") ? t("admn_classes") : t("admn_courses")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                    
                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding mb-60">
                    <IonRow className='ion-margin-top'>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard className='note-card'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonRow>
                                        <IonCol size="1">
                                            <IonIcon icon={informationCircleOutline}
                                                color="primary" 
                                                className="note-icon" />
                                        </IonCol>
                                        <IonCol size="11" className='note-text pl-08'>
                                            <p>
                                            {(type === "school") ? t("classes_info") : t("courses_info")}
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className='ion-margin-top'>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12" >
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("admn_session")}</IonLabel>
                                            <IonSelect value={session}
                                                    className="input-field"
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("admn_session")}
                                                    onIonChange={
                                                        (e) => setSession(e.detail.value)
                                                }>

                                                    {sessions.map((session) => (
                                                        (session.isActive) && 
                                                        (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                    ))}

                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className='mt-30'>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12" className='border-bottom-sec ion-padding-bottom'>
                          
                            <IonRow>
                                <IonCol size="8" className='ion-padding-horizontal mt-05'>
                                    <IonText className='list-title'>
                                    {(type === "school") ? t("admn_classes") : t("admn_courses")}
                                    </IonText>
                                </IonCol>
                                <IonCol size="2" className='ion-text-right'>
                                    
                                    <IonIcon size="large"
                                        icon={arrowUndoCircle}
                                        color='secondary'
                                        onClick={() => setRefreshed(Math.random())}
                                    />
                                
                                
                                </IonCol>
                                <IonCol size="2" className='ion-text-center'>
                                    
                                        <IonIcon size="large"
                                            icon={addCircle}
                                            color='secondary'
                                            onClick={() => (type === "school") ? setPop(true) : history.push(`/app/admission/course`)}
                                        />
                                    
                                    
                                </IonCol>
                            </IonRow>
                                   
                        </IonCol>
                    </IonRow>
                    <IonPopover isOpen={pop} size='auto' onDidDismiss={(e) => setPop(false)} >
                        <IonGrid className="ion-no-padding ion-no-margin">
                            <IonRow>
                                <IonCol className='ion-padding'>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                lines='full'
                                                button={false}
                                                >
                                                
                                                <IonLabel position="stacked" color="secondary">{t("class")}*</IonLabel>
                                                
                                                    <IonSelect value={selectedClass}
                                                                    placeholder={t("class")}
                                                                    cancelText={t("cancel")}
                                                                    okText={t("ok")}
                                                                    onIonChange={
                                                                        (e) => setSelectedClass(e.detail.value)

                                                                }>
                                                                { allClasses.map((allClass) => {
                                                                        
                                                                    return (<IonSelectOption key={allClass._id} value={`${allClass._id}`}>{allClass.name}</IonSelectOption>);
                                                                        
                                                                    })
                                                                }
                                                    </IonSelect>
                                               
                                            </IonItem>
                                            <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" color="secondary">{t("application_fees")}*</IonLabel>
                                                    <IonInput type="number"
                                                        value={admnFees}
                                                        placeholder={t("application_fees")}
                                                        onIonInput={
                                                            (e) => setAdmnFees(parseInt(e.detail.value!))
                                                    }></IonInput>
                                            </IonItem>

                                            <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" color="secondary">{t("total_cost_first")}*</IonLabel>
                                                    <IonInput type="text"
                                                        value={cost}
                                                        placeholder={t("total_cost_first")}
                                                        onIonInput={
                                                            (e) => setCost(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="ion-padding-horizontal ion-padding-bottom">
                                    <IonButton onClick={handleAdd}
                                        expand="block"
                                        className="first-button"
                                        fill="clear" >{t("add_admn_class")}</IonButton>

                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonPopover>
                    
                    <IonRow className="ion-margin-vertical">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                        <IonReorderGroup disabled={false} onIonItemReorder={doReorder}>
                             {admnClasses && (admnClasses.length > 0) ? (admnClasses.map((admnClass, i) => {
                                    return (
                                        <IonCard className='action-card-b' key={admnClass.masterID}>
                                            <IonCardContent className="ion-no-padding ion-no-margin">
                                                <IonGrid className="ion-no-padding ion-no-margin">
                                                
                                                    <IonRow className='ion-no-padding mt-10'>
                                                        <IonCol size="10" className='ion-padding-horizontal'>
                                                            <IonText className='sub-label'>{admnClass.name}</IonText>
                                                        </IonCol>
                                                        <IonCol size="1" className='mt-10'>
                                                            {(type === "school") && (
                                                            <IonIcon className='tran-icon'
                                                                    icon={closeCircle}
                                                                    color='dark'
                                                                    onClick={() => handleDelete(i)}
                                                                />
                                                            )}
                                                            {(type === "institute") && (
                                                            <IonIcon className='tran-icon'
                                                                    icon={constructOutline}
                                                                    color='dark'
                                                                    onClick={() => history.push(`/app/admission/course/${admnClass.masterID}`)}
                                                                />
                                                            )}
                                                        </IonCol>
                                                        <IonCol size="1" className='mt-10'>
                                                            <IonReorder><IonIcon className='tran-icon' color='secondary' icon={reorderThree}></IonIcon></IonReorder>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonItem detail={false}
                                                                lines='inset'
                                                                button={false}
                                                                className="item-transparent"
                                                                >
                                                                <IonLabel position="stacked" color="dark">{t("application_fees")}</IonLabel>
                                                            
                                                                <IonInput type="number"
                                                                    value={admnClass.applicationFees}
                                                                    placeholder={t("application_fees")}
                                                                    onIonInput={
                                                                        (e) => handleFee(parseInt(e.detail.value!), i)
                                                                }></IonInput>

                                                            </IonItem>
                                                            <IonItem detail={false}
                                                                lines='inset'
                                                                button={false}
                                                                className="item-transparent"
                                                                >
                                                                <IonLabel position="stacked" color="dark">{(type === "school") ? t("total_cost_first") : t("total_cost")}</IonLabel>
                                                            
                                                                <IonInput type="text"
                                                                    value={admnClass.annualCost}
                                                                    placeholder={(type === "school") ? t("total_cost_first") : t("total_cost")}
                                                                    onIonInput={
                                                                        (e) => handleCost(e.detail.value!, i)
                                                                }></IonInput>

                                                            </IonItem>
                                                            <IonItem detail={false}
                                                                lines='full'
                                                                button={false}
                                                                className="item-transparent"
                                                                >
                                                                <IonLabel className="input-label" color="secondary">{t("admn_open")}</IonLabel>
                                                                <IonToggle color="secondary" checked={admnClass.isActive} onIonChange={e => handleActive(e.detail.checked, i)} />
                                                            </IonItem>
                                                           
                                                        </IonCol>
                                                        
                                                    </IonRow>
                                                   
                                                </IonGrid>
                                            </IonCardContent>
                                        </IonCard>
                                        
                                    
                                    )
                                })) : ( 
                                    <IonCard className='red-card'>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                slot="start" 
                                                color="danger" />
                                                <IonLabel className="list-title"><h3>{(type === "school") ? t("no_admn_classes") : t("no_admn_courses")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                   
                                )
                                } 
                            </IonReorderGroup>
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("save_changes")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default ClassSetup;
