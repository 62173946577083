import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, busOutline, chevronForwardOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';

interface Trip {
    _id: string,
    name: string,
    type: string,
    students: TripStudent[],
    scheduledAt: Date,
    startedAt: Date,
    finishedAt: Date
}
interface TripStudent {
    studentID: string,
    name: string,
    order: number
}

const CompletedTrips: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [completedTrips, setCompletedTrips] = useState < Trip[] > ([]);
    const [refreshed, setRefreshed] = useState < number > (0);
    const [skip, setSkip] = useState < number > (0);

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const loadItems = async () => {

        let limit = skip+30;

        try {
            const result = await api.get(`/trips/completed/getByInstitute/${authInfo.user.instituteID}?skip=${limit}`);

            if(result.data)
            {
                setCompletedTrips([...completedTrips, ...result.data]);
                setSkip(limit);
            }
                

        } catch (error: any) {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        }
        
    }

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const formatDateTime = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'long' }) + ' at ' + valueDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
    };
   
    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const completed = await api.get(`/trips/completed/getByInstitute/${authInfo.user.instituteID}`);
                setCompletedTrips(completed.data);
                setSkip(0);
            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, refreshed]);


    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/transport`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("completed_trips")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("completed_trips")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}
                
                <IonGrid className="ion-no-padding mb-60">

                    <IonRow className="ion-no-padding ion-margin-top max-xl">
                        {
                            (completedTrips.length > 0) ? (completedTrips.map((trip) => {
                                return (
                                    <IonCol key={trip._id} size="12" sizeLg="6" className="ion-no-padding ion-no-margin">
                                        <IonCard className={trip.type === 'drop' ? "announce-card " : "circular-card "} 
                                            routerLink={`/app/trip/detail/${trip._id}`}>
                                            <IonCardContent>
                                                <IonGrid>
                                                    <IonRow>
                                                        <IonCol size="3">
                                                            <IonIcon className='first-icon mt-05'
                                                                icon={busOutline}
                                                                color={trip.type === 'drop' ? 'secondary' : 'primary'} />
                                                            </IonCol>
                                                            <IonCol size="8">
                                                                <IonText className='action-text'>{trip.name} ({trip.type})<br/>
                                                                        {t("started")}: {formatDateTime(trip.startedAt)}<br/>
                                                                        {t("ended")}: {formatDateTime(trip.finishedAt)}<br/>
                                                                        {t("no_of_students")}: {trip.students.length}
                                                                </IonText>
                                                            </IonCol>
                                                            <IonCol size="1" className='mt-20'>
                                                                
                                                                        <IonIcon className='second-icon'
                                                                            icon={chevronForwardOutline}
                                                                            color='dark'/>
                                                              
                                                            </IonCol>
                                                    </IonRow>
                                                 
                                                </IonGrid>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                        );
                                        })) : (
                                        <IonCol>
                                            <IonCard className='red-card'>

                                                <IonCardContent>
                                                    <IonItem lines="none">
                                                        <IonIcon icon={alertCircleOutline}
                                                            slot="start"
                                                            color="danger" />
                                                        <IonLabel className="list-title"><h3>{t("no_completed")}</h3></IonLabel>

                                                    </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        )
                                    }

                        
                    </IonRow>
                    
                        {(completedTrips.length > 0) &&
                        <IonRow>
                            <IonCol>
                            <IonInfiniteScroll
                                onIonInfinite={async (ev) => {
                                    await loadItems();
                                    ev.target.complete();
                                }}
                            >
                                <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                            </IonCol>
                        </IonRow>
                            }
                        
                    
                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default CompletedTrips;
