import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToggle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import { camera, trash } from 'ionicons/icons';
import axios from 'axios';
import { Capacitor } from '@capacitor/core';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';

interface Store {
    _id: string,
    name: string,
    isActive: boolean
}

interface ParamTypes {
    store?: string
}

const AddItem: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {store} = useParams < ParamTypes > ();
    const [stores, setStores] = useState < Store[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [price, setPrice] = useState < number > (0);
    const [buyPrice, setBuyPrice] = useState < number > (0);
    const [storeID, setStoreID] = useState < string > ("");
    const [uploadFile, setUploadFile] = useState < File | null > (null);
    const [stock, setStock] = useState < boolean > (false);
    const [imageURL, setImageURL] = useState < string > ("");

    const api = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      timeout: parseInt(process.env.REACT_APP_API_TO!),
      headers: {
          'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const fileToDataUri = (file: File) => new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event: any) => {
            resolve(event.target.result);
        };
        reader.readAsDataURL(file);
    });

    const convertFileSrc = (path: string) => {
        return Capacitor.convertFileSrc(path);
    };
      
    const pickImage = async () => {
         try {

            const result = await FilePicker.pickImages({limit: 1});

            if (result.files.length === 1)
            {
                const file = result.files[0];
                //for native
                if (file && file.path && file.name && file.mimeType) {
                    const webPath = convertFileSrc(file.path);
                    if ((file.mimeType === 'image/jpeg') || (file.mimeType === 'image/png'))
                    {
                        setImageURL(webPath);
                    }
                    else
                    {
                        setImageURL("");
                    }
                    const blob = await fetch(webPath).then((r) => r.blob());
                    const rawFile = new File([blob], file.name, {
                        type: file.mimeType,
                    });
                    setUploadFile(rawFile);
                }
                //for web
                else if (file && file.blob && file.name && file.mimeType) {
                    const rawFile = new File([file.blob], file.name, {
                        type: file.mimeType,
                    });
                    setUploadFile(rawFile);
                    if ((file.mimeType === 'image/jpeg') || (file.mimeType === 'image/png'))
                    {
                        const base64 = await fileToDataUri(rawFile);
                        setImageURL(base64);
                    }
                    else
                    {
                        setImageURL("");
                    }
                }
            }
            
        } 
        catch(error: any) {
 
            setMessage(t("no_files_selected"));  
            setIserror(true);
        }
    };

    const handleSubmit = () => {

        if (!name || name === "") {
            setMessage(t("name_mandatory"));
            setIserror(true);
            return;
        }

        if (!storeID || storeID === "") {
            setMessage(t("store_mandatory"));
            setIserror(true);
            return;
        }

        if (price < 1) {
            setMessage(t("price_mandatory"));
            setIserror(true);
            return;
        }

        if (stock)
        {
            if (buyPrice < 1) {
                setMessage(t("price_mandatory"));
                setIserror(true);
                return;
            }
        }

        const storeName = stores.find((val) => val._id === storeID)!.name;
  
        const addItem = () => {
          return new Promise((resolve, reject) => {
            api.post('/items/add', { name, price, buyPrice, 'instituteID': authInfo.user.instituteID, storeID, storeName, stock }).then(res => {
              return resolve(res.data.item.name);
            }).catch(err => reject(err));
          });
        }

        const addItemPhoto = (formData: FormData) => {
            return new Promise((resolve, reject) => {
              api.post('/items/addPhoto', formData).then(res => {
                return resolve(res.data.item.name);
              }).catch(err => reject(err));
            });
        }

        setShowLoading(true);

        if (uploadFile) {

            const stocker = (stock === true) ? 'yes' : 'no';
            let formData = new FormData();

            formData.append('instituteID', authInfo.user.instituteID);
            formData.append('name', name);
            formData.append('price', price.toString());
            formData.append('buyPrice', buyPrice.toString());
            formData.append('storeID', storeID);
            formData.append('storeName', storeName);
            formData.append('item', uploadFile, 'item');
            formData.append('stocker', stocker);

            addItemPhoto(formData)
                .then(data => {
                    setPass(data+t("has_been_added"));
                    setIspass(true);
                })
                .catch((error) => {
                    setMessage(error.response?.data.message || t("something_went_wrong"));
                    setIserror(true);
                })
                .finally(() => setShowLoading(false));
        }
        else
        {
            addItem()
            .then(data => {
            
                setPass(data + t("has_been_added"));
                setIspass(true);
            })
            .catch((error) => {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            })
            .finally(() => setShowLoading(false));
        }
        
        
      }

      useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const res = await api.get(`/stores/getAllInstitute/${authInfo.user.instituteID}`);
                setStores(res.data);

                if (store)
                {
                    setStoreID(store);
                }
                else
                {
                    setStoreID("");
                }

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchData();

    }, [authInfo, store]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary" />
                        <IonBackButton color="secondary" defaultHref={`/app/inventory`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("add_item")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("add_item")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                
                 <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => history.push(`/app/inventory/items/${storeID}`)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                    
                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding">
                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {t("item_details")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    
                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("item_store")}*
                                        </IonLabel>
                                            <IonSelect value={storeID}
                                                    className="input-field"
                                                    placeholder={t("item_store")}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    onIonChange={
                                                        (e) => setStoreID(e.detail.value)
                                                }>

                                                    {stores.length > 0 && stores.map((store) => 
                                                        (<IonSelectOption key={store._id} value={store._id}>{store.name}</IonSelectOption>)
                                                    )}

                                                    
                                            </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("item_name")}*
                                        </IonLabel>
                                            <IonInput type="text"
                                                className="input-field"
                                                placeholder={t("item_name")}
                                                value={name}
                                                onIonInput={
                                                    (e) => setName(e.detail.value!)
                                            }></IonInput>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("sell_price")}*
                                        </IonLabel>
                                            <IonInput type="number"
                                                className="input-field"
                                                placeholder={t("sell_price")}
                                                value={price}
                                                onIonInput={
                                                    (e) => setPrice(parseInt(e.detail.value!))
                                            }></IonInput>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}
                                        color="primary"
                                        >
                                        <IonLabel className="input-label">{t("maintain_stock")}</IonLabel>
                                        <IonToggle color="light" checked={stock} onIonChange={e => setStock(e.detail.checked)} />
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    {stock && (
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("buy_price")}*
                                        </IonLabel>
                                            <IonInput type="number"
                                                className="input-field"
                                                placeholder={t("buy_price")}
                                                value={buyPrice}
                                                onIonInput={
                                                    (e) => setBuyPrice(parseInt(e.detail.value!))
                                            }></IonInput>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    )}
                     <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                        <IonItem detail={true}
                                            lines='none'
                                            color="secondary"
                                            button={true}
                                            onClick={pickImage}
                                            >
                                            <IonIcon
                                                slot="start"
                                                icon={camera}
                                            />
                                            <IonLabel>
                                            {(uploadFile) ? t("change_photo") : t("upload_photo")}
                                            </IonLabel>
                                            
                                        </IonItem>
                                        {(uploadFile) && (
                                        <IonItem detail={true}
                                            detailIcon={trash}
                                            lines='none'
                                            button={true}
                                            onClick={
                                                () => {
                                                    setUploadFile(null);
                                                    setImageURL("");
                                                }
                                            }
                                        >
                                            <IonBadge color="danger" slot="start">1</IonBadge>
                                            <IonLabel>
                                                {t("remove_files")}
                                            </IonLabel>
                                            
                                        </IonItem>
                                        )}
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    {(imageURL !== "") && (
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard className="ion-text-center">
                                <IonCardHeader>
                                    <IonCardSubtitle>{t("image_preview")}</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent>
                                
                                    <img src={imageURL} 
                                        alt={t("image_preview")}
                                        width="320" 
                                    />

                                </IonCardContent>        
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    )}

                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear"
                                > {t("add_item")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default AddItem;
