import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonInput,
    IonItem,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, calendar, informationCircleOutline } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import { Toast } from '@capacitor/toast';
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';

interface Session {
    _id: string,
    name: string,
    isActive: boolean
}

interface Student {
    _id: string,
    name: string,
    className: string,
    status: string
}

interface ClassInterface {
    _id: string,
    name: string,
    year: string,
    isActive: boolean
}

const Attend: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [students, setStudents] = useState < Student[] > ([]);
    const [classes, setClasses] = useState < ClassInterface[] > ([]);
    const [classi, setClassi] = useState < string > ("");
    const [session, setSession] = useState < string > ("");
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [selectedDate, setSelectedDate] = useState < string | string[] | undefined > ();
    const [skip, setSkip] = useState < number > (0);

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const formatDate = (value: string | string[] | undefined) => {
        if (typeof value === "string")
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
        
    };

    const handleSession = (sessionID : string) => {

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${sessionID}`);
                setClasses(result.data);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();
        setSession(sessionID);
        setClassi("");
        setStudents([]);
    };

    const handleClassi = (classID: string) => {

        if ((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic")))
        {
            if (!session || session === "") {
                setMessage(t("select_session_first"));
                setIserror(true);
                return;
            }
        }

        const onDate = new Date(Date.now());
        setSelectedDate(onDate.toISOString());
        setClassi(classID);

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/attends/getByClass/${classID}/${onDate}`);
                setStudents(studs.data);
                setSkip(0);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        const fetchAll = async () => {
            setShowLoading(true);
            try {
                if ((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic")))
                {
                    const studs = await api.get(`/attends/getAllInstitute/${authInfo.user.instituteID}/${session}/${onDate}`);
                    setStudents(studs.data);
                }
                if ((authInfo.user.role === 'Teacher') || (authInfo.user.role === 'Employee' && !authInfo.user.roles.includes("Academic") && authInfo.user.roles.includes("Teacher")))
                {
                    const studs = await api.get(`/attends/getAllTeacher/${authInfo.user._id}/${onDate}`);
                    setStudents(studs.data);
                }
                setSkip(0);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        if (classID === 'all')
        {
            fetchAll();
        }
        else
        {
            fetchUp();
        }
        
    }

    const loadItems = async () => {

        if (!session || session === "") {
            setMessage(t("select_session_first"));
            setIserror(true);
            return;
        }

        if (!selectedDate || selectedDate === "") {
            setMessage(t("select_date"));
            setIserror(true);
            return;
        }

        let limit = skip + 30;

        try {
            const result = await api.get(`/attends/getAllInstitute/${authInfo.user.instituteID}/${session}/${selectedDate}?skip=${limit}`);

            if(result.data && result.data.length)
            {
                setStudents([...students, ...result.data]);
                setSkip(limit);
            }
                

        } catch (error: any) {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        }
        
    }

    const handleSubmit = (dated: string | string[]) => {

        if ((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic")))
        {
            if (!session || session === "") {
                setMessage(t("select_session_first"));
                setIserror(true);
                return;
            }
        }

        if (!classi || classi === "") {
            setMessage((authInfo.institute.type === "school") ? t("select_class") : t("select_batch"));
            setIserror(true);
            return;
        }

        if (!dated || dated === "") {
            setMessage(t("select_date"));
            setIserror(true);
            return;
        }
        setSelectedDate(dated);

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/attends/getByClass/${classi}/${dated}`);
                setStudents(studs.data);
                setSkip(0);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        const fetchAll = async () => {
            setShowLoading(true);
            try {

                if ((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic")))
                {
                    const studs = await api.get(`/attends/getAllInstitute/${authInfo.user.instituteID}/${session}/${dated}`);
                    setStudents(studs.data);
                }
                if ((authInfo.user.role === 'Teacher') || (authInfo.user.role === 'Employee' && !authInfo.user.roles.includes("Academic") && authInfo.user.roles.includes("Teacher")))
                {
                    const studs = await api.get(`/attends/getAllTeacher/${authInfo.user._id}/${dated}`);
                    setStudents(studs.data);
                }
                setSkip(0);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        if (classi === 'all')
        {
            fetchAll();
        }
        else
        {
            fetchUp();
        }

    }

    const handlePresent = (studentID : string) => {

        if (!selectedDate || selectedDate === "") {
            setMessage(t("select_date"));
            setIserror(true);
            return;
        }

        api.get('/attends/present/' + studentID + '/' + selectedDate)
        .then(async (res) => {

            let studs = [...students];
            const index = studs.findIndex((val) => val._id === studentID);
            if (index > -1)
            {
                studs[index].status = 'present';
            }
            setStudents(studs);
            await Toast.show({
                text: `${res.data.student.name} ${t("marked_qr_present")} ${formatDate(selectedDate)}. ${t("parent_notified")}`,
            });

        })
        .catch((error: any) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        });

    }

    const handleAbsent = (studentID : string) => {

        if (!selectedDate || selectedDate === "") {
            setMessage(t("select_date"));
            setIserror(true);
            return;
        }
    
        api.get('/attends/absent/' + studentID + '/' + selectedDate)
        .then(async (res) => {
            
            let studs = [...students];
            const index = studs.findIndex((val) => val._id === studentID);
            if (index > -1)
            {
                studs[index].status = 'absent';
            }
            setStudents(studs);
            await Toast.show({
                text: `${res.data.student.name} ${t("marked_qr_absent")} ${formatDate(selectedDate)}. ${t("parent_notified")}`,
            });

        })
        .catch((error: any) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        });

    }

    useEffect(() => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {

                if ((authInfo.user.role === 'Teacher') || (authInfo.user.role === 'Employee' && !authInfo.user.roles.includes("Academic") && authInfo.user.roles.includes("Teacher")))
                {
                    const classy = await api.get(`/classes/getByTeacher/${authInfo.user._id}`);
                    setClasses(classy.data);
                }
                if ((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic")))
                {
                    const res = await api.get(`/sessions/getAllInstitute/${authInfo.user.instituteID}`);
                    setSessions(res.data);
                    setClasses([]);
                    setSelectedDate(undefined);
                    setClassi("");
                    setStudents([]);
                    setSkip(0);

                }

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/student`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("mark_attendance")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("mark_attendance")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                
                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding overflow-x">
                    <IonRow className='min-md'>
                    {((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic"))) && (
                        <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                     <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("class_session")}</IonLabel>
                                            <IonSelect value={session}
                                                    className="input-field"
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("class_session")}
                                                    onIonChange={
                                                        (e) => handleSession(e.detail.value)
                                                }>

                                                    {sessions.map((session) => (
                                                        (session.isActive) && 
                                                        (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                    ))}

                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    )}
                        <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {(authInfo.institute.type === "school") ? t("class") : t("batch")}</IonLabel>
                                            <IonSelect value={classi}
                                                    className="input-field"
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}
                                                    onIonChange={
                                                        (e) => handleClassi(e.detail.value)
                                                }>
                                                     <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                    {classes.map((classy) => (
                                                        (classy.isActive) &&
                                                        (<IonSelectOption key={classy._id} value={`${classy._id}`}>{classy.name} ({classy.year})</IonSelectOption>)
                                                    ))}
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding pointer">
                                
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-date-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">{t("date")}</IonLabel>
                                            <IonInput className="input-field" value={typeof selectedDate !== 'string' ? t("pick_date") : formatDate(selectedDate)} readonly={true} />
                                                <IonModal trigger="open-date-input" showBackdrop={true} 
                                                    initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                    <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                        <IonToolbar>
                                                            <IonTitle>{t("pick_date")}</IonTitle>
                                                            
                                                        </IonToolbar>
                                                        <IonDatetime
                                                            cancelText={t("cancel")}
                                                            doneText={t("ok")}
                                                            value={selectedDate}
                                                            presentation="date"
                                                            onIonChange={ev => handleSubmit(ev.detail.value!)}
                                                            showDefaultButtons={true}
                                                            size="cover"
                                                        />
                                                    </IonContent>
                                                    
                                                </IonModal>
                                        </IonItem>
                                     
                                    </IonCardContent>
                            </IonCard>
                        </IonCol>
                       
                    </IonRow>
                </IonGrid>
                <IonGrid className="ion-no-padding max-xl">

                    {(students.length > 0) && isPlatform("mobile") && (
                    
                    <IonRow>
                        <IonCol>
                            <IonCard className='note-card-1'>
                                <IonCardContent className="ion-no-padding">
                                    <IonRow>
                                        <IonCol size="1">
                                            <IonIcon icon={informationCircleOutline}
                                                color="secondary" 
                                                className="note-icon mt-05" />
                                        </IonCol>
                                        <IonCol size="11" className='note-text pl-08 pt-08'>
                                            <p>
                                                {t("attend_note")}
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    )}
            
                    <IonRow className="ion-margin-top">
                        <IonCol>
                            <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {t("students")} {typeof selectedDate !== "string" ? "" : `(${formatDate(selectedDate)})`}
                            </IonTitle>
                        </IonCol>
                    </IonRow>
         
                    {(students.length > 0) && (
                    <IonRow className="ion-margin-vertical">
                        <IonCol>
                        
                          <IonList className="ion-no-padding">

                             {(students.map((student) => {
                                    return (
                                        <IonItemSliding key={student._id}>

                                            {isPlatform("mobile") && (
                                                <IonItemOptions side="start" onIonSwipe={() => handlePresent(student._id)}>
                                                    <IonItemOption color="success" onClick={() => handlePresent(student._id)}>{t("mark_present")}</IonItemOption>
                                                </IonItemOptions>
                                            )}

                                            <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                                  <IonIcon slot="start"
                                                    icon={calendar}
                                                    color={
                                                        (student.status === 'present') ? 'success' : (student.status === 'absent') ? 'danger' : ''
                                                    }/>
                                                <IonLabel>
                                                <h2 className="text-capitalize">{student.name} {(classi === "all") && ("("+student.className+")")} </h2>
                                                    {(student.status === 'absent') && (
                                                        <p>{t("marked_absent")}</p>
                                                    )}

                                                    {(student.status === 'present') && (
                                                        <p>{t("marked_present")}</p>
                                                    )}
                                                </IonLabel>
                                                {!isPlatform("mobile") && 
                                                (<IonButtons slot="end">
                                                        <IonButton className="present-button" onClick={
                                                            () => handlePresent(student._id)
                                                        }>
                                                            {t("mark_present")}
                                                        </IonButton>
                                                        
                                                        <IonButton className="absent-button" onClick={
                                                            () => handleAbsent(student._id)
                                                        }>
                                                            {t("mark_absent")}
                                                        </IonButton>
                                                </IonButtons>
                                                )}
                                            </IonItem>
                                        
                                            {isPlatform("mobile") && (
                                                <IonItemOptions side="end" onIonSwipe={(e) => handleAbsent(student._id) }>
                                                    <IonItemOption color="danger" onClick={() => handleAbsent(student._id)}>{t("mark_absent")}</IonItemOption>
                                                </IonItemOptions>
                                            )}
                                            
                                      </IonItemSliding>
                                      
                                      
                                    )
                                }))}
                                
                                </IonList>
                            </IonCol>
                        </IonRow>
                        )}
                            
                        {(students.length === 0) && ( 
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_students")}</h3></IonLabel>
    
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        )} 

                    {((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic"))) && (classi === "all") && (students.length > 0) && (

                            <IonRow>
                                <IonCol>
                                    <IonInfiniteScroll
                                        onIonInfinite={async (ev) => {
                                            await loadItems();
                                            ev.target.complete();
                                        }}
                                    >
                                        <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                    </IonInfiniteScroll>
                                </IonCol>
                            </IonRow>
                        )}

                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow className="max-xl">
                        <IonCol size="4" className="ion-padding footer-stats">
                            {t("total")}: {students.length}
                        </IonCol>
                        <IonCol size="4" className="ion-padding footer-stats">
                            {t("present")}: {students.filter(val => val.status === "present").length}
                        </IonCol>
                        <IonCol size="4" className="ion-padding footer-stats">
                            {t("absent")}: {students.filter(val => val.status === "absent").length}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default Attend;
