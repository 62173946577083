import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';

interface Department {
    _id: string,
    name: string
}

const AddSalesRep: React.FC = () => {
    const {register, authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [phone, setPhone] = useState < string > ("");
    const [email, setEmail] = useState < string > ("");
    const [newPass, setNewPass] = useState < string > ("");
    const [selectedDate, setSelectedDate] = useState < string | string[] | undefined > ();
    const [gender, setGender] = useState < string > ("");
    const [address, setAddress] = useState < string > ("");
    const [designation, setDesignation] = useState < string > ("");
    const [qualification, setQualification] = useState < string > ("");
    const [joiningDate, setJoiningDate] = useState < string | string[] | undefined > ();
    const [departments, setDepartments] = useState < Department[] > ([]);
    const [department, setDepartment] = useState < string > ("");
    const [instituteID, setInstituteID] = useState < string > ("");
    const [enrollNo, setEnrollNo] = useState < string > ("");

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const validateEmail = (email : string) => { 
        // eslint-disable-next-line no-control-regex
        const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
        return re.test(String(email).toLowerCase());
    };

    const validatePhone = (phone : string) => { 
        // eslint-disable-next-line no-control-regex
        const re = /^[0-9]{10}$/;
        return re.test(phone);
    };

    const formatDate = (value: string | string[] | undefined) => {
        if (typeof value === "string") 
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
        else
        {
            return t("pick_date");
        }
    };

    const handleSubmit = async () => {

        if (!name || name === "") {
            setMessage(t("name_mandatory"));
            setIserror(true);
            return;
        }

        if (!address || address === "") {
            setMessage(t("address_mandatory"));
            setIserror(true);
            return;
        }

        if (!gender || gender === "") {
            setMessage(t("gender_mandatory"));
            setIserror(true);
            return;
        }

        if (!department || department === "") {
            setMessage(t("department_mandatory"));
            setIserror(true);
            return;
        }

        if (typeof selectedDate !== "string")
        {
            setMessage(t("dob_mandatory"));
            setIserror(true);
            return;
        }

        if (validateEmail(email) === false) {
            setMessage(t("invalid_email"));
            setIserror(true);
            return;
        }

        if (validatePhone(phone) === false) {
            setMessage(t("invalid_mobile"));
            setIserror(true);
            return;
        }

        if (!newPass || newPass.length < 8) {
            setMessage(t("invalid_password"));
            setIserror(true);
            setShowLoading(false);
            return;
        }

        const departmentName = departments.find(val => val._id === department)!.name;

        let joining = "";
        if (typeof joiningDate === "string")
        {
           joining = joiningDate;
        }


        const user = await register(email, newPass, name, phone, "SalesRep", instituteID);
              
        const addEmployee = (userID: string, dob: string, role: string, departmentID: string) => {
            return new Promise((resolve, reject) => {
                api.post('/employees/add', { instituteID, userID, name, role, phone, email, departmentID, departmentName, gender, address, dob, enrollNo, joining, designation, qualification, 'licenseNo': 'none', 'classID': 'none', 'additional': false, classes: [] }).then(res => {
        
                    return resolve(res.data);
                
                }).catch(err => reject(err));
    
            });
        }       
            
        setShowLoading(true);
        addEmployee(user, selectedDate, "SalesRep", department)
            .then(data => {

                setPass(name+t("has_been_added"));
                setIspass(true);
            
            })
            .catch((error) => {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            })
            .finally(() => setShowLoading(false));

      }

      useEffect(() => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                setInstituteID(authInfo.user.instituteID);

                const result = await api.get(`/departments/getAllInstitute/${authInfo.user.instituteID}`);
                setDepartments(result.data);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authInfo]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/salesreps`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("add_salesrep")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("add_salesrep")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                
                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => history.push(`/app/salesreps`)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className="ion-no-padding">
                    <IonRow className="ion-padding-vertical max-xl">
                        <IonCol sizeLg="6" size="12">
                            <IonRow className="mt-30">
                                <IonCol>
                                    <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                        {t("basic_info")}
                                    </IonTitle>
                                </IonCol>
                            </IonRow>
                            
                            <IonRow className="ion-margin-top">
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("employee_name")}*
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("employee_name")}
                                                        value={name}
                                                        onIonInput={
                                                            (e) => setName(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("employee_gender")}*
                                                    </IonLabel>
                                                    <IonSelect value={gender}
                                                        className="input-field"
                                                        placeholder={t("employee_gender")}
                                                        cancelText={t("cancel")}
                                                        okText={t("ok")}
                                                        onIonChange={
                                                            (e) => setGender(e.detail.value)
                                                        }>
                                                    
                                                        <IonSelectOption value="male">{t("male")}</IonSelectOption>
                                                        <IonSelectOption value="female">{t("female")}</IonSelectOption> 
                                                        <IonSelectOption value="other">{t("other")}</IonSelectOption> 
                                                    </IonSelect>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                    lines='full'
                                                    button={false}
                                                    id="open-date-input">
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("employee_dob")}*
                                                    </IonLabel>
                                                    <IonInput value={formatDate(selectedDate)} className="input-field" readonly={true} />
                                                    <IonModal trigger="open-date-input" showBackdrop={true} 
                                                        initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                        <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                            <IonToolbar>
                                                                <IonTitle>{t("employee_dob")}</IonTitle>
                                                            </IonToolbar>
                                                            <IonDatetime
                                                                value={selectedDate}
                                                                presentation="date"
                                                                cancelText={t("cancel")}
                                                                doneText={t("done")}
                                                                onIonChange={ev => setSelectedDate(ev.detail.value!)}
                                                                showDefaultButtons={true}
                                                                size="cover"
                                                            />
                                                        </IonContent>
                                                    </IonModal>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("employee_address")}*
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("employee_address")}
                                                        value={address}
                                                        onIonInput={
                                                            (e) => setAddress(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("employee_dep")}*
                                                </IonLabel>
                                                    <IonSelect value={department}
                                                            className="input-field"
                                                            placeholder={t("employee_dep")}
                                                            cancelText={t("cancel")}
                                                            okText={t("ok")}
                                                            onIonChange={
                                                                (e) => setDepartment(e.detail.value)
                                                        }>

                                                            {departments.length > 0 && departments.map((dep) => (
                                                                        (<IonSelectOption key={dep._id} value={dep._id}>{dep.name}</IonSelectOption>)
                                                                    ))}

                                                            
                                                    </IonSelect>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("enrollment_no")}
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("enrollment_no")}
                                                        value={enrollNo}
                                                        onIonInput={
                                                            (e) => setEnrollNo(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("employment_info")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("designation")}
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("designation")}
                                                        value={designation}
                                                        onIonInput={
                                                            (e) => setDesignation(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                    lines='full'
                                                    button={false}
                                                    id="open-join-input">
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("joining_date")}
                                                    </IonLabel>
                                                    <IonInput value={formatDate(joiningDate)} className="input-field" readonly={true} />
                                                    <IonModal trigger="open-join-input" showBackdrop={true} 
                                                        initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                        <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                            <IonToolbar>
                                                                <IonTitle>{t("joining_date")}</IonTitle>
                                                            </IonToolbar>
                                                            <IonDatetime
                                                                value={joiningDate}
                                                                presentation="date"
                                                                cancelText={t("cancel")}
                                                                doneText={t("done")}
                                                                onIonChange={ev => setJoiningDate(ev.detail.value!)}
                                                                showDefaultButtons={true}
                                                                size="cover"
                                                            />
                                                        </IonContent>
                                                    </IonModal>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("qualification")}
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("qualification")}
                                                        value={qualification}
                                                        onIonInput={
                                                            (e) => setQualification(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
           
                        </IonCol>
                        <IonCol sizeLg="6" size="12">
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("employee_account")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("employee_phone")}*
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("employee_phone")}
                                                        value={phone}
                                                        onIonInput={
                                                            (e) => setPhone(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("employee_email")}*
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("employee_email")}
                                                        value={email}
                                                        onIonInput={
                                                            (e) => setEmail(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
 
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("set_pass")}*
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("set_pass")}
                                                        value={newPass}
                                                        onIonInput={
                                                            (e) => setNewPass(e.detail.value !)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                       
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-padding-horizontal ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear"
                                >{t("add_salesrep")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
               
        </IonPage>
    );
};

export default AddSalesRep;