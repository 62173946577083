import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, chatboxEllipses } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';

interface Inquiry {
    _id: string,
    title: string,
    type: string,
    student: Student,
    messages: Message[],
    createdAt: Date
}

interface Message {
    timestamp: Date
}

interface Student {
    studentID: string,
    name: string
}

const LeaveApplications: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [leaves, setLeaves] = useState < Inquiry[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [skip, setSkip] = useState < number > (0);
    const [total, setTotal] = useState < number > (0);

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const formatDated = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    const loadItems = async () => {

        let limit = skip + 30;

        try {
            if ((authInfo.user.role === 'Teacher') || (authInfo.user.role === 'Employee' && !authInfo.user.roles.includes("Academic") && authInfo.user.roles.includes("Teacher")))
            {
                const result = await api.get(`/inquiries/getTeacherLeaves/${authInfo.user._id}?skip=${limit}`);
                if(result.data.inquiries && result.data.inquiries.length)
                {
                    setLeaves([...leaves, ...result.data.inquiries]);
                    setSkip(limit);
                }
            }
            if ((authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic")))
            {
                const result = await api.get(`/inquiries/getLeaves/${authInfo.user.instituteID}?skip=${limit}`);
                if(result.data.inquiries && result.data.inquiries.length)
                {
                    setLeaves([...leaves, ...result.data.inquiries]);
                    setSkip(limit);
                }
            }
            

        } catch (error: any) {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        }
    }

    useEffect(() => {

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                if ((authInfo.user.role === 'Teacher') || (authInfo.user.role === 'Employee' && !authInfo.user.roles.includes("Academic") && authInfo.user.roles.includes("Teacher")))
                {
                    const result = await api.get(`/inquiries/getTeacherLeaves/${authInfo.user._id}`);
                    setLeaves(result.data.inquiries);
                    setTotal(result.data.total);
                }
                if ((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic")))
                {
                    const result = await api.get(`/inquiries/getLeaves/${authInfo.user.instituteID}`);
                    setLeaves(result.data.inquiries);
                    setTotal(result.data.total);

                }
                setSkip(0);
                
            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/student`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("leave_applications")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("leave_applications")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}
                <IonGrid className="ion-no-padding mb-60">
                    <div className="ion-table">

                        <IonRow className="table-title ion-padding">

                            <IonCol sizeLg="4" size="8" className='table-heading'>
                                {t("title")}
                            </IonCol>
                            <IonCol sizeLg="4" size="0" className='table-heading ion-hide-lg-down'>
                                {t("student")}
                            </IonCol>
                            <IonCol size="3" className='table-heading'>
                                {t("date")}
                            </IonCol>
                            <IonCol size="1">
                                <IonIcon 
                                    icon={chatboxEllipses}
                                    color="light"
                                    className='table-icon'
                                />
                            </IonCol>
                        </IonRow>

                        {(leaves.length > 0) ? (leaves.map((leave, i) => {
                            return (
                                <IonRow key={leave._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}
                                onClick={() => history.push(`/app/inquiry/${leave._id}`)}>

                                    <IonCol sizeLg="4" size="8" className='table-field' >
                                        <IonRow>
                                            <IonCol>
                                                {leave.title}
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className='ion-hide-lg-up vid-responsive-field-1 text-capitalize'>
                                            <IonCol>
                                                <IonLabel>
                                                    {leave.student.name}
                                                </IonLabel>
                                            </IonCol>
                                        </IonRow>
                                    </IonCol>
                                    <IonCol sizeLg="4" size="0" className='table-field ion-hide-lg-down text-capitalize'>
                                        {leave.student.name}
                                    </IonCol>
                                    <IonCol size="3" className='table-field'>
                                        {formatDated(leave.createdAt)}
                                    </IonCol>
                                    <IonCol size="1" className='table-field'>
                                        <IonBadge className=''>{leave.messages.length+1}</IonBadge>
                                    </IonCol>
                                    
                                </IonRow>
                            )
                        })) : ( 
                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonCardContent>
                                        <IonItem lines="none">
                                            <IonIcon icon={alertCircleOutline}
                                                slot="start"
                                                color="danger" />
                                            <IonLabel className="list-title"><h3>{t("no_leaves")}</h3></IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        )} 
                    </div>

                    {(leaves.length > 0) &&
                    <IonRow>
                        <IonCol>
                            <IonInfiniteScroll
                                onIonInfinite={async (ev) => {
                                    await loadItems();
                                    ev.target.complete();
                                }}
                            >
                                <IonInfiniteScrollContent></IonInfiniteScrollContent>
                            </IonInfiniteScroll>
                        </IonCol>
                   </IonRow>
                    }
                    
                </IonGrid>

            </IonContent>
            <IonFooter className='footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                    <IonRow className="ion-padding-vertical">
                        <IonCol>
                            <IonRow>
                                <IonCol className="ion-padding-start footer-stats">
                                    {t("showing")} {total === 0 ? t("no_records") : `1 - ${leaves.length} ${t("of")} ${total}`} 
                                </IonCol>
                            </IonRow>
                          
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default LeaveApplications;