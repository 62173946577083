import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonSkeletonText,
    IonText,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import { RefresherEventDetail } from '@ionic/core';
import axios from 'axios';
import { alertCircleOutline, callSharp, checkmarkCircleSharp, closeCircleSharp, linkSharp, locationSharp, mailSharp } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import {useHistory, useParams} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { useAuth } from '../../components/AuthContext';
import ThemeToggle from '../../components/ThemeToggle';
import LanguageToggle from '../../components/LanguageToggle';

import 'swiper/css';
import "swiper/css/autoplay";
import '@ionic/react/css/ionic-swiper.css';

interface ParamTypes {
    schoolID: string
}

interface Feed {
    _id: string,
    title: string,
    media: string
}

interface Facility {
    _id: string,
    name: string,
    category: string,
    isActive: boolean,
    isChecked: boolean
}

interface AdmnClass {
    masterID: string,
    name: string,
    annualCost: number,
    applicationFees: number,
    isActive: boolean,
    photo?: string
}

interface Stat {
    masterID: string,
    title: string,
    info: string
}

const SchoolProfile: React.FC = () => {
    const history = useHistory();
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const {schoolID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [address, setAddress] = useState < string > ("");
    const [email, setEmail] = useState < string > ("");
    const [phone, setPhone] = useState < string > ("");
    const [website, setWebsite] = useState < string > ("");
    const [logo, setLogo] = useState < string > ("");
    const [photo, setPhoto] = useState < string > ("");
    const [session, setSession] = useState < string > ("");
    const [feed, setFeed] = useState < Feed[] > ([]);
    const [classes, setClasses] = useState < AdmnClass[] > ([]);
    const [refreshed, setRefreshed] = useState < number > (0);
    const [type, setType] = useState < string > ("");
    const [source, setSource] = useState < string > ("");
    const [text, setText] = useState < string > ("");
    const [isOpen, setIsOpen] = useState(false);
    const [userName, setUserName] = useState < string > ("");
    const [userEmail, setUserEmail] = useState < string > ("");
    const [userPhone, setUserPhone] = useState < string > ("");
    const [mode, setMode] = useState < string > ("");
    const [categories, setCategories] = useState < string[] > ([]);
    const [allFacilities, setAllFacilities] = useState < Facility[] > ([]);
    const [stats, setStats] = useState < Stat[] > ([]);

    const validateEmail = (email : string) => { 
        // eslint-disable-next-line no-control-regex
        const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
        return re.test(String(email).toLowerCase());
    };

    const validatePhone = (phone : string) => { 
        // eslint-disable-next-line no-control-regex
        const re = /^[0-9]{10}$/;
        return re.test(phone);
    };

    const handleInquiry = () => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        if (!source || source === "") {
            setMessage(t("inquiry_source_mandatory"));
            setIserror(true);
            return;
        }

        if (!type || type === "") {
            setMessage(t("inquiry_type_mandatory"));
            setIserror(true);
            return;
        }

        let userID = "Application";

        if (authInfo.loggedIn !== true) {
            if (!userName || userName === "") {
                setMessage(t("name_mandatory"));
                setIserror(true);
                return;
            }

            if (userEmail !== "")
            {
                if (validateEmail(userEmail) === false) 
                {
                    setMessage(t("invalid_email"));
                    setIserror(true);
                    return;
                }
            }
    
            if (validatePhone(userPhone) === false) {
                setMessage(t("invalid_mobile"));
                setIserror(true);
                return;
            }
    
        }
        else
        {
            userID = authInfo.user._id;
        }
  
        const addInq = () => {
          return new Promise((resolve, reject) => {
            api.post('/admissionInquiries/add', { 'instituteID': schoolID, 'instiName': name, userID, 'name': userName, 'phone': userPhone, 'email': userEmail, type, source, text }).then(res => {
              return resolve(res.data.inquiry.type);
            }).catch(err => reject(err));
          });
        }
  
        setShowLoading(true);
        addInq()
        .then(data => {
          
          setPass(data + t("has_been_submitted"));
          setIspass(true);
        })
        .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));
        
    }

    const shortlist = (schoolID: string, schoolName: string) => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                await api.get(`/admissionUsers/shortlist/${authInfo.user._id}/${schoolID}`);
                setPass(schoolName+t("has_been_shorted"));
                setIspass(true);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();
    }
   

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };
   
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!)
        });

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const result = await api.get(`/institutes/${schoolID}`);

                setMode(result.data.type);
                setName(result.data.name);
                setAddress(result.data.address);
                setEmail(result.data.email);
                setPhone(result.data.phone);

                if(result.data.website)
                {
                    setWebsite(result.data.website);
                }

                if(result.data.logo)
                {
                    setLogo(result.data.logo);
                }

                if(result.data.admnPhoto)
                {
                    setPhoto(result.data.admnPhoto);
                }
                if(result.data.admnSession)
                {
                    setSession(result.data.admnSession.sessionName);
                }
                if(result.data.admnClasses)
                {
                    setClasses(result.data.admnClasses);
                }

                if (result.data.type === "school")
                {

                    if(result.data.keyStats)
                    {
                        setStats(result.data.keyStats);
                    }

                    const allFac = await api.get(`/masters/getFacilities`);
                    const insituteFacilities = allFac.data.insituteFacilities;
                    let facx: Array < Facility > = [];
                    for (let i = 0; i < insituteFacilities.length ; i++) {
                        if (result.data.facilities && result.data.facilities.indexOf(insituteFacilities[i]._id) > -1) {
                            facx[i] = {'_id': insituteFacilities[i]._id, 'name': insituteFacilities[i].name, 'category': insituteFacilities[i].category, 'isActive': insituteFacilities[i].isActive, 'isChecked' : true};    
                        }
                        else 
                        {
                            facx[i] = {'_id': insituteFacilities[i]._id, 'name': insituteFacilities[i].name, 'category': insituteFacilities[i].category, 'isActive': insituteFacilities[i].isActive, 'isChecked' : false}; 
                        }       
                    }
                    setAllFacilities(facx);
    
                    //const unique = [...new Set(facx.map(item => item.category))];
                    //setCategories(unique);
    
                    //temp fix for ion col height issue
                    setCategories(["Classroom", "Lab", "Safety and Security" ,"Boarding", "Infrastructure", "Extra Curricular", "Sports and Fitness", "Advanced Facilities"]);
    
                }

            
                const feed = await api.get(`/feeds/getByInstitute/${schoolID}`);
                setFeed(feed.data);


                if (authInfo.loggedIn === true) {
                    setUserName(authInfo.user.name);
                    setUserPhone(authInfo.user.phone);
                    setUserEmail(authInfo.user.email);
                }

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchData();

    }, [authInfo, refreshed, schoolID]);


    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        {(authInfo.loggedIn === true) && (
                            <IonMenuButton color="secondary"/>
                        )}
                        <IonBackButton color="secondary" defaultHref="/home"/>
                        
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{
                        (name !== "") ? (name) : 
                        ((<IonSkeletonText animated={true} style={{ 'width': '140px', 'marginLeft': '20px' }}></IonSkeletonText>))
                        }</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{
                        (name !== "") ? (name) : 
                        ((<IonSkeletonText animated={true} style={{ 'width': '140px', 'marginLeft': '20px' }}></IonSkeletonText>))
                        }</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />


                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className="ion-no-padding">
                    <IonRow className="ion-no-padding">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <Swiper 
                                modules={[Autoplay]}
                                spaceBetween={0}
                                initialSlide={0}
                                slidesPerView={1}        
                                autoplay={{
                                    delay: 3000
                                }}
                            >
                                {(photo !== "") && (
                                <SwiperSlide >
                                        <img src={`${process.env.REACT_APP_STATIC_S3}/images/${photo}`} alt={name} style={{'width': '100%', 'height': 'auto'}} />
                                </SwiperSlide>  
                                )}

                                {(feed.length > 0) && feed.map((post) => (
                                (post.media && post.media !== photo) &&
                                    (
                                    <SwiperSlide key={post._id}>
                                            <img src={`${process.env.REACT_APP_STATIC_S3}/images/${post.media}`} alt={post.title}  style={{'width': '100%', 'height': 'auto'}} />
                                    </SwiperSlide>  
                                    )
                                ))}
                                
                            </Swiper>
                        </IonCol>
                    </IonRow>
                </IonGrid>

                <IonGrid className="ion-no-padding">

                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonRow>
                                        <IonCol size="9" className="insti-info">
                                            <IonRow className="ion-margin-horizontal ion-padding-vertical">
                                                <IonCol>
                                                    <p><span className="insti-name">{name}</span></p>
                                                    
                                                    <p><IonIcon icon={locationSharp} color="secondary" className="mt-15" /><span className="insti-address">{address}</span></p>
                                                    <p><IonIcon icon={callSharp} color="secondary" className="mt-05"/><span className="insti-address">{phone}</span></p>
                                                    <p><IonIcon icon={mailSharp} color="secondary" className="mt-05"/><span className="insti-address">{email}</span></p>
                                                    {(website !== "") && (
                                                    <p><IonIcon icon={linkSharp} color="secondary" className="mt-05"/><span className="insti-address">{website}</span></p>
                                                    )}
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className="ion-margin-horizontal ion-padding-bottom">
                                                    <IonCol>
                                                        <IonButton className="forth-button" fill="clear" onClick={() => (authInfo.loggedIn !== true) ? history.push(`/login`) : shortlist(schoolID, name)}>{t("shortlist")}</IonButton>
                                                        <IonButton color="primary" size="small" onClick={() => setIsOpen(true)}>{t("inquire")}</IonButton>
                                                    </IonCol>
                                                    
                                            </IonRow>
                                        </IonCol>
                                        <IonCol size="3" className="ion-text-center">
                                            <div className="vertical-container">
                                                <div className="vertical-center"> 
                                                {logo && (logo !== "") ?
                                                    (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${logo}`} alt={name} width="60" />)
                                                    : (<img src={`assets/images/institute.png`} alt="Logo" width="60" />)
                                                }
                                                </div>
                                            </div>        
                                        </IonCol>
                                    </IonRow>

                                </IonCardContent>
                            </IonCard>    
                        </IonCol>
                    </IonRow>

                    <IonModal isOpen={isOpen} showBackdrop={true} onDidDismiss={() => setIsOpen(false)}
                        initialBreakpoint={(authInfo.loggedIn !== true) ? 0.95 : 0.6} breakpoints={[0, 0.6, 0.95]} handle={true}>

                        <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                            <IonToolbar>
                                <IonTitle>{t("admn_inquiry")}</IonTitle>
                            </IonToolbar>
                            {(authInfo.loggedIn !== true) && (<>
                            <IonRow>
                                <IonCol>
                                    <IonCard className='ion-no-margin ion-margin-horizontal'>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("student_name")}*
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("student_name")}
                                                        value={userName}
                                                        onIonInput={
                                                            (e) => setUserName(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard className='ion-no-margin ion-margin-horizontal'>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("employee_phone")}*
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("employee_phone")}
                                                        value={userPhone}
                                                        onIonInput={
                                                            (e) => setUserPhone(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard className='ion-no-margin ion-margin-horizontal'>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("employee_email")}
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("employee_email")}
                                                        value={userEmail}
                                                        onIonInput={
                                                            (e) => setUserEmail(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            </>)}
                            <IonRow>
                                    <IonCol>
                                    <IonCard className='ion-no-margin ion-margin-horizontal'>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("type_of_inquiry")}</IonLabel>
                                                    <IonSelect value={type}
                                                            className="input-field"
                                                            cancelText={t("cancel")}
                                                            okText={t("ok")}
                                                            placeholder={t("type_of_inquiry")}
                                                            onIonChange={
                                                                (e) => setType(e.detail.value)
                                                        }>
                                                        <IonSelectOption value="general">{t("general")}</IonSelectOption>
                                                            {classes.map((classy) => (
                                                                    (classy.isActive) && 
                                                                (<IonSelectOption key={classy.masterID} value={classy.name}>{classy.name}</IonSelectOption>)
                                                            ))}
                                                    </IonSelect>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard className='ion-no-margin ion-margin-horizontal'>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("inquiry_source")}
                                                </IonLabel>
                                                    <IonSelect value={source}
                                                            className="input-field"
                                                            cancelText={t("cancel")}
                                                            okText={t("ok")}
                                                            placeholder={t("inquiry_source")}
                                                            onIonChange={
                                                                (e) => setSource(e.detail.value)
                                                        }>
                                                        <IonSelectOption value="google">{t("google")}</IonSelectOption>
                                                        <IonSelectOption value="instagram">{t("instagram")}</IonSelectOption>
                                                        <IonSelectOption value="youtube">{t("youtube")}</IonSelectOption>
                                                        <IonSelectOption value="linkedin">{t("linkedin")}</IonSelectOption>
                                                        <IonSelectOption value="justdial">{t("justdial")}</IonSelectOption>
                                                        <IonSelectOption value="website">{t("website")}</IonSelectOption>
                                                        <IonSelectOption value="newspaper">{t("newspaper")}</IonSelectOption>
                                                        <IonSelectOption value="magazine">{t("magazine")}</IonSelectOption>
                                                        <IonSelectOption value="poster">{t("poster")}</IonSelectOption>
                                                        <IonSelectOption value="reference">{t("reference")}</IonSelectOption>
                                                        <IonSelectOption value="other">{t("other")}</IonSelectOption>
                                                            
                                                    </IonSelect>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard className='ion-no-margin ion-margin-horizontal'>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                <IonLabel position="floating" className="input-label" color="secondary">{t("inquiry_text")}</IonLabel>
                                                <IonTextarea rows={2} value={text} onIonInput={(e) => setText(e.detail.value!)}></IonTextarea>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="ion-padding-horizontal ion-text-center">
                                    <IonButton onClick={handleInquiry}
                                        className="first-button"
                                        fill="clear"
                                        >{t("submit_inquiry")}</IonButton>

                                </IonCol>
                            </IonRow>
                        </IonContent>
                        
                    </IonModal>

                    <IonRow className='ion-margin-top'>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonRow>
                                <IonCol>
                                    <IonTitle className='vid-title' >
                                    {(mode === "school") ? t("classes") : t("courses")} {(session !== "") && `(${session})`}
                                    </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                {(classes.length > 0) ? (classes.map((admnClass) => 
                                    (admnClass.isActive) && 
                                    (
                                        <IonCard key={admnClass.masterID} className="ion-margin-vertical border-radius-10">

                                            <IonCardContent className="ion-no-padding">
                                                   
                                                <IonRow className="ion-margin-horizontal">
                                                    {(admnClass.photo) ? (
                                                    <>
                                                        <IonCol size="4" className='ion-padding-vertical'>
                                                            <img src={`${process.env.REACT_APP_STATIC_S3}/images/${admnClass.photo}`} alt={admnClass.name} style={{'width': '100%', 'height': 'auto'}} />
                                                        </IonCol>
                                                        <IonCol size="8" className='ion-padding'>
                                                            <p><span className="class-name" >{admnClass.name}</span></p>
                                                            
                                                            <p><span className="class-info">{t("application_cost")} {admnClass.applicationFees}/-</span></p>
                                                        </IonCol>
                                                    </>
                                                    ) : (
                                                    <IonCol className='ion-padding'>
                                                        <p><span className="class-name" >{admnClass.name}</span></p>
                                                        
                                                        <p><span className="class-info">{t("application_cost")}  {admnClass.applicationFees}/-</span></p>
                                                    </IonCol>
                                                    )}
                                                    
                                                </IonRow>
                                                <IonRow className={(authInfo.loggedIn !== true) ? "ion-margin-horizontal pb-08 blur-row" : "ion-margin-horizontal pb-08"}
                                                    onClick={() => (authInfo.loggedIn !== true) ? history.push(`/login`) : (mode === "school") ? history.push(`/app/application/${schoolID}/${admnClass.masterID}`) : history.push(`/app/school/${schoolID}/course/${admnClass.masterID}`)}>
                                                    <IonCol size="8" className='mt-05'>
                                                        <p><span className="class-info"> {(mode === "school") ? t("total_cost_first") : t("total_cost")} {t("rs")} {admnClass.annualCost}/-</span></p>
                                                    </IonCol>
                                                    <IonCol size="4" className="ion-text-right">
                                                        <IonButton color="secondary" size="small">{t("apply")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                                        
                                            </IonCardContent>
                                        </IonCard>
                                        )
                                        )) : (
                                        <IonCard className="ion-margin-vertical red-card">
                                            <IonCardContent>
                                                <IonItem lines="none">
                                                    <IonIcon icon={alertCircleOutline}
                                                        color="danger"
                                                        slot="start"
                                                        size="large" />
                                                    <IonLabel className="list-title"><h3>{t("admn_closed")}</h3></IonLabel>
                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                        )
                                    }
                                </IonCol>
                            </IonRow>
                              
                        </IonCol>
                    </IonRow>

                    {(mode === "school") && (stats.length > 0) && (
                    <IonRow className="ion-margin-bottom">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                      
                            <IonCard>
                                <IonCardHeader>
                                    <IonCardSubtitle className='info-subtitle'>{t("key_stats")}</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                              
                                    <IonRow className='ion-padding'>
                                       
                                        {stats.map((stat, i) => (
                                             <IonCol size="6" className="stat-info pb-16" key={i}>
                                                <IonText>
                                                    <p><span>{stat.title}</span></p>
                                                    <p><span className="stat-name" >{stat.info}</span></p>
                                                </IonText>
                                            </IonCol>
                                        ))}
                                        
                                    </IonRow>
                                          
                                </IonCardContent>
                            </IonCard>
                                      
                        </IonCol>
                    </IonRow>
                    )}

                    {(mode === "school") && (
                    <IonRow className="ion-margin-bottom">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                      
                            <IonCard>
                                <IonCardHeader>
                                    <IonCardSubtitle className='info-subtitle'>{t("school_facilities")}</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                              
                                    <IonRow>
                                        
                                        {categories && categories.length > 0 && categories.map((category, i) => (
                                            <IonCol size="6" className="cat-info" key={i} >
                                                <IonItemDivider>
                                                    <IonLabel className="cat-label">{category}</IonLabel>
                                                </IonItemDivider>
                                                {allFacilities.length > 1 && allFacilities.map((fac, i) => (
                                                    (fac.isActive) && (fac.category === category) &&
                                                    (<IonItem key={fac._id}
                                                        detail={false}
                                                        lines='none'
                                                        button={false}>
                                                        <IonIcon icon={fac.isChecked ? checkmarkCircleSharp : closeCircleSharp} color={fac.isChecked ? "success" : "danger"} className="fac-icon mt-05"/>
                                                        <IonLabel className="fac-label mt-15 pl-08 ion-text-wrap" >{fac.name}</IonLabel>
                                                    </IonItem>)
                                                ))}

                                            </IonCol>
                                        ))}
                                        
                                    </IonRow>
                                          
                                </IonCardContent>
                            </IonCard>
                                      
                        </IonCol>
                    </IonRow>
                    )}
                 
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default SchoolProfile;