import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import { medical } from 'ionicons/icons';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';

interface ParamTypes {
    incidentID: string
}

interface Student {
    _id: string,
    name: string,
    dob: Date,
    gender?: string,
    bloodType?: string,
    isDisabled?: boolean,
    disabilityInfo?: string,
    medicalInfo?: string,
    isActive: boolean
}

const IncidentDetail: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const {incidentID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");

    const [selectedStudent, setSelectedStudent] = useState <Student> ();
    const [type, setType] = useState <string> ("");
    const [description, setDescription] = useState <string> ("");
    const [treatment, setTreatment] = useState <string> ("");
    const [dated, setDated] = useState <Date> ();

    const api = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      timeout: parseInt(process.env.REACT_APP_API_TO!),
      headers: {
          'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const formatDated = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB');
    };

    const handleSubmit = () => {

        if(!type || type === "")
        {
            setMessage(t("incident_type_mandatory"));
            setIserror(true);
            return;
        }

        if(!description || description === "")
        {
            setMessage(t("incident_desc_mandatory"));
            setIserror(true);
            return;
        }

        if(!treatment || treatment === "")
        {
            setMessage(t("incident_treat_mandatory"));
            setIserror(true);
            return;
        }
  
        const updateIncident = () => {
          return new Promise((resolve, reject) => {
            api.put(`incidents/${incidentID}`, {  type, description, treatment }).then(res => {
              return resolve(res.data.type);
            }).catch(err => reject(err));
          });
        }
  
        setShowLoading(true);
        updateIncident()
        .then(data => {
          
          setPass(data+t("has_been_updated"));
          setIspass(true);
        })
        .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));
        
    }

    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const res = await api.get(`/incidents/${incidentID}`);

                setType(res.data.type);
                setDescription(res.data.description);
                setTreatment(res.data.treatment);
                setDated(res.data.createdAt);
                const stud = await api.get(`/students/${res.data.student.studentID}`);
                setSelectedStudent(stud.data);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchData();

    }, [authInfo, incidentID]);
      
    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/medical/incidents`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("incident_details")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("incident_details")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                
                 <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className="ion-no-padding mb-60">

                            {(selectedStudent) && (
                            <IonRow className="mt-30">
                                <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                    <IonCard className='medical-card'>
                                        <IonCardHeader>
                                            <IonCardTitle className='mt-10'>
                                                {t("student_data")}
                                                <IonIcon 
                                                className='ion-float-right'
                                                color="danger"
                                                size="large"
                                                icon={medical}/>
                                            </IonCardTitle>
                                            
                                        </IonCardHeader>
                                        <IonCardContent className="ion-no-padding">
                                            <IonCardSubtitle className='ion-padding-horizontal border-bottom-sec text-bold'>{t("basic_info")}</IonCardSubtitle>
                                            <IonRow className="ion-padding text-dark">
                                                <IonCol size="5" className='text-bold'>
                                                    {t("student_name")}:<br/>
                                                    {t("student_dob")}:<br/>
                                                    {t("student_blood")}:
                                                </IonCol>
                                                <IonCol size="7">
                                                    {selectedStudent.name}<br/>
                                                    {formatDated(selectedStudent.dob)}<br/>
                                                    {selectedStudent.bloodType}
                                                </IonCol>
                                            </IonRow>
                                            {(selectedStudent.isDisabled) && (
                                            <>
                                                <IonCardSubtitle className='ion-padding-horizontal border-bottom-sec text-bold'>{t("disability_info")}</IonCardSubtitle>
                                                <IonRow className="ion-padding text-dark">
                                                    <IonCol>
                                                        {selectedStudent.disabilityInfo}
                                                    </IonCol>
                                                </IonRow>
                                            </>
                                            )}

                                                <IonCardSubtitle className='ion-padding-horizontal border-bottom-sec text-bold'>{t("medical_condition")}</IonCardSubtitle>
                                                <IonRow className="ion-padding text-dark">
                                                    <IonCol>
                                                        {selectedStudent.medicalInfo && (selectedStudent.medicalInfo !== "") ? selectedStudent.medicalInfo : t("no_medical_info")}
                                                    </IonCol>
                                                </IonRow>

                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )}

                            <IonRow className="mt-30">
                                <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("incident_details")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            {dated && (
                            <IonRow className="ion-margin-top">
                                <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("recorded_on")}
                                                </IonLabel>
                                                <IonInput className="input-field" value={formatDated(dated)} readonly={true} />
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )}
                            <IonRow>
                                <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("incident_type")}*
                                                </IonLabel>
                                                    <IonSelect value={type}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                        className="input-field" 
                                                        placeholder={t("incident_type")}
                                                        onIonChange={
                                                            (e) => setType(e.detail.value)
                                                    }>
                                                    
                                                        <IonSelectOption value="injury">{t("injury")}</IonSelectOption>
                                                        <IonSelectOption value="illness">{t("illness")}</IonSelectOption>      
                                                        
                                                    </IonSelect>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                
                            </IonRow>
                            <IonRow>
                                <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                lines='full'
                                                button={false}>
                                                <IonLabel position="floating" color="secondary">{t("incident_desc")}*</IonLabel>
                                                <IonTextarea rows={8} value={description} onIonInput={
                                                        (e) => setDescription(e.detail.value!)
                                                }></IonTextarea>
                                            
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                lines='full'
                                                button={false}>
                                                <IonLabel position="floating" color="secondary">{t("incident_treat")}*</IonLabel>
                                                <IonTextarea rows={8} value={treatment} onIonInput={
                                                        (e) => setTreatment(e.detail.value!)
                                                }></IonTextarea>
                                            
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>

                    
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("update_incident")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default IncidentDetail;