import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail
} from '@ionic/react';
import { alertCircleOutline, informationCircleOutline, trash } from 'ionicons/icons';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../components/AuthContext";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';

interface Application {
    _id: string,
    applicationType: string,
    amount: number,
    status: string,
    classInfo: ClassInfo,
    instituteInfo: InstituteInfo,
    childInfo: ChildInfo,
    createdAt: Date
}

interface ClassInfo {
    classID: string,
    className: string
}

interface InstituteInfo {
    instituteID: string,
    instituteName: string
}

interface ChildInfo {
    childID: string,
    childName: string
}

const PendingApplications: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [pendingApps, setPendingApps] = useState < Application[] > ([]);
    const [refreshed, setRefreshed] = useState < number > (0);
    const [showAlert, setShowAlert] = useState < boolean > (false);
    const [selectedApp, setSelectedApp] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [skip, setSkip] = useState < number > (0);
    const [total, setTotal] = useState < number > (0);

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    const loadItems = async () => {

        let limit = skip+30;

        try {
            const result = await api.get(`/applications/getUnpaidUser/${authInfo.user._id}?skip=${limit}`);
            if(result.data.applications && result.data.applications.length)
            {
                setPendingApps([...pendingApps, ...result.data.applications]);
                setSkip(limit);
            }
            

        } catch (error: any) {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        }
        
    }

    const handleDelete = () => {

        const deleteApp = () => {
          return new Promise((resolve, reject) => {
            api.put(`/applications/${selectedApp}`, {status: 'removed'}).then(res => {
                  return resolve(true);
            }).catch(err => reject(err));
          });
        }
  
        setShowLoading(true);
        deleteApp()
        .then(() => {
  
          setPass(t("application_removed"));
          setIspass(true);
        })
        .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));
        
    }
    
    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const unpaid = await api.get(`/applications/getUnpaidUser/${authInfo.user._id}`);
                setPendingApps(unpaid.data.applications);
                setTotal(unpaid.data.total);
                setSkip(0);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

     
        fetchData();

    }, [authInfo, refreshed]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/home`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("my_cart")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("my_cart")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false);
                            setRefreshed(Math.random());
                        }
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    header={t("confirm")}
                    message={t("confirm_delete_app")}
                    buttons={[
                        {
                        text: t("no"),
                        role: 'cancel',
                        cssClass: 'secondary',
                        },
                        {
                        text: t("yes"),
                        handler: () => {
                            handleDelete();
                        }
                        }
                    ]}
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className='mb-60'>
                        
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                      
                            {(pendingApps.length > 0) && pendingApps.map((app) => {
                                return (
                                        <IonCard key={app._id} className={(app.applicationType === 'workshop') ? 'ion-margin-vertical planner-card-3 ion-padding' : 'ion-margin-vertical class-card'} >

                                            <IonCardContent className="ion-no-padding">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonRow className="pb-08">
                                                                <IonCol size="11">
                                                                    <p><span className="class-name" >{app.instituteInfo.instituteName}</span></p>
                                                                    
                                                                    <p><span className="class-info">{app.classInfo.className} &#8226; {app.childInfo.childName}</span></p>
                                                                    <p><span className="class-info-2"> {formatDate(app.createdAt)}</span></p>
                                                                </IonCol>
                                                                <IonCol size="1">
                                                                    <p><IonIcon icon={informationCircleOutline} size="large" color="success"
                                                                    onClick={() => {
                                                                        history.push(`/app/form/${app._id}`);
                                                                    }}/>
                                                                    </p>
                                                                    <p>
                                                                    <IonIcon icon={trash} size="large" color="danger" className="pt-16"
                                                                    onClick={() => {
                                                                        setSelectedApp(app._id);
                                                                        setShowAlert(true);
                                                                    }}/>
                                                                    </p>
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow className="pr-16">
                                                                <IonCol size="8" className='mt-05'>
                                                                    <p><span className="class-info-3">{t("app_fees")} {app.amount}/-</span></p>
                                                                </IonCol>
                                                                <IonCol size="4" className="ion-text-right">
                                                                    <IonButton color="primary" size="small" onClick={() => history.push(`/app/application/${app._id}`)}>{t("checkout")}</IonButton>
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonCol>
                                                      
                                                    </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                        );
                                    })
                                }
                                {(pendingApps.length === 0) && (
                                     <IonCard className='red-card'>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger"
                                                    size="large" />
                                                <IonLabel className="list-title"><h3>{t("no_applications")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                )}
                        </IonCol>
                    </IonRow>

                    {(pendingApps.length > 0) &&
                        <IonRow>
                            <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonInfiniteScroll
                                    onIonInfinite={async (ev) => {
                                        await loadItems();
                                        ev.target.complete();
                                    }}
                                    >
                                    <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                            </IonCol>
                        </IonRow>
                    }
                  
                </IonGrid>
            </IonContent>
            <IonFooter className=' footer-shade'>
                <IonGrid>
                    <IonRow className='ion-text-center'>
                        <IonCol className="ion-padding footer-stats">
                            {t("showing")} {total === 0 ? t("no_records") : `1 - ${pendingApps.length} ${t("of")} ${total}`} 
                        </IonCol>
                      
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
}

export default PendingApplications;
