import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, receipt } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';
import usePageUnloadHandler from '../../../components/PageUnloadHandler';

interface Invoice {
    _id: string,
    amount: number,
    invoiceDate: Date,
    dueDate: Date,
    paidAt: Date
}

interface Session {
    _id: string,
    name: string,
    isActive: boolean
}

interface ClassInterface {
    _id: string,
    name: string,
    year: string,
    isActive: boolean
}
interface ClassStudent {
    _id: string,
    name: string,
    isActive: boolean
}

interface ParamTypes {
    studentID?: string
}

const StudentInvoices: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {studentID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [credits, setCredits] = useState < Invoice[] > ([]);
    const [total, setTotal] = useState < number > (0);
    const [sum, setSum] = useState < number > (0);
    const [skip, setSkip] = useState < number > (0);
    const [scrollPosition, setScrollPosition] = useState < number > (0);
    const scrollContent = useRef<HTMLIonContentElement | null> (null);
    const [initialRender, setInitialRender] = useState < boolean > (true);
    const [selectedStudent, setSelectedStudent] = useState <string> ("");
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [session, setSession] = useState < string > ("");
    const [classes, setClasses] = useState < ClassInterface[] > ([]);
    const [classi, setClassi] = useState < string > ("");
    const [students, setStudents] = useState < ClassStudent[] > ([]);

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });
   
    const handleScrollEnd = async () => {
        const elem = await scrollContent.current?.getScrollElement();
        if(elem) {
            setScrollPosition(elem.scrollTop);
        }
    };

    const formatDated = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    const loadItems = async () => {

        if (!selectedStudent || selectedStudent === "") {
            setMessage(t("student_mandatory"));
            setIserror(true);
            return;
        }

        let limit = skip + 30;
       
        try {
            const result = await api.get(`/invoices/getByStudent/${selectedStudent}?skip=${limit}`);

            if(result.data.invoices && result.data.invoices.length)
            {
                setCredits([...credits, ...result.data.invoices]);
                setSkip(limit);
            }

        } catch (error: any) {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        }
    }

      const handleSession = (sessionName : string) => {

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${sessionName}`);
                setClasses(result.data);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();
        setSession(sessionName);
        setClassi("");
        setSelectedStudent("");
        setStudents([]);
        setCredits([]);
        setTotal(0);
        setSum(0);
        setSkip(0);
    }

    const handleClassi = (classID : string) => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/students/getByClass/${classID}`);
                setStudents(studs.data);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        fetchUp();
        setClassi(classID);
        setSelectedStudent("");
        setCredits([]);
        setTotal(0);
        setSum(0);
        setSkip(0);
    }

    const handleStudent = (studentID : string) => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/invoices/getByStudent/${studentID}`);
                setCredits(result.data.invoices);
                setTotal(result.data.total);
                setSum(result.data.sum);
                setSkip(0);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        fetchUp();
        setSelectedStudent(studentID);
    }

    useEffect(() => {

        const storedState = sessionStorage.getItem(history.location.pathname);
        if (history.action === "POP" && storedState) {
            const { credits, total, sum, classes, sessions, students, classi, session, skip, selectedStudent, scrollPosition } = JSON.parse(storedState);
                // Data is present in local storage, use it to initialize state
                setCredits(credits);
                setTotal(total);
                setSum(sum);
                setSkip(skip);
                setClasses(classes);
                setSessions(sessions);
                setStudents(students);
                setClassi(classi);
                setSession(session);
                setSelectedStudent(selectedStudent);
               
                setTimeout(() => scrollContent.current?.scrollToPoint(0, scrollPosition, 500), 500);
            } 
            else
            {

                const fetchUp = async () => {
                    setShowLoading(true);
                    try {
                            const res = await api.get(`/sessions/getAllInstitute/${authInfo.user.instituteID}`);
                            setSessions(res.data);
                            
                            if (studentID)
                            {
                                const stud = await api.get(`/students/${studentID}`);
                            
                                const classInfo = await api.get('/classes/' + stud.data.classID);
                                setSession(classInfo.data.sessionID);
            
                                const query = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${classInfo.data.sessionID}`);
                                setClasses(query.data);
                                setClassi(stud.data.classID);
                                
                                const studs = await api.get(`/students/getByClass/${stud.data.classID}`);
                                setStudents(studs.data);
                                setSelectedStudent(studentID);
            
                                const result = await api.get(`/invoices/getByStudent/${studentID}`);
                                setCredits(result.data.invoices);
                                setTotal(result.data.total);
                                setSum(result.data.sum);
                            }
                            else
                            {
                                setSession("");
                                setClasses([]);
                                setClassi("");
                                setStudents([]);
                                setSelectedStudent("");
                                setCredits([]);
                                setTotal(0);
                                setSum(0);
                            }
                            setSkip(0);

                    } catch (error: any) {
                        setMessage(error.response?.data.message || t("something_went_wrong"));
                        setIserror(true);
                    } finally {
                        setShowLoading(false);
                    }
                    
                };

                fetchUp();
            }
           
     
        
        setInitialRender(false);

    }, [studentID, authInfo.user.instituteID, history.action, history.location.pathname]);

    // Save state to local storage whenever it changes
    useEffect(() => {
        if (!initialRender) 
        {
            const currentState = {
            credits,
            total,
            sum,
            skip,
            classes,
            sessions,
            students,
            classi,
            session,
            selectedStudent,
            scrollPosition
            };
            sessionStorage.setItem(history.location.pathname, JSON.stringify(currentState));
        }
    }, [credits, total, sum, skip, classes, sessions, students, classi, session, selectedStudent, scrollPosition, history.location, initialRender]);

    // Effect to handle page reload 
    usePageUnloadHandler();

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/accounting`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("student_invoices")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade" ref={scrollContent} scrollEvents={true} onIonScrollEnd={handleScrollEnd}>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("student_invoices")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                    
                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}
                
                <IonGrid className="ion-no-padding overflow-x">
                    <IonRow className="min-md">
      
                        <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                     <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("class_session")}</IonLabel>
                                            <IonSelect value={session}
                                                    className="input-field"
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("class_session")}
                                                    onIonChange={
                                                        (e) => handleSession(e.detail.value)
                                                }>

                                                    {sessions.map((session) => 
                                                        (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                    )}

                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    
                        <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}</IonLabel>
                                            <IonSelect value={classi}
                                                    className="input-field"
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}
                                                    onIonChange={
                                                        (e) => handleClassi(e.detail.value)
                                                }>
                                                     
                                                    {classes.map((classy) => (
                                                        (classy.isActive) &&
                                                        (<IonSelectOption key={classy._id} value={`${classy._id}`}>{classy.name}</IonSelectOption>)
                                                    ))}
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">                   
                                    
                                    <IonItem
                                    detail={false}
                                    lines='full'
                                    button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">{t("select_student")}</IonLabel>
                                        <IonSelect value={selectedStudent}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    className="input-field text-capitalize"
                                                    placeholder={t("select_student")}
                                                    onIonChange={
                                                        (e) => e.detail.value && handleStudent(e.detail.value)
                                                }>
                                                    {students.map((student) => (
                                                        (student.isActive) &&
                                                        (<IonSelectOption key={student._id} value={`${student._id}`} className="text-capitalize">{student.name}</IonSelectOption>)
                                                    ))}
                                                 
                                        </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <IonGrid className="ion-no-padding mb-60">
                    <div className="ion-table mt-10">
                                <IonRow className="table-title ion-padding">

                                    <IonCol size="1" className='table-heading'>
                                    {t("s_no")}
                                    </IonCol>
                                    <IonCol size="3.5" className='table-heading'>
                                    {t("due_on")}
                                    </IonCol>
                                    <IonCol size="4" className='table-heading'>
                                    {t("status")}
                                    </IonCol>
                                    <IonCol size="2.5" className='table-heading ion-text-right'>
                                    {t("amount")}
                                    </IonCol>
                                    <IonCol size="1" className='ion-text-right'>
                                        <IonIcon 
                                            icon={receipt}
                                            color="light"
                                            className='table-icon'
                                            />
                                    </IonCol>
                                </IonRow>
                               

                                {(credits.length > 0) ? (credits.map((credit, i) => {
                                    return (
                                        <IonRow key={credit._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}
                                        onClick={
                                            () => history.push(`/app/invoice/${credit._id}`)
                                        }>
                                            
                                                <IonCol size="1" className='table-field action-button' >
                                                    {i+1}.
                                                </IonCol>
                                                <IonCol size="3.5" className='table-field'>
                                                    {formatDated(credit.dueDate)}
                                                </IonCol>
                                                <IonCol size="4" className='table-field text-capitalize'>
                                                {(credit.paidAt !== undefined) ? t("cleared") : ((new Date(credit.dueDate)) < (new Date(Date.now()))) ? t("past_due") : ((new Date(credit.invoiceDate)) <= (new Date(Date.now()))) ? t("uncleared") : t("scheduled")}
                                                </IonCol>
                                                <IonCol size="2.5" className='table-field ion-text-right'>
                                                    {credit.amount}
                                                </IonCol>
                                                <IonCol size="1" className='ion-text-right'>
                                                <IonIcon 
                                                        icon={receipt}
                                                        color="dark"
                                                        className='table-icon mt-10'
                                                        
                                                        />
        
                                                </IonCol>
                                             
                                        </IonRow>
                                    )
                                })) : ( 
                                <IonRow>
                                    <IonCol>
                                        <IonCard>
                                            <IonCardContent>
                                                <IonItem lines="none">
                                                    <IonIcon icon={alertCircleOutline}
                                                        slot="start"
                                                        color="danger" />
                                                    <IonLabel className="list-title"><h3>{t("no_invoices")}</h3></IonLabel>

                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                )
                                } 
                                </div>

                    {(credits.length > 0) &&
                    <IonRow>
                        <IonCol>
                            <IonInfiniteScroll
                                onIonInfinite={async (ev) => {
                                    await loadItems();
                                    ev.target.complete();
                                }}
                            >
                                <IonInfiniteScrollContent></IonInfiniteScrollContent>
                            </IonInfiniteScroll>
                        </IonCol>
                   </IonRow>
                    }
                   
                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                <IonCol size="6" className="ion-padding-start footer-stats">
                                {t("showing")} {total === 0 ? t("no_records") : `1 - ${credits.length} ${t("of")} ${total}`} 
                                </IonCol>
                                <IonCol size="6" className="ion-padding-start footer-stats">
                                {t("total_amount")} {Math.abs(sum)}
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-vertical overflow-x">
                                <IonCol className='min-md'>
                                    <IonButton 
                                        onClick={
                                            () => {
                                                if (selectedStudent === "")
                                                {
                                                    setMessage(t("select_student"));
                                                    setIserror(true);
                                                }
                                                else
                                                {
                                                    history.push(`/app/collection/${selectedStudent}`);
                                                }
                                            }
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("collect_fees")}</IonButton>   

                                    <IonButton 
                                        onClick={
                                            () => {
                                                if (selectedStudent === "")
                                                {
                                                    setMessage(t("select_student"));
                                                    setIserror(true);
                                                }
                                                else
                                                {
                                                    history.push(`/app/student/ledger/${selectedStudent}`);
                                                }
                                            }
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("view_ledger")}</IonButton>

                                <IonButton 
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("export_excel")}</IonButton>


                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default StudentInvoices;