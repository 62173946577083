import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';

const TeacherStatus: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);
    const [categories, setCategories] = useState < string[] > ([]);
    const [series, setSeries] = useState < number[] > ([]);
    const [total, setTotal] = useState < number > (0);
    const [present, setPresent] = useState < number > (0);
    const [series1, setSeries1] = useState < number[] > ([0, 0]);

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const options1 = {
        colors : ['#2dd36f', '#eb445a'],
        labels: [t("present"), t("absent")],
        dataLabels: {
            enabled: false
        },
        legend: {
            position: 'bottom' as 'bottom',
            labels: {
                colors: '#777'
            },
        }
    };

    const daySeries =  [{
        name: t("present"),
        data: series
    }];

    const options = {
        chart: {
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight' as 'straight'
        },
        xaxis: {
            categories: categories,
        },
        yaxis: {
            title: {
            text: t("present")
            }
        },
        tooltip: {
            theme: 'dark'
        }
    };

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    useEffect(() => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {

                const report = await api.get('/staffAttends/reports/teacher/' + authInfo.user.instituteID);

                setCategories(report.data.categories);
                setSeries(report.data.series);

                setTotal(report.data.total);
                setPresent(report.data.today);

                let percent = Math.round((report.data.today/report.data.total)*100);

                setSeries1([percent, 100-percent]);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo, refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/teachers`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("attendance_report")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("attendance_report")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className="ion-no-padding mb-60 max-xl">

                    <IonRow className='ion-margin-top'>
                        <IonCol size="12" sizeLg="6" className='ion-padding'>
                            <IonRow>
                                <IonCol>
                                    <IonCard className='action-card-s'>
                                        <IonCardHeader className="ion-text-center">
                                            <IonCardSubtitle>{t("today_attendance")}</IonCardSubtitle>
                                        </IonCardHeader>
                                        <IonCardContent className="reports-attend">
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol size='4'>
                                                        
                                                                <h1>{t("present")} <IonText className='attend-badge' color="success">{present}</IonText></h1>
                                                                <br/>
                                                                <h1>{t("total")} <IonText className='attend-badge' >{total}</IonText></h1>
                                                        
                                                    </IonCol>
                                                    <IonCol size='8'>
                                                        <Chart options={options1} series={series1} type="donut" width={180} />
                                                    </IonCol>
                                                </IonRow>
                                            
                                            </IonGrid>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            
                           
                        </IonCol>
                       
                        <IonCol size="12" sizeLg="6" className='ion-padding'>
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='list-title'>
                                        {t("last_10_days")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol>
                                     <Chart options={options} series={daySeries} type="line" height={400}  />
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                   
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default TeacherStatus;
