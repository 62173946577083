import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonProgressBar,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import { helpCircleOutline } from 'ionicons/icons';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import { useAuth } from '../../../components/AuthContext';

const Essentials: React.FC = () => {
    const {t} = useTranslation();
    const {authInfo} = useAuth()!;
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [showDownloading, setShowDownloading] = useState < boolean > (false);
    const [classes, setClasses] = useState < number > (0);
    const [eventsNo, setEventsNo] = useState < number > (0);
    const [notifs, setNotifs] = useState < number > (0);

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    useEffect(() => {

        const fetchData = async () => {
            setShowDownloading(true);
            try {
                const stats = await api.get(`/institutes/essential/${authInfo.user.instituteID}`);
                const statsData = stats.data;

                setClasses(statsData.classes);
                setEventsNo(statsData.events);
                setNotifs(statsData.notifs);
            }
            catch (error: any) {
                if (error.response) {
                    setMessage(error.response.data.message);
                    setIserror(true);
                    
                } else {
                    setMessage(t("unable_to_connect"));
                    setIserror(true);
                }
            } finally {
                setShowDownloading(false);
            }
        };

        fetchData();

    }, [authInfo]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary" />
                        <IonBackButton color="secondary" defaultHref="/app" />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{(authInfo.institute.type === "school") ? t("school_essentials") : t("institute_essentials")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                    {showDownloading && (
                        <IonProgressBar type="indeterminate"></IonProgressBar>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{(authInfo.institute.type === "school") ? t("school_essentials") : t("institute_essentials")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className='mb-60'>

                    <IonRow className="ion-padding-vertical max-lg">
                        <IonCol>
                            <IonRow>
                                <IonCol sizeMd="6" size="12">
                                    <IonCard className="dashboard-card dash-bg-5">
                                        <IonCardContent className="ion-padding">
                                            <IonRow>
                                                <IonCol size="9" className="dash-title text-dark">         
                                                    {t("essentials_overview")}
                                                </IonCol>
                                                <IonCol size="3" className='dash-icon'>
                                                    <div className="vertical-container">
                                                        <div className="vertical-center"> 
                                                            <img alt={t("essentials_overview")} src="assets/images/class-stats.png" />
                                                        </div>
                                                    </div>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className="ion-margin-top">
                                                <IonCol>
                                                    <IonRow>
                                                        <IonCol size="8" className="dash-label-2">
                                                        {(authInfo.institute.type === "school") ? t("total_classes") : t("total_batches")}
                                                        </IonCol>
                                                        <IonCol size="4" className='dash-info-2 ion-text-right'>
                                                        {classes}
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol size="8" className="dash-label-2">
                                                        {t("total_events")}
                                                        </IonCol>
                                                        <IonCol size="4" className='dash-info-2 ion-text-right'>
                                                        {eventsNo}
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol size="8" className="dash-label-2">
                                                        {t("notifications_sent")}
                                                        </IonCol>
                                                        <IonCol size="4" className='dash-info-2 ion-text-right'>
                                                        {notifs}
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                                
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-top">
                                <IonCol sizeMd="6" size="12">

                                    <IonRow>
                                        <IonCol>
                                            <IonTitle id="open-class-info" className="border-bottom-sec">
                                                {(authInfo.institute.type === "school") ? t("classes") : t("batches")}
                                                <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                            </IonTitle>
                                            <IonPopover trigger="open-class-info" className='info-popover'>
                                                <IonCard className='note-card-3 ion-no-margin'>
                                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                                        <IonRow>
                                                            <IonCol className='note-text'>
                                                                <p>
                                                                {(authInfo.institute.type === "school") ? t("classes_help") : t("batches_help")}
                                                                </p>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCardContent>
                                                </IonCard>
                                            </IonPopover>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-padding-vertical">
                                        <IonCol size="6">
                                            <IonCard className="action-card" routerLink={`/app/classes`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow className="ion-padding-top">
                                                        <IonCol className='ion-no-padding'>
                                                            <img alt={(authInfo.institute.type === "school") ? t("manage_classes") : t("manage_batches")} src="assets/images/class.png"/>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{(authInfo.institute.type === "school") ? t("manage_classes") : t("manage_batches")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        <IonCol size="6">
                                            <IonCard className="action-card" routerLink={`/app/class/add`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear" >{(authInfo.institute.type === "school") ? t("add_new_class") : t("add_new_batch")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                            <IonCard className="action-card" routerLink={`/app/class/promote`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{(authInfo.institute.type === "school") ? t("promote_class") : t("promote_batch")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>

                                    <IonRow>
                                        <IonCol>
                                            <IonTitle id="open-session-info" className="border-bottom-sec">
                                                {t("sessions")}
                                                <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                            </IonTitle>
                                            <IonPopover trigger="open-session-info" className='info-popover'>
                                                <IonCard className='note-card-3 ion-no-margin'>
                                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                                        <IonRow>
                                                            <IonCol className='note-text'>
                                                                <p>
                                                                    {t("session_help")}
                                                                </p>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCardContent>
                                                </IonCard>
                                            </IonPopover>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-padding-vertical">
                                        
                                        <IonCol size="6">
                                            <IonCard className="action-card action-card-2" routerLink={`/app/sessions`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow className="ion-padding-top">
                                                        <IonCol className='ion-no-padding'>
                                                            <img alt={t("manage_sessions")} src="assets/images/timetable-1.png"/>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("manage_sessions")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        <IonCol size="6">
                                            <IonCard className="action-card action-card-2" routerLink={`/app/sessions/add`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("add_session")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>

                            </IonCol>
                            <IonCol sizeMd="6" size="12">
                                <IonRow>
                                    <IonCol>
                                        <IonTitle id="open-config-info" className="border-bottom-sec">
                                            {t("configuration")}
                                            <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                        </IonTitle>
                                        <IonPopover trigger="open-config-info" className='info-popover'>
                                            <IonCard className='note-card-3 ion-no-margin'>
                                                <IonCardContent className="ion-no-padding ion-no-margin">
                                                    <IonRow>
                                                        <IonCol className='note-text'>
                                                            <p>
                                                                {t("config_help")}
                                                            </p>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonPopover>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-padding-vertical">
                                    <IonCol size="6">
                                        <IonCard className="action-card" routerLink={`/app/planner`}>
                                            <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className="ion-no-padding">
                                                        <img alt={t("event_calendar")} src="assets/images/planner.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("event_calendar")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                    <IonCol size="6">
                                        <IonCard className="action-card" routerLink={`/app/config/institute`}>
                                            <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{(authInfo.institute.type === "school") ? t("school_info") : t("institute_info")} </IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                        <IonCard className="action-card" routerLink={`/app/config/institute/location`}>
                                            <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{(authInfo.institute.type === "school") ? t("school_location") : t("institute_location")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                    {(authInfo.institute.type === "school") && (
                                    <IonCol size="6">
                                        <IonCard className="action-card" routerLink={`/app/config/houses`}>
                                            <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className='ion-no-padding'>
                                                        <img alt={t("school_houses")} src="assets/images/store.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("school_houses")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                    )}
                                
                                </IonRow>
                                
                                {authInfo.institute.medical && (<>
                                <IonRow>
                                    <IonCol>
                                        <IonTitle id="open-medical-info" className="border-bottom-sec">
                                            {t("medical_room")}
                                            <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                        </IonTitle>
                                        <IonPopover trigger="open-medical-info" className='info-popover'>
                                            <IonCard className='note-card-3 ion-no-margin'>
                                                <IonCardContent className="ion-no-padding ion-no-margin">
                                                    <IonRow>
                                                        <IonCol className='note-text'>
                                                            <p>
                                                            {t("medical_help")}
                                                            </p>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonPopover>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-padding-vertical">
                                    <IonCol size="6">
                                        <IonCard className="action-card action-card-2" routerLink={`/app/medical/incidents`}>
                                            <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow className="ion-padding-top">
                                                        <IonCol className='ion-no-padding'>
                                                            <img alt={t("view_medical_records")} src="assets/images/medical-1.png"/>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("view_medical_records")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                    <IonCol size="6">
                                        <IonCard className="action-card action-card-2" routerLink={`/app/medical/incidents/add`}>
                                            <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("record_an_incident")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                    
                                </IonRow>
                                </>)}
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>
                 
            </IonGrid>
        </IonContent>
    </IonPage>
    );
}

export default Essentials;
