import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, chatboxEllipses } from 'ionicons/icons';
import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import {useAuth} from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';
import usePageUnloadHandler from '../../../../components/PageUnloadHandler';

interface Chat {
    _id: string,
    title: string,
    type: string,
    employee?: Employee,
    employees?: string[],
    messages: Message[],
    updatedAt: Date
}

interface Message {
    timestamp: Date
}

interface Employee {
    employeeID: string,
    name: string
}

const OpenChats: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [chats, setChats] = useState < Chat[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);
    const [skip, setSkip] = useState < number > (0);
    const [total, setTotal] = useState < number > (0);
    const [scrollPosition, setScrollPosition] = useState < number > (0);
    const scrollContent = useRef<HTMLIonContentElement | null> (null);
    const [initialRender, setInitialRender] = useState < boolean > (true);

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });
   
    const handleScrollEnd = async () => {
        const elem = await scrollContent.current?.getScrollElement();
        if(elem) {
            setScrollPosition(elem.scrollTop);
        }
    };

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    const loadItems = async () => {

        let limit = skip + 30;

        try {
            const result = await api.get(`/staffChats/listOpenInstitute/${authInfo.user.instituteID}?skip=${limit}`);
            if(result.data.chats && result.data.chats.length)
            {
                setChats([...chats, ...result.data.chats]);
                setSkip(limit);
            }
        } catch (error: any) {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        }
    }

    useEffect(() => {

        const storedState = sessionStorage.getItem(history.location.pathname);
        if (history.action === "POP" && storedState) {
            const { chats, total, skip, scrollPosition } = JSON.parse(storedState);
                // Data is present in local storage, use it to initialize state
                setChats(chats);
                setTotal(total);
                setSkip(skip);
               
                setTimeout(() => scrollContent.current?.scrollToPoint(0, scrollPosition, 500), 500);
            } 
            else 
            {

                const fetchUp = async () => {
                    setShowLoading(true);
                    try {
                    
                        const result = await api.get(`/staffChats/listOpenInstitute/${authInfo.user.instituteID}`);
                        setChats(result.data.chats);
                        setTotal(result.data.total);
                        setSkip(0);
                        
                        setShowLoading(false);

                    } catch (error: any) {
                        setMessage(error.response?.data.message || t("something_went_wrong"));
                        setIserror(true);
                    } finally {
                        setShowLoading(false);
                    }
                    
                };

                fetchUp();
            }
       
        setInitialRender(false);

    }, [authInfo, refreshed, history.action, history.location.pathname]);

    // Save state to local storage whenever it changes
    useEffect(() => {
        if (!initialRender) 
        {
            const currentState = {
                chats,
                total,
                skip,
                scrollPosition
            };
            sessionStorage.setItem(history.location.pathname, JSON.stringify(currentState));
        
        }
    }, [chats, total, skip, scrollPosition, history.location.pathname, initialRender]);

    // Effect to handle page reload 
    usePageUnloadHandler();

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/communication`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("open_chats")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                       <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade" ref={scrollContent} scrollEvents={true} onIonScrollEnd={handleScrollEnd}>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("open_chats")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}
                
                <IonGrid className="ion-no-padding mb-60">
                    <div className="ion-table">

                        <IonRow className="table-title ion-padding">
                            
                            <IonCol sizeLg="4" size="8" className='table-heading'>
                                {t("title")}
                            </IonCol>

                            <IonCol sizeLg="2" size="0" className='table-heading ion-hide-lg-down'>
                                {t("type")}
                            </IonCol>
                            <IonCol sizeLg="3" size="0" className='table-heading ion-hide-lg-down'>
                                {t("employee")}
                            </IonCol>
                            <IonCol sizeLg="2" size="3" className='table-heading'>
                                {t("updated_at")}
                            </IonCol>
                            <IonCol size="1">
                                <IonIcon 
                                    icon={chatboxEllipses}
                                    color="light"
                                    className='table-icon'
                                    />
                            </IonCol>
                        </IonRow>

                            {(chats.length > 0) ? (chats.map((chat, i) => {
                                return (
                                    <IonRow key={chat._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}
                                    onClick={
                                        () => history.push(`/app/chat/${chat._id}`)
                                    }>
                                           
                                            <IonCol sizeLg="4" size="8" className='table-field' >
                                                <IonRow>
                                                    <IonCol>
                                                        {chat.title}
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className='ion-hide-lg-up vid-responsive-field-1 text-capitalize'>
                                                    <IonCol>
                                                        <IonLabel>
                                                        {chat.employee ? chat.employee.name : `${t("employees")}: ${chat.employees!.length}`} &#8226; {chat.type}
                                                        </IonLabel>
                                                            
                                                    </IonCol>
                                                </IonRow>
                                                
                                            </IonCol>
                                         
                                            
                                            <IonCol sizeLg="2" size="0" className='table-field ion-hide-lg-down text-capitalize'>
                                                {chat.type}
                                            </IonCol>
                                            <IonCol sizeLg="3" size="0" className='table-field ion-hide-lg-down text-capitalize'>
                                                {chat.employee ? chat.employee.name : `${chat.employees!.length}`}
                                            </IonCol>
                                            <IonCol sizeLg="2" size="3" className='table-field'>
                                                {formatDate(chat.updatedAt)}
                                            </IonCol>
                                            <IonCol size="1" className='table-field'>
                                                <IonBadge className=''>{chat.messages.length+1}</IonBadge>
                                            </IonCol>
                                           
                                        
                                    </IonRow>
                                )
                            })) : ( 
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_pending_inq")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )
                        } 
                    </div>

                    {(chats.length > 0) &&
                        <IonRow>
                            <IonCol>
                                <IonInfiniteScroll
                                    onIonInfinite={async (ev) => {
                                        await loadItems();
                                        ev.target.complete();
                                    }}
                                    >
                                    <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                            </IonCol>
                        </IonRow>
                    }

                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                <IonCol className="ion-padding-start footer-stats">
                            {t("showing")} {total === 0 ? t("no_records") : `1 - ${chats.length} ${t("of")} ${total}`} 
                        </IonCol>
                        </IonRow>
                            <IonRow className="ion-padding-vertical">
                                <IonCol className=''>
                                    <IonButton 
                                        onClick={
                                            () => history.push(`/app/chats/new`)
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("make_inquiry")}</IonButton>
                                        
                                    <IonButton 
                                        onClick={
                                            () => history.push(`/app/chats/closed`)
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("closed_chats")}</IonButton>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default OpenChats;