import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCheckbox,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import { addCircle, alertCircleOutline, closeCircle, fileTrayFull } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';

interface ParamTypes {
    invoiceID: string
}

interface Invoice {
    student: Stud,
    invoiceDate: Date,
    dueDate: Date,
    amount: number,
    paid: number,
    dueComponents: Component[],
    paidAt?: Date
}
interface Stud {
    studentID: string,
    name: string
}
interface Component {
    amount: number,
    dueID?: string,
    receiptID?: string,
    head?: FeeHead,
    frequency?: string,
    duration?: string,
    remarks?: string,
    isChecked?: boolean
}
interface FeeHead {
    headID: string,
    name: string
}

interface Student {
    _id: string,
    name: string,
    photo?: string,
    classInfo: ClassInfo
}
interface ClassInfo {
    className: string
}

interface Session {
    _id: string,
    name: string,
    isActive: boolean
}

const InvoiceDetail: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const history = useHistory();
    const {invoiceID} = useParams < ParamTypes > ();
    const [readOnly, setReadOnly] = useState < boolean > (false);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [invoice, setInvoice] = useState < Invoice > ();
    const [components, setComponents] = useState < Component[] > ([]);
    const [student, setStudent] = useState < Student > ();
    const [selectedDate, setSelectedDate] = useState < string | string[] | undefined > ();
    const [invoiceDate, setInvoiceDate] = useState < string | string[] | undefined > ();
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [showAlert, setShowAlert] = useState < boolean > (false);

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const formatDate = (value: string | string[] | undefined) => {
        if (typeof value === "string") 
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
        else
        {
            return t("pick_date");
        }
    };

    const handleRemoval = () => {

        const withdraw = () => {
            return new Promise((resolve, reject) => {
                api.delete(`/invoices/${invoiceID}`).then(res => {
                    return resolve(res.data);
                  }).catch(err => reject(err));
            });
        }
    
        setShowLoading(true);
        withdraw()
        .then(data => {
            setPass(t("invoice_deleted"));
            setIspass(true);
        })
        .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));
    }

    const handleChecked = (value: boolean | undefined, id : number) => {
        if (invoice)
        {
            if (value)
            {
                let inv = {...invoice};
                let index = inv.dueComponents.findIndex(val => val.head?.headID === components[id].head?.headID);
                if (index > -1) {
                    inv.dueComponents.splice(index, 1);
                }
                setInvoice(inv);
    
                let compos = [...components];
                compos[id].isChecked = false;
                setComponents(compos);
            }
    
            if (!value)
            {
                let inv = {...invoice};
                let index = inv.dueComponents.findIndex(val => val.head?.headID === components[id].head?.headID);
                if (index < 0) {
                    inv.dueComponents.push({
                        head: components[id].head,
                        frequency: components[id].frequency,
                        amount: components[id].amount
                    });
                }
                
                setInvoice(inv);
    
                let compos = [...components];
                compos[id].isChecked = true;
                setComponents(compos);
            }
        }
    }

    const handleAmount = (amt: string, index: number) => {
        if (invoice)
        {
            let inv = {...invoice};
            inv.dueComponents[index].amount = parseInt(amt);
            setInvoice(inv);
        }
    }

    const handleDuration = (dur: string, index: number) => {
        if (invoice)
        {
            let inv = {...invoice};
            inv.dueComponents[index].duration = dur;
            setInvoice(inv);
        }
    }
        
    const handleRemarks = (freq: string, index: number) => {
        if (invoice)
        {
            let inv = {...invoice};
            inv.dueComponents[index].remarks = freq;
            setInvoice(inv);
        }
    }

    const handleDelete = (index: number) => {
        if (invoice)
        {
            let inv = {...invoice};
            if (index > -1) {
                inv.dueComponents.splice(index, 1);
            }
            setInvoice(inv);
        }
    }

    const handleAdd = () => {
        if (invoice)
        {
            let inv = {...invoice};
            inv.dueComponents.push({remarks: "", amount: 0});
            setInvoice(inv);
        }
    }

    const handleSubmit = () => {
        if (invoice)
        {
            if (typeof selectedDate !== "string")
            {
                setMessage(t("due_date_mandatory"));
                setIserror(true);
                return;
            }
    
            if (typeof invoiceDate !== "string")
            {
                setMessage(t("invoice_date_mandatory"));
                setIserror(true);
                return;
            }

            if (invoice.dueComponents.length < 1)
            {
                setMessage(t("dues_mandatory"));
                setIserror(true);
                return;
            }
    
            for (let j = 0; j < invoice.dueComponents.length; j++)
            {
                if (invoice.dueComponents[j].amount < 1)
                {
                    setMessage(t("dues_invalid"));
                    setIserror(true);
                    return;
                }
                if (invoice.dueComponents[j].head)
                {
                    if (!invoice.dueComponents[j].duration || invoice.dueComponents[j].duration === "") {
                        setMessage(t("dues_invalid"));
                        setIserror(true);
                        return;
                    } 
                }
                if (!invoice.dueComponents[j].head)
                {
                    if (!invoice.dueComponents[j].remarks || invoice.dueComponents[j].remarks === "") {
                        setMessage(t("dues_invalid"));
                        setIserror(true);
                        return;
                    }
                }
            }
    
            const upFee = (dueDate: string, dueComponents: Component[]) => {
                return new Promise((resolve, reject) => {
                    api.put('/invoices/'+invoiceID, { dueDate, invoiceDate, dueComponents }).then(res => {
                        return resolve(res.data.amount);
                    }).catch(err => reject(err));
                });
            }
            
            setShowLoading(true);
            upFee(selectedDate, invoice.dueComponents)
            .then(data => {
    
                setPass(t("invoice_updated")+data);
                setIspass(true);
            
            })
            .catch((error) => {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            })
            .finally(() => setShowLoading(false));
        }
    }
    
    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/invoices/' + invoiceID);
                setInvoice(result.data);
                setInvoiceDate(result.data.invoiceDate);
                setSelectedDate(result.data.dueDate);

                const onDate = new Date(Date.now());
                const invDate = new Date(result.data.invoiceDate);
                if (invDate <= onDate)
                {
                    setReadOnly(true);
                }
                if (result.data.paidAt)
                {
                    setReadOnly(true);
                }

                const res = await api.get(`/students/${result.data.student.studentID}`);
                setStudent(res.data);
                if (res.data.feeComponents)
                {
                    setComponents(res.data.feeComponents);
                }

                const resu = await api.get(`/sessions/getAllInstitute/${authInfo.user.instituteID}`);
                setSessions(resu.data);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, invoiceID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app/accounting"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("view_invoice")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("view_invoice")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                
                 <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false);
                            history.goBack();
                        }
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert
                    isOpen={showAlert}
                    cssClass="first-alert"
                    onDidDismiss={() => setShowAlert(false)}
                    header={t("confirm")}
                    message={t("confirm_invoice")}
                    buttons={[
                        {
                        text: t("no"),
                        role: 'cancel',
                        cssClass: 'secondary',
                        },
                        {
                        text: t("yes"),
                        handler: () => {
                            handleRemoval();
                        }
                        }
                    ]}
                />

                <IonGrid className="ion-no-padding">
                    <IonRow className="max-xxl">
                        <IonCol sizeLg="6" size="12" className='ion-padding-horizontal'>
                            
                            <IonRow className="mt-30">
                                <IonCol>
                                    <IonCard className="action-card-b">
                                        <IonCardContent>
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol size="3">
                                                        
                                                        {student && student.photo && (student.photo !== "") ?
                                                            (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${student.photo}`} alt={t("profile_pic")} />)
                                                            : (<img src={`assets/images/avatar1.png`} alt={t("profile_pic")} />)
                                                        }
                                                        
                                                    </IonCol>
                                                    <IonCol size="7">
                                                        <IonText className='action-text-3'>{student && student.name}<br/>
                                                            {student && student.classInfo.className}
                                                        </IonText>
                                                    </IonCol>
                                                    <IonCol size="2" className="ion-padding-top">
                                                        <IonIcon className='third-icon'
                                                            icon={fileTrayFull}
                                                            color='secondary' 
                                                            onClick={() => student && history.push(`/app/student/ledger/${student._id}`)}
                                                        />
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            {invoice && !((new Date(invoice.invoiceDate)) <= (new Date(Date.now()))) && 
                            (<>
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("fee_struct_comps")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="mt-30">
                                <IonCol>
                                <div className="ion-table">

                                    <IonRow className="table-title ion-padding">
                                        <IonCol size="5" className='table-heading heading-fixed mt-05'>
                                                {t("fee_head")}
                                        </IonCol>
                                        <IonCol offset="1" size="3" className='table-heading mt-05'>
                                                {t("fee_frequency")}
                                        </IonCol>
                                        <IonCol size="3" className='table-heading ion-text-right mt-05 ion-padding-end'>
                                                {t("fee_amount")}
                                        </IonCol>
                                    </IonRow>

                                    {(components.length > 0) ? (components.map((component, i) => 
                                        (component.head) && 
                                        (
                                            <IonRow key={component.head.headID} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}>
                                                    <IonCol size="5" className='table-field overflow-hide' >
                                                        
                                                            <IonCheckbox labelPlacement="end" value={component.head.headID} checked={component.isChecked} disabled={readOnly}
                                                                onClick={e => handleChecked(component.isChecked, i)}>
                                                                {component.head.name}
                                                            </IonCheckbox>
                                                       
                                                    </IonCol>

                                                    <IonCol offset="1" size="3" className='table-field text-capitalize' >
                                                        {component.frequency}
                                                    </IonCol>

                                                    <IonCol size="3" className='table-field ion-text-right ion-padding-end' >
                                                        {component.amount}
                                                    </IonCol>
                                              
                                            </IonRow>
                                        )
                                    )) : ( 
                                    <IonRow>
                                        <IonCol>
                                            <IonCard>
                                                <IonCardContent>
                                                    <IonItem lines="none">
                                                        <IonIcon icon={alertCircleOutline}
                                                            slot="start"
                                                            color="danger" />
                                                        <IonLabel className="list-title"><h3>{t("no_fee_comps")}</h3></IonLabel>

                                                    </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                    )
                                } 

                                </div>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol className="ion-text-center">
                                    <IonButton color="primary" onClick={() => history.push(`/app/fees/student/${invoice?.student.studentID}`)}>{t("edit_fee_struct")}</IonButton> 

                                </IonCol>
                            </IonRow>
                            </>)}
                           
                        </IonCol>
                        <IonCol sizeLg="6" size="12" className='ion-padding-horizontal mb-30'>
                            {invoice && (
                                <>
                                <IonRow className='mt-30'>
                                    <IonCol size="6">
                                        <IonTitle className='border-bottom-sec'>
                                        {t("invoice_details")}
                                        </IonTitle>
                                    </IonCol>
                                    <IonCol size="6" className='pr-08 ion-text-right'>
                                        <IonBadge color={(invoice.paidAt !== undefined) ? 'success' : ((new Date(invoice.dueDate)) < (new Date(Date.now()))) ? 'danger' : ((new Date(invoice.invoiceDate)) <= (new Date(Date.now()))) ? 'warning' : 'secondary'} >
                                        {(invoice.paidAt !== undefined) ? t("cleared") : ((new Date(invoice.dueDate)) < (new Date(Date.now()))) ? t("past_due") : ((new Date(invoice.invoiceDate)) <= (new Date(Date.now()))) ? t("uncleared") : t("scheduled")}
                                        </IonBadge>
                                    </IonCol>
                                </IonRow> 
                                <IonRow className='mt-30'>
                                    <IonCol size="6">
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        id={readOnly ? 'no-invoice' : `open-invoice`}>
                                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                            {t("invoice_date")}*
                                                        </IonLabel>
                                                        <IonInput value={formatDate(invoiceDate)} className="input-field" readonly={true} />
                                                        <IonModal trigger={`open-invoice`} showBackdrop={true} 
                                                            initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                            <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                                <IonToolbar>
                                                                    <IonTitle>{t("invoice_date")}</IonTitle>
                                                                    
                                                                </IonToolbar>
                                                                <IonDatetime
                                                                    value={invoiceDate}
                                                                    presentation="date"
                                                                    cancelText={t("cancel")}
                                                                    doneText={t("done")}
                                                                    onIonChange={ev => setInvoiceDate(ev.detail.value!)}
                                                                    showDefaultButtons={true}
                                                                    size="cover"
                                                                />
                                                            </IonContent>
                                                            
                                                        </IonModal>
                                                    </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                    <IonCol size="6">
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        id={readOnly ? 'no-due' : `open-due`}>
                                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                            {t("due_date")}*
                                                        </IonLabel>
                                                        <IonInput value={formatDate(selectedDate)} className="input-field" readonly={true} />
                                                        <IonModal trigger={`open-due`} showBackdrop={true} 
                                                            initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                            <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                                <IonToolbar>
                                                                    <IonTitle>{t("due_date")}</IonTitle>
                                                                    
                                                                </IonToolbar>
                                                                <IonDatetime
                                                                    value={selectedDate}
                                                                    presentation="date"
                                                                    cancelText={t("cancel")}
                                                                    doneText={t("done")}
                                                                    onIonChange={ev => setSelectedDate(ev.detail.value!)}
                                                                    showDefaultButtons={true}
                                                                    size="cover"
                                                                />
                                                            </IonContent>
                                                            
                                                        </IonModal>
                                                    </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-margin-top">
                                    <IonCol>
                                        <div className="ion-table">

                                        <IonRow className="table-title ion-padding">
                                            <IonCol size="4" className='table-heading mt-05'>
                                                    {t("due_head")}
                                            </IonCol>
                                            <IonCol size="4" className='table-heading mt-05'>
                                                    {t("due_duration")}
                                            </IonCol>
                                            <IonCol size="3" className='table-heading ion-text-right mt-05 ion-padding-end'>
                                                    {t("due_amount")}
                                            </IonCol>

                                            <IonCol size="1">
                                                <IonIcon 
                                                    icon={addCircle}
                                                    color="light"
                                                    className='table-icon ml-05'
                                                    size="large"
                                                    onClick={() => !readOnly && handleAdd()}
                                                    />
                                            </IonCol>
                                        </IonRow>
                                    
                                        {(invoice.dueComponents.length > 0) ? (invoice.dueComponents.map((due, i) => 
                                            (
                                                <IonRow key={i} className='row-table ion-padding-horizontal'>
                                                    {due.head && (
                                                        <>
                                                        <IonCol size="4" className='table-field ion-padding-top' >
                                                        {due.head.name}
                                                        </IonCol>
                                                        <IonCol size="4" className='table-field ion-padding-end' >
                                                            <IonItem>
                                                                {(due.frequency === "monthly") && (
                                                                <IonSelect placeholder={t("due_duration")}
                                                                    className='ion-no-padding' 
                                                                    style={{'maxWidth': '100%'}} 
                                                                    cancelText={t("cancel")}
                                                                    okText={t("ok")}
                                                                    value={due.duration}
                                                                    disabled={readOnly}
                                                                    onIonChange={
                                                                        (e) => handleDuration(e.detail.value!, i)
                                                                    }
                                                                    >
                                                                    <IonLabel>{t("due_duration")}</IonLabel>
                                                                    <IonSelectOption value="Jan">{t("jan")}</IonSelectOption>
                                                                    <IonSelectOption value="Feb">{t("feb")}</IonSelectOption>      
                                                                    <IonSelectOption value="Mar">{t("mar")}</IonSelectOption>   
                                                                    <IonSelectOption value="Apr">{t("apr")}</IonSelectOption>   
                                                                    <IonSelectOption value="May">{t("may")}</IonSelectOption>
                                                                    <IonSelectOption value="Jun">{t("jun")}</IonSelectOption>      
                                                                    <IonSelectOption value="Jul">{t("jul")}</IonSelectOption>   
                                                                    <IonSelectOption value="Aug">{t("aug")}</IonSelectOption>   
                                                                    <IonSelectOption value="Sep">{t("sep")}</IonSelectOption>
                                                                    <IonSelectOption value="Oct">{t("oct")}</IonSelectOption>      
                                                                    <IonSelectOption value="Nov">{t("nov")}</IonSelectOption>   
                                                                    <IonSelectOption value="Dec">{t("dec")}</IonSelectOption>   
                                                                </IonSelect>
                                                                )}
                                                                {(due.frequency === "quaterly") && (
                                                                <IonSelect placeholder={t("due_duration")}
                                                                    className='ion-no-padding' 
                                                                    style={{'maxWidth': '100%'}} 
                                                                    cancelText={t("cancel")}
                                                                    okText={t("ok")}
                                                                    value={due.duration}
                                                                    disabled={readOnly}
                                                                    onIonChange={
                                                                        (e) => handleDuration(e.detail.value!, i)
                                                                    }
                                                                    >
                                                                    <IonLabel>{t("due_duration")}</IonLabel>
                                                                    <IonSelectOption value="Jan-Mar">{t("q4")}</IonSelectOption>
                                                                    <IonSelectOption value="Apr-Jun">{t("q1")}</IonSelectOption>
                                                                    <IonSelectOption value="Jul-Sep">{t("q2")}</IonSelectOption>      
                                                                    <IonSelectOption value="Oct-Dec">{t("q3")}</IonSelectOption>
                                                                    <IonSelectOption value="Feb-Apr">{t("q5")}</IonSelectOption>
                                                                    <IonSelectOption value="Mar-May">{t("q6")}</IonSelectOption>      
                                                                    <IonSelectOption value="May-Jul">{t("q7")}</IonSelectOption>   
                                                                    <IonSelectOption value="Jun-Aug">{t("q8")}</IonSelectOption> 
                                                                    <IonSelectOption value="Aug-Oct">{t("q9")}</IonSelectOption>
                                                                    <IonSelectOption value="Sep-Nov">{t("q10")}</IonSelectOption>
                                                                    <IonSelectOption value="Nov-Jan">{t("q11")}</IonSelectOption>      
                                                                    <IonSelectOption value="Dec-Feb">{t("q12")}</IonSelectOption>  
                                                                    
                                                                </IonSelect>
                                                                )}
                                                                {(due.frequency === "annually") && (
                                                                <IonSelect placeholder={t("due_duration")}
                                                                    className='ion-no-padding' 
                                                                    style={{'maxWidth': '100%'}} 
                                                                    cancelText={t("cancel")}
                                                                    okText={t("ok")}
                                                                    value={due.duration}
                                                                    disabled={readOnly}
                                                                    onIonChange={
                                                                        (e) => handleDuration(e.detail.value!, i)
                                                                    }
                                                                    >
                                                                    <IonLabel>{t("due_duration")}</IonLabel>
                                                                    {sessions.map((session) => (
                                                                        (session.isActive) && 
                                                                        (<IonSelectOption key={session._id} value={session.name}>{session.name}</IonSelectOption>)
                                                                    ))}
                                                                </IonSelect>
                                                                )}
                                                                  {(due.frequency === "once") && (
                                                                    <IonInput type="text"
                                                                            placeholder={t("due_remarks")}
                                                                            value={due.duration}
                                                                            disabled={readOnly}
                                                                            onIonInput={
                                                                                (e) => handleDuration(e.detail.value!, i)
                                                                        }></IonInput>
                                                                    
                                                                    )}
                                                                
                                                            </IonItem>
                                                        
                                                        </IonCol>
                                                        </>
                                                    )}
                                                    {!due.head && (
                                                    <IonCol size="8" className='table-field ion-padding-end'>
                                                        <IonItem className='fee-comp-height'>
                                                            <IonInput type="text"
                                                                placeholder={t("due_remarks")}
                                                                value={due.remarks}
                                                                disabled={readOnly}
                                                                onIonInput={
                                                                    (e) => handleRemarks(e.detail.value!, i)
                                                            }></IonInput>
                                                        </IonItem>
                                                    </IonCol>
                                                    )}
                                                    <IonCol size="3" className='table-field'>
                                                        <IonItem className='fee-comp-height'>
                                                            <IonInput type="number"
                                                                className='ion-text-right'
                                                                placeholder='0'
                                                                value={due.amount}
                                                                disabled={readOnly}
                                                                onIonInput={
                                                                    (e) => e.detail.value && handleAmount(e.detail.value, i)
                                                            }></IonInput>
                                                        </IonItem>
                                                    </IonCol>
                                                        
                                                    <IonCol size="1" >
                                                            <IonIcon 
                                                            className='mt-15 ml-05'
                                                                icon={closeCircle}
                                                                color='dark'
                                                                onClick={() => !readOnly && handleDelete(i)}
                                                                size="large"
                                                            />

                                                    </IonCol>
                                                    
                                                </IonRow>
                                            )
                                        )) : ( 
                                        <IonRow>
                                            <IonCol>
                                                <IonCard>
                                                    <IonCardContent>
                                                        <IonItem lines="none">
                                                            <IonIcon icon={alertCircleOutline}
                                                                slot="start"
                                                                color="danger" />
                                                            <IonLabel className="list-title"><h3>{t("no_dues_added")}</h3></IonLabel>

                                                        </IonItem>
                                                    </IonCardContent>
                                                </IonCard>
                                            </IonCol>
                                        </IonRow>
                                        )
                                    } 
                                    {invoice.dueComponents && (invoice.dueComponents.length > 0) && (
                                    <IonRow className="table-title ion-padding">
                                        <IonCol size="8" className='table-heading heading-fixed mt-05'>
                                                {t("total")}
                                        </IonCol>
                                        <IonCol size="3" className='table-heading ion-text-right mt-05 ion-padding-end'>
                                                {invoice.dueComponents.reduce((acc, curr) => { 
                                                        return (acc + curr.amount);
                                                    }, 0)}
                                        </IonCol>
                                    </IonRow>
                                    )}
                                    {((new Date(invoice.invoiceDate)) <= (new Date(Date.now()))) && (
                                    <IonRow className="table-title-2 ion-padding">
                                        <IonCol size="8" className='table-heading-1 mt-05'>
                                                {t("amount_paid")}
                                        </IonCol>
                                        <IonCol size="3" className='table-heading-1 ion-text-right mt-05 ion-padding-end'>
                                                {invoice.paid}
                                        </IonCol>
                                    </IonRow>
                                    )}
                                    
                                    </div>
                                    
                                </IonCol>
                            </IonRow>
                        </>
                        )}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            {!readOnly && (
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("update")}</IonButton>

                            <IonButton onClick={() => setShowAlert(true)}
                                className="fifth-button"
                                fill="clear">{t("delete")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
            )}
        </IonPage>
    );
};

export default InvoiceDetail;
