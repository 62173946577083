import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import { alertCircleOutline, repeatSharp } from 'ionicons/icons';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';

interface DaySchedule {
    timeSlot: Slot,
    subject: Subject,
    teacher: Teacher
}

interface Slot {
    timeSlotID: string,
    startTime: string,
    endTime: string
}

interface Subject {
    subjectID: string,
    subjectName: string
}

interface Teacher {
    teacherID: string,
    teacherName: string
}

interface AllTeacher {
    _id: string,
    name: string
}

interface ClassInfo {
    classID: string,
    className: string
}

interface TimeTable {
    _id: string,
    name: string,
    classInfo: ClassInfo,
    mon?: DaySchedule[],
    tue?: DaySchedule[],
    wed?: DaySchedule[],
    thu?: DaySchedule[],
    fri?: DaySchedule[],
    sat?: DaySchedule[]
}

interface TimeSlot {
    _id: string,
    name: string,
    startTime: string,
    endTime: string
}

interface Substitute {
    _id: string,
    classInfo: ClassInfo,
    timeSlotInfo: Slot,
    subjectInfo: Subject,
    subTeacher: Teacher
}

interface Session {
    _id: string,
    name: string,
    isActive: boolean
}

type today = "mon" | "tue" | "wed" | "thu" | "fri" | "sat";

const DayTimeTable: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror1, setIserror1] = useState < boolean > (false);
    const [message1, setMessage1] = useState < string > ("");
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [teachers, setTeachers] = useState < AllTeacher[] > ([]);
    const [timeTables, setTimeTables] = useState < TimeTable[] > ([]);
    const [timeSlots, setTimeSlots] = useState < string[] > ([]);
    const [allSlots, setAllSlots] = useState < TimeSlot[] > ([]);
    const [substitutes, setSubstitutes] = useState < Substitute[] > ([]);
    const [day, setDay] = useState < today > ("mon");
    const [selectedTeacher, setSelectedTeacher] = useState < string > ("");
    const popover = useRef<HTMLIonPopoverElement>(null);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [timeSlotID, setSlotID] = useState < string > ("");
    const [startTime, setStartTime] = useState < string > ("");
    const [endTime, setEndTime] = useState < string > ("");
    const [classID, setClassID] = useState < string > ("");
    const [className, setClassName] = useState < string > ("");
    const [subjectID, setSubID] = useState < string > ("");
    const [subjectName, setSubName] = useState < string > ("");
    const [teacherID, setTeacherID] = useState < string > ("");
    const [teacherName, setTeacherName] = useState < string > ("");
    const [session, setSession] = useState < string > ("");

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
          }
    });

    const handleSub = () => {

        if (!selectedTeacher || selectedTeacher === "") {
            setMessage(t("teacher_mandatory"));
            setIserror(true);
            return;
        }

        if (selectedTeacher === teacherID)
        {
            setMessage(t("sub_self"));
            setIserror(true);
            return;
        }

        //if selectedteacher is already assigned to other class timetable in same slot id = error

        for (let i = 0; i < timeTables.length; i++)
        {
            if(timeTables[i].classInfo.classID !== classID)
            {
                if(timeTables[i][day]?.find(val => val.timeSlot.timeSlotID === timeSlotID))
                {
                    if(timeTables[i][day]!.find(val => val.timeSlot.timeSlotID === timeSlotID)!.teacher.teacherID === selectedTeacher)
                    {
                        setMessage(t("sub_invalid"));
                        setIserror(true);
                        return;
                    }
                }
            }
        }

        const selectedTeacherName: string = teachers.find(val => val._id === selectedTeacher)!.name;
    
        const upSub = () => {
            return new Promise((resolve, reject) => {
                api.post('/substitutes/add', { 'instituteID': authInfo.user.instituteID, timeSlotID, startTime, endTime, classID, className, subjectID, subjectName, teacherID, teacherName, selectedTeacher, selectedTeacherName}).then(res => {
                    return resolve(res.data.substitute);
                }).catch(err => reject(err));
            });
        }
    
        setShowLoading(true);
        upSub()
        .then((data: any) => {
            substitutes.push(data);
            setPopoverOpen(false);
            setPass(t("sub_complete"));
            setIspass(true);
        
        })
        .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));

    }

    const openPopover = (slotID: string, startTime: string, endTime: string, classID: string, className: string, daySch: DaySchedule) => {
        setSlotID(slotID);
        setStartTime(startTime);
        setEndTime(endTime);
        setClassID(classID);
        setClassName(className);
        setSubID(daySch.subject.subjectID);
        setSubName(daySch.subject.subjectName);
        setTeacherID(daySch.teacher.teacherID);
        setTeacherName(daySch.teacher.teacherName);
        setPopoverOpen(true);
    };

    const handleSession = () => {

        const d = new Date();
        
        if ((d.getDay() === 0))
        {
            setIserror1(true);
            setMessage1(t("today_sunday"));
        }
        else
        {
            const weekday = ["sun","mon","tue","wed","thu","fri","sat"];
            const theday = weekday[d.getDay()] as today;
            setDay(theday);

            if (!session || session === "") {
                setMessage(t("select_session_first"));
                setIserror(true);
                return;
            }

            const fetchUp = async () => {
                
                setShowLoading(true);
                try {
                        const times = await api.get(`/timeSlots/getByInstitute/${authInfo.user.instituteID}`);
                        setAllSlots(times.data);

                        const result = await api.get(`/timeTables/dayTimeTable/${authInfo.user.instituteID}/${session}/${theday}`);
                        setTimeTables(result.data.timeTables);

                        let array = result.data.timeSlots;
                        array.sort(function(a: any, b: any){
                            return parseInt(times.data.find((val: any) => val._id === a).startTime) - parseInt(times.data.find((val: any) => val._id === b).startTime);
                          });

                        setTimeSlots(array);
                        setSubstitutes(result.data.substitutes);
        
                        const teach = await api.get(`/users/${authInfo.user.instituteID}/teachers`);
                        setTeachers(teach.data);

                } catch (error: any) {
                    setMessage(error.response?.data.message || t("something_went_wrong"));
                    setIserror(true);
                } finally {
                    setShowLoading(false);
                }
                
            };

            fetchUp();
        }
    }

      useEffect(() => {

            const fetchData = async () => {
                setShowLoading(true);
                try {

                    const res = await api.get(`/sessions/getAllInstitute/${authInfo.user.instituteID}`);
                    setSessions(res.data);
                    setAllSlots([]);
                    setTimeTables([]);
                    setTimeSlots([]);
                    setSubstitutes([]);
                    setTeachers([]);
    
                } catch (error: any) {
                    setMessage(error.response?.data.message || t("something_went_wrong"));
                    setIserror(true);
                } finally {
                    setShowLoading(false);
                }
            };

            fetchData();
        

    }, [authInfo]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/academics`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("consolidated_timetable")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("consolidated_timetable")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                 <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert
                    isOpen={iserror1}
                    cssClass="first-alert"
                    onDidDismiss={ () => history.push(`/app`)}
                    header={t("error")}
                    message={message1}
                    buttons={[`${t("close")}`]}
                />
                
                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding mb-60 overflow-x">

                    <IonPopover ref={popover} size='auto' isOpen={popoverOpen} onDidDismiss={() => setPopoverOpen(false)}>
                        <IonGrid className="ion-no-padding ion-no-margin">
                            <IonRow>
                                <IonCol className='ion-padding'>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            
                                            <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            
                                            <IonLabel position="stacked" color="secondary">{t("teacher")}*</IonLabel>
                                                <IonSelect value={selectedTeacher}
                                                                placeholder={t("select_teacher")}
                                                                onIonChange={
                                                                    (e) => setSelectedTeacher(e.detail.value)
                                                            }>
                                                            
                                                            { teachers.map((teacher) => {
                                                                    
                                                                return (<IonSelectOption key={teacher._id} value={`${teacher._id}`}>{teacher.name}</IonSelectOption>);
                                                                    
                                                                })
                                                            }
                                                </IonSelect>
                                                        
                                            </IonItem>

                                           
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="ion-padding-horizontal ion-padding-bottom">
                                    <IonButton onClick={handleSub}
                                        expand="block"
                                        className='first-button' fill="clear">{t("substitute")}</IonButton>

                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonPopover>

                    <IonRow className="ion-margin-top">
                        <IonCol sizeXl="3" size="6">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" color="secondary" className='input-label'>
                                            {t("class_session")}</IonLabel>
                                            <IonSelect value={session}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("class_session")}
                                                    onIonChange={
                                                        (e) => setSession(e.detail.value)
                                                }>

                                                    {sessions.length > 0 && sessions.map((session) => 
                                                        (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                    )}

                                                    
                                            </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol sizeXl="3" size="6" className="ion-padding">
                            <IonButton onClick={() => handleSession() }
                                color="primary" className='mt-15'>{t("view_consolidate_table")}
                            </IonButton>
                        </IonCol>
                    </IonRow>

                    {(timeTables && timeTables.length > 0) ? (
                    <div className="ion-table mt-10 min-xxl">

                        <IonRow className="table-title ion-padding-vertical ion-text-center">

                            <IonCol size="1" className='table-heading heading-fixed'>
                                {t("class")}
                            </IonCol>
                            {timeSlots && timeSlots.length > 0 && timeSlots.map((slot, i) => (
                               
                                <IonCol key={slot} size={`${11/timeSlots.length}`} className='table-heading'>
                                    {(allSlots.length > 0) && (allSlots.find(val => val._id === slot)!.name +' ('+
                                    allSlots.find(val => val._id === slot)!.startTime)+' to '+allSlots.find(val => val._id === slot)!.endTime+')'}
                                </IonCol>
                         
                            
                            ))}
                        </IonRow>
                        {timeTables && timeTables.length > 0 && timeTables.map((table, i) =>  (
                            <IonRow key={table._id} className='row-table'>
                              <IonCol size="1" className='table-heading heading-fixed'>     
                                  <div className="vertical-container">
                                      <div className="vertical-center">     
                                          {table.classInfo.className}
                                      </div>
                                  </div>
                              </IonCol>
                                {timeSlots && timeSlots.length > 0 && timeSlots.map((slot, i) => (
                                        (table[day]?.find(val => val.timeSlot.timeSlotID === slot)) ?
                                                    (
                                                    <IonCol key={slot} size={`${11/timeSlots.length}`} className='table-field'>
                                                        <IonCard>
                                                            <IonCardContent className="ion-no-padding ion-no-margin">
                                                                <IonItem detail={false}
                                                                            lines='full'
                                                                            button={false}
                                                                            >
                                                                            
                                                                            <IonLabel position="stacked" color="secondary"> {t("subject")}</IonLabel>
                                                                            <IonInput type="text"
                                                                                    value={table[day]!.find(val => val.timeSlot.timeSlotID === slot)!.subject.subjectName}
                                                                                    readonly={true}
                                                                                    ></IonInput>
                                                                        </IonItem>

                                                                        {table[day]!.find(val => val.timeSlot.timeSlotID === slot)!.teacher.teacherName !== 'None' && (
                                                                        <IonItem detail={false}
                                                                            lines='full'
                                                                            button={true}
                                                                            onClick={() => openPopover(slot, allSlots.find(val => val._id === slot)!.startTime, allSlots.find(val => val._id === slot)!.endTime, table.classInfo.classID, table.classInfo.className, table[day]!.find(val => val.timeSlot.timeSlotID === slot)! )}
                                                                            >
                                                                            
                                                                            <IonLabel position="stacked" color="secondary"> {t("teacher")}</IonLabel>
                                                                            <IonInput type="text"
                                                                                    value={table[day]!.find(val => val.timeSlot.timeSlotID === slot)!.teacher.teacherName}
                                                                                    readonly={true}
                                                                                    ></IonInput>
                                                                            <IonIcon icon={repeatSharp} slot="end" size="large" className='mt-15'/>
                                                                        </IonItem>
                                                                        )}

                                                                        {substitutes && substitutes.some(val => val.timeSlotInfo.timeSlotID === slot && val.classInfo.classID === table.classInfo.classID && val.subjectInfo.subjectID === table[day]!.find(val => val.timeSlot.timeSlotID === slot)!.subject.subjectID) && (
                                                                            <IonItem detail={false}
                                                                            lines='full'
                                                                            button={false}
                                                                            >
                                                                            
                                                                            <IonLabel position="stacked" color="secondary">{t("substitute")}</IonLabel>
                                                                            <IonInput type="text"
                                                                                    value={substitutes.slice().reverse().find(val => val.timeSlotInfo.timeSlotID === slot && val.classInfo.classID === table.classInfo.classID && val.subjectInfo.subjectID === table[day]!.find(val => val.timeSlot.timeSlotID === slot)!.subject.subjectID)?.subTeacher.teacherName}
                                                                                    readonly={true}
                                                                                    ></IonInput>

                                                                            
                                                                        </IonItem>
                                                                        )}


                                                        </IonCardContent>
                                                    </IonCard>
                                                </IonCol>
                                                ) :
                                                (
                                                    <IonCol key={slot} size={`${11/timeSlots.length}`} className='table-field'>
                                                    </IonCol>
                                                )
                                            ))}
                                    </IonRow>   
                            ))}        
                    </div>
                    ) : (
                        <IonRow>
                            <IonCol>
                                <IonCard className="red-card">
                                    <IonCardContent>
                                        <IonItem lines="none">
                                            <IonIcon icon={alertCircleOutline}
                                                slot="start"
                                                color="danger" />
                                            <IonLabel className="list-title"><h3>{t("no_tables")}</h3></IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    )}

                </IonGrid>
            </IonContent>
            
        </IonPage>
    );
};

export default DayTimeTable;
