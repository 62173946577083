import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { alertCircleOutline } from 'ionicons/icons';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';

interface Substitute {
    _id: string,
    classInfo: ClassInfo,
    timeSlotInfo: Slot,
    subjectInfo: Subject,
    subTeacher: Teacher,
    originalTeacher: Teacher
}

interface Slot {
    timeSlotID: string,
    startTime: string,
    endTime: string
}

interface Subject {
    subjectID: string,
    subjectName: string
}

interface Teacher {
    teacherID: string,
    teacherName: string
}

interface ClassInfo {
    classID: string,
    className: string
}

const Substitutes: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const [substitutes, setSubstitutes] = useState < Substitute[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    useEffect(() => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/substitutes/getDayByInstitute/${authInfo.user.instituteID}`);
                setSubstitutes(result.data);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        fetchUp();

    }, [authInfo]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/academics`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("substitutes")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("substitutes")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}
                
                <IonGrid className="ion-no-padding mb-60">
                   
                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {t("today_subs")}
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                  
 
                    <IonRow className="ion-margin-vertical">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            {(substitutes.length > 0) ? substitutes.map((sub) => (
                            <IonCard className='border-radius-10 mt-15' key={sub._id}>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonGrid className="ion-no-padding ion-no-margin">
                                        <IonRow className="tran-pad title-row">
                                            <IonCol size="6" className=''>
                                                <IonText>{sub.classInfo.className}</IonText>
                                            </IonCol>
                                            <IonCol size="6" className='ion-text-right'>
                                                <IonText>{sub.timeSlotInfo.startTime} to {sub.timeSlotInfo.endTime}</IonText>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className='border-top'>
                                            <IonCol>
                                                <IonItem detail={false}
                                                    lines='full'
                                                    button={false}
                                                    >
                                                    
                                                    <IonLabel position="stacked" color="secondary">{t("original_teacher")}</IonLabel>
                                                    <IonInput type="text"
                                                            value={sub.originalTeacher.teacherName}
                                                            readonly={true}
                                                            ></IonInput>
                                                </IonItem>
                                                <IonItem detail={false}
                                                    lines='full'
                                                    button={false}
                                                    >
                                                    
                                                    <IonLabel position="stacked" color="secondary">{t("substitute_teacher")}</IonLabel>
                                                    <IonInput type="text"
                                                            value={sub.subTeacher.teacherName}
                                                            readonly={true}
                                                            ></IonInput>
                                                </IonItem>
                                            </IonCol>
                                        </IonRow>
                                        
                                    
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                            )) : (
                            <IonCard>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonItem lines="none">
                                        <IonIcon icon={alertCircleOutline}
                                        slot="start" 
                                        color="danger" />
                                        <IonLabel className="list-label"><h3>{t("no_substitutes")}</h3></IonLabel>

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                            )}

                        </IonCol>
                    </IonRow>
                  
                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton routerLink={`/app/substitutes/history`}
                                className="first-button"
                                fill="clear">{t("substitutes_history")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default Substitutes;
