import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';

interface ParamTypes {
    vendorID?: string
}

interface Vendor {
    _id: string,
    name: string,
    due: number,
    isActive: boolean
}

const VendorPayment: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {vendorID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [amount, setAmount] = useState < number > ();
    const [remarks, setRemarks] = useState < string > ("");
    const [mode, setMode] = useState < string > ("");
    const [selectedDate, setSelectedDate] = useState < string | string[] > (new Date().toISOString());
    const [tags, setTags] = useState < string[] > ([]);
    const [vendors, setVendors] = useState < Vendor[] > ([]);
    const [selectedVendor, setSelectedVendor] = useState < string > ("");
    const [due, setDue] = useState < number > ();

    const api = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      timeout: parseInt(process.env.REACT_APP_API_TO!),
      headers: {
          'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const formatDate = (value: string | string[]) => {
        if (typeof(value) === "string")
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
    };

    const handleTags = (value : string) => {
        let inputTags = value.toLowerCase();
        let strArray: Array < string > = [];
        strArray = inputTags.split(" ");
        setTags(strArray);
    };

    const handleVendor = (value : string) => {
        setSelectedVendor(value);
        const vendorDue = vendors.find((val) => val._id === value)!.due;
        setDue(vendorDue);
    };

    const handleSubmit = () => {

        if (!mode || mode === "") {
            setMessage(t("tran_mode_mandatory"));
            setIserror(true);
            return;
        }

        if (!remarks || remarks === "") {
            setMessage(t("tran_remarks_mandatory"));
            setIserror(true);
            return;
        }

        if (!amount || amount === 0) {
            setMessage(t("tran_amount_mandatory"));
            setIserror(true);
            return;
        }

        if (!selectedVendor || selectedVendor === "") {
            setMessage(t("vendor_mandatory"));
            setIserror(true);
            return;
        }

        const vendorName = vendors.find((val) => val._id === selectedVendor)!.name;
        const vendor = {
            vendorID: selectedVendor,
            vendorName
        };
  
        const addExpenseTran = (dated: string | string[]) => {
            return new Promise((resolve, reject) => {
              api.post('/transactions/addExpense', { 'instituteID': authInfo.user.instituteID, amount, remarks, mode, dated, 'adminID': authInfo.user._id, tags, vendor}).then(res => {
                return resolve(res.data.transaction);
              }).catch(err => reject(err));
            });
        }
  
        setShowLoading(true);
        addExpenseTran(selectedDate)
        .then(data => {
        
            setPass(t("expense_added"));
            setIspass(true);
        })
        .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));
        
    }

    useEffect(() => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {

                const result = await api.get(`/vendors/getAllInstitute/${authInfo.user.instituteID}`);
                setVendors(result.data);
                if (vendorID) {
                    setSelectedVendor(vendorID);
                    const vendorObj = result.data.find((val: Vendor) => val._id === vendorID);
                    if (vendorObj) {
                        setDue(vendorObj.due);
                    }
                } else {
                    setSelectedVendor("");
                }

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo, vendorID]);
    
    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/inventory`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("make_payment")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("make_payment")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                
                 <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => history.push(`/app/inventory`)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding">

                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                    {t("payment_details")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("tran_vendor")}*
                                        </IonLabel>
                                        <IonSelect value={selectedVendor}
                                                    className="input-field" 
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("tran_vendor")}
                                                    onIonChange={
                                                        (e) => handleVendor(e.detail.value)
                                                }>
                                                    { vendors.map((vendor) => (
                                                        (<IonSelectOption key={vendor._id} value={vendor._id}>{vendor.name}</IonSelectOption>)
                                                    ))}
                                                    
                                                </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    {due && (
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                    {t("current_outstanding")} {t("rs")} {due}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    )}
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("tran_amount")}*
                                            </IonLabel>
                                        <IonInput type="number"
                                            value={amount}
                                            placeholder={t("tran_amount")}
                                            className="input-field" 
                                            onIonInput={
                                                (e) => setAmount(parseInt(e.detail.value!))
                                        }></IonInput>
                                    </IonItem>
                                    
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-date-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("tran_date")}*
                                            </IonLabel>
                                            <IonInput value={formatDate(selectedDate)} className="input-field" readonly={true} />
                                            <IonModal trigger="open-date-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("tran_date")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                        value={selectedDate}
                                                        presentation="date"
                                                        cancelText={t("cancel")}
                                                        doneText={t("done")}
                                                        onIonChange={ev => setSelectedDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("tran_remarks")}*
                                            </IonLabel>
                                            <IonInput value={remarks} 
                                                placeholder={t("tran_remarks")}
                                                type="text"
                                                className="input-field" 
                                                onIonInput={ev => setRemarks(ev.detail.value!)}
                                            />
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("tran_mode")}*
                                            </IonLabel>
                                            <IonSelect placeholder={t("tran_mode")}
                                                className='input-field' 
                                                style={{'maxWidth': '100%'}} 
                                                cancelText={t("cancel")}
                                                okText={t("ok")}
                                                value={mode}
                                                onIonChange={
                                                    (e) => setMode(e.detail.value!)
                                                }
                                                >
                                                <IonSelectOption value="cash">{t("cash")}</IonSelectOption>
                                                <IonSelectOption value="cheque">{t("cheque")}</IonSelectOption>      
                                                <IonSelectOption value="online">{t("online")}</IonSelectOption>   
                                                
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                   
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("tran_tags")}
                                            </IonLabel>
                                        <IonInput type="text"
                                            placeholder={t("tran_tags")}
                                            className="input-field" 
                                            value={tags.join(' ')}
                                            onIonInput={
                                                (e) => handleTags(e.detail.value!)
                                        }></IonInput>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12" className="ion-padding-horizontal">
                            <IonText className='info-text'>
                                {t("tags_info")}
                            </IonText>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>

            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("make_payment")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>

        </IonPage>
    );
};

export default VendorPayment;
