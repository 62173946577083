import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {useAuth} from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';

interface ParamTypes {
    adminID: string
}

interface Institute {
    _id: string,
    name: string,
    isActive: boolean
}

const UpdateAdmin: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const {adminID} = useParams < ParamTypes > ();
    const [active, setActive] = useState < string > ("");
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [phone, setPhone] = useState < string > ("");
    const [email, setEmail] = useState < string > ("");
    const [institutes, setInstitutes] = useState < Institute[] > ([]);
    const [instituteID, setInstituteID] = useState < string > ("");
    
    const api = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      timeout: parseInt(process.env.REACT_APP_API_TO!),
      headers: {
          'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const validateEmail = (email : string) => { 
        // eslint-disable-next-line no-control-regex
        const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
        return re.test(String(email).toLowerCase());
    };

    const validatePhone = (phone : string) => { 
        // eslint-disable-next-line no-control-regex
        const re = /^[0-9]{10}$/;
        return re.test(phone);
    };

    const handleReset = () => {
    
        setShowLoading(true);
  
        const upUser = () => {
            return new Promise((resolve, reject) => {
              api.get('/users/reset/'+adminID).then(res => {
        
                return resolve(res.data.name);
                
              }).catch(err => reject(err));
    
            });
          }
      
      
        upUser()
        .then(data => {
    
            setPass(data+t("password_reset"));
            setIspass(true);
            
        })
        .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));
            
      }

    const handleSection = (section: string) => {

        const toggleAdmin = (isActive: string) => {
            return new Promise((resolve, reject) => {
                api.put(`/users/${adminID}`, { 'isActive': section }).then(res => {
                    return resolve(res.data.name);
                }).catch(err => reject(err));
            });
        }

        if ((active !== "") && (section !== active))
        {
            setShowLoading(true);
            toggleAdmin(section)
            .then(data => {

                setActive(section);

                setPass(data+t("has_been_updated"));
                setIspass(true);
            })
            .catch((error) => {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            })
            .finally(() => setShowLoading(false));
        }
    }

    const handleSubmit = () => {

        if (!name || name === "") {
            setMessage(t("name_mandatory"));
            setIserror(true);
            return;
        }

        if (validateEmail(email) === false) {
            setMessage(t("invalid_email"));
            setIserror(true);
            return;
        }

        if (validatePhone(phone) === false) {
            setMessage(t("invalid_mobile"));
            setIserror(true);
            return;
        }
        
    
        setShowLoading(true);
  
        const upAdmin = (name: string, phone: string, email: string) => {
          return new Promise((resolve, reject) => {
            api.put('/users/'+adminID, { instituteID, 'name': name, 'phone': phone, 'email': email }).then(res => {
              return resolve(res.data.name);
            }).catch(err => reject(err));
          });
        }
    
        upAdmin(name, phone, email)
        .then(data => {
  
            setPass(data+t("has_been_updated"));
            setIspass(true);
          
        })
        .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));
        
      }
    
    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/users/' + adminID);

                setName(result.data.name);
                setPhone(result.data.phone);
                setEmail(result.data.email);

                if (result.data.isActive)
                    setActive("enable");
                else
                    setActive("disable");

                const res = await api.get(`/institutes/getBySuper/${authInfo.user._id}`);
                setInstitutes(res.data);

                setInstituteID(result.data.instituteID);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, adminID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/admins`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("manage_admin")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("manage_admin")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className="ion-no-padding">
                    <IonRow className="mt-10">
                        <IonCol>
                            <IonCard className='border-radius-10 active-group'>
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol size="8">
                                                
                                                {t("current_status")}
                                            
                                            </IonCol>
                                            <IonCol size="4" className='ion-text-right ion-padding-horizontal'>
                                            {(active === 'enable') ? ( <IonBadge color="success" className='ion-padding-horizontal'>{t("active")}</IonBadge>) : 
                                            ( <IonBadge color="danger" className='ion-padding-horizontal'>{t("inactive")}</IonBadge>)}
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className="ion-padding-top ion-padding-horizontal">
                                            <IonCol>
                                                <IonSegment value={active} onIonChange={e => handleSection( e.detail.value!.toString() )} mode="ios" className="isactive-segment">
                                                    <IonSegmentButton value="enable">
                                                        <IonLabel>{t("make_active")}</IonLabel>
                                                    </IonSegmentButton>
                                                    <IonSegmentButton value="disable">
                                                        <IonLabel>{t("make_inactive")}</IonLabel>
                                                    </IonSegmentButton>
                                                </IonSegment>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleReset}
                                 color="dark">{t("reset_password")}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-30">
                        <IonCol>
                            <IonTitle className='list-title'>
                                {t("admin_details")}
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol>
                            <IonList className="ion-no-padding">

                                {(instituteID !== "") && (
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}>
                                            <IonLabel position="fixed" color="secondary">{t("institute")}</IonLabel>
                                                <IonSelect value={instituteID}
                                                        placeholder={t("institute")}
                                                        onIonChange={
                                                            (e) => setInstituteID(e.detail.value)
                                                    }>
                                                    {institutes.map((institute) => 
                                                        (institute.isActive) &&
                                                        (<IonSelectOption key={institute._id} value={institute._id}>{institute.name}</IonSelectOption>)
                                                    )}
                                        </IonSelect>
                                    </IonItem>
                                    )}
                                <IonItem detail={false}
                                    lines='full'
                                    button={false}>
                                    <IonLabel position="fixed" color="secondary">{t("school_email")}</IonLabel>
                                    <IonInput type="text"
                                        value={email}
                                        placeholder={t("school_email")}
                                        onIonInput={
                                            (e) => setEmail(e.detail.value !)
                                    }></IonInput>
                                </IonItem>


                                <IonItem detail={false}
                                    lines='full'
                                    button={false}>
                                    <IonLabel position="fixed" color="secondary">{t("student_name")}</IonLabel>
                                    <IonInput type="text"
                                        value={name}
                                        placeholder={t("student_name")}
                                        onIonInput={
                                            (e) => setName(e.detail.value !)
                                    }></IonInput>
                                </IonItem>

                                <IonItem detail={false}
                                    lines='full'
                                    button={false}>
                                    <IonLabel position="fixed" color="secondary">{t("school_phone")}</IonLabel>
                                    <IonInput type="text"
                                        value={phone}
                                        placeholder={t("school_phone")}
                                        onIonInput={
                                            (e) => setPhone(e.detail.value !)
                                    }></IonInput>
                                </IonItem>

                            </IonList>
                                
                        </IonCol>
                    </IonRow>

                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-padding-horizontal ion-text-center">
                            <IonButton onClick={handleSubmit}
                                fill="clear"
                                className='first-button'>{t("update_admin")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default UpdateAdmin;
