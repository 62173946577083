import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, chevronForward, searchCircle } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';

interface Employee {
    _id: string,
    userInfo: UserInfoEmp,
    isActive: boolean
}
interface UserInfoEmp {
    userID: string,
    name: string
}
interface Inquiry {
    _id: string,
    status: string,
    type: string,
    userInfo: User,
    updatedAt: Date
}
interface User {
    userID: string,
    name: string,
    phone: string
}
interface ParamTypes {
    userID?: string
}

const SalesRepReports: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const history = useHistory();
    const {userID} = useParams < ParamTypes > ();
    const [employees, setEmployees] = useState < Employee[] > ([]);
    const [selectedEmployee, setSelectedEmployee] = useState < string > ("");
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);
    const [categories, setCategories] = useState < string[] > (['First', 'Second', 'Third']);
    const [callSeries, setCallSeries] = useState < number[] > ([0, 0, 0]);
    const [meetingSeries, setMeetingSeries] = useState < number[] > ([0, 0, 0]);
    const [call, setCall] = useState < number > (0);
    const [meeting, setMeeting] = useState < number > (0);
    const [inquiries, setInquiries] = useState < Inquiry[] > ([]);

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const series =  [{
        name: 'Calls Made',
        data: callSeries
        }, {
        name: 'Meetings Scheduled',
        data: meetingSeries
    }];

    const options =  {
        chart: {
            toolbar: {
              show: false
            }
        },
        colors : ['#ffc409', '#2dd36f'],
        plotOptions: {
            bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: categories,
        },
        yaxis: {
            title: {
            text: 'No. of Enquiries'
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val: any) {
                    return val
                }
            },
            theme: 'dark'
        }
    };

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB');
    };
         
    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const handleEmployee = (empID : string) => {

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.get('/admissionInquiries/getReportsEmployee/' + empID);
                setCategories(result.data.categories);
                setCallSeries(result.data.callSeries);
                setMeetingSeries(result.data.meetingSeries);
                setCall(result.data.call);
                setMeeting(result.data.meeting);

                const inq = await api.get(`/admissionInquiries/getSomePendingEmployee/${empID}`);
                setInquiries(inq.data);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        const fetchAll = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.get('/admissionInquiries/getReportsInstitute/' + authInfo.user.instituteID);
                setCategories(result.data.categories);
                setCallSeries(result.data.callSeries);
                setMeetingSeries(result.data.meetingSeries);
                setCall(result.data.call);
                setMeeting(result.data.meeting);

                const inq = await api.get(`/admissionInquiries/getSomePendingInstitute/${authInfo.user.instituteID}`);
                setInquiries(inq.data);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        if (empID === "all") {
            fetchAll();
        } else {
            fetchUp();
        }
        
        setSelectedEmployee(empID);

    }

    useEffect(() => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {

                const studs = await api.get(`/employees/getSalesReps/${authInfo.user.instituteID}`);
                setEmployees(studs.data);

                if (userID) {
                    setSelectedEmployee(userID);
                    const result = await api.get('/admissionInquiries/getReportsEmployee/' + userID);

                    setCategories(result.data.categories);
                    setCallSeries(result.data.callSeries);
                    setMeetingSeries(result.data.meetingSeries);
                    setCall(result.data.call);
                    setMeeting(result.data.meeting);

                    const inq = await api.get(`/admissionInquiries/getSomePendingEmployee/${userID}`);
                    setInquiries(inq.data);
                } else {
                    setSelectedEmployee("all");
                    const result = await api.get('/admissionInquiries/getReportsInstitute/' + authInfo.user.instituteID);

                    setCategories(result.data.categories);
                    setCallSeries(result.data.callSeries);
                    setMeetingSeries(result.data.meetingSeries);
                    setCall(result.data.call);
                    setMeeting(result.data.meeting);

                    const inq = await api.get(`/admissionInquiries/getSomePendingInstitute/${authInfo.user.instituteID}`);
                    setInquiries(inq.data);
                }
                

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        fetchUp();

    }, [authInfo, refreshed, userID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/crm`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("sales_rep_performance")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("sales_rep_performance")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>
                
                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}
                
                <IonGrid className="ion-no-padding mb-60 max-xl">
                    <IonRow>
                        <IonCol offsetLg="3" sizeLg="6" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem
                                        detail={false}
                                        lines='full'
                                        button={false}
                                    >
                                        <IonLabel position="stacked" className="input-label" color="secondary">{t("select_salesrep")}</IonLabel>
                                        <IonSelect value={selectedEmployee}
                                            cancelText={t("cancel")}
                                            okText={t("ok")}
                                            className="text-capitalize"
                                            placeholder={t("select_emp")}
                                            onIonChange={
                                                (e) => e.detail.value && handleEmployee(e.detail.value)
                                        }>
                                            <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                            {employees.map((employee) => (
                                                (employee.isActive) &&
                                                (<IonSelectOption key={employee._id} value={employee.userInfo.userID} className='text-capitalize'>{employee.userInfo.name}</IonSelectOption>)
                                            ))}
                                        </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol size="12" sizeLg="6" className='ion-padding'>
                            <IonRow className="mt-30">
                                <IonCol>
                                    <IonCard className='action-card-w'>
                                        <IonCardHeader className="ion-text-center">
                                            <IonCardSubtitle>{t("current_calls")}</IonCardSubtitle>
                                        </IonCardHeader>
                                        <IonCardContent className="reports-warn">
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol>
                                                        <div className="vertical-container">
                                                            <div className="vertical-center"> 
                                                                <h1>{call}</h1>
                                                            </div>
                                                        </div>
                                                    </IonCol>
                                                    
                                                </IonRow>
                                            
                                            </IonGrid>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow className='ion-margin-top'>
                                <IonCol>
                                    <IonCard className='action-card-s'>
                                        <IonCardHeader className="ion-text-center">
                                            <IonCardSubtitle>{t("current_meetings")}</IonCardSubtitle>
                                        </IonCardHeader>
                                        <IonCardContent className="reports-in">
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol>
                                                        <div className="vertical-container">
                                                            <div className="vertical-center"> 
                                                                <h1>{meeting}</h1>
                                                            </div>
                                                        </div>
                                                    </IonCol>
                                                   
                                                </IonRow>
                                            
                                            </IonGrid>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow className="mt-30">
                                <IonCol>
                                    <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                        {t("last_3_months")}
                                    </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol className='ion-padding'>
                                    <Chart options={options} series={series} type="bar" height={350} />
                                </IonCol>
                            </IonRow>
                           
                        </IonCol>
                       
                        <IonCol size="12" sizeLg="6" className='ion-padding'>
                            <IonRow className="mt-30">
                                <IonCol>
                                    <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                        {t("recently_updated")}
                                    </IonTitle>
                                </IonCol>
                           </IonRow>
                           <IonRow className="mt-30">
                                <IonCol>
                                     <IonList className="ion-no-padding">
                                     {(inquiries.length > 0) ? (inquiries.map((inquiry, i) => {
                                            return (
                                                <IonItem detail={true}
                                                    detailIcon={chevronForward}
                                                    lines='full'
                                                    button={true}
                                                    key={inquiry._id}
                                                    onClick={
                                                        () => history.push(`/app/admission/inquiry/${inquiry._id}`)
                                                    }
                                                >
                                                    <IonIcon slot="start"
                                                        icon={searchCircle}
                                                        color='primary'
                                                    />
                                                    <IonLabel className="list-label text-capitalize">
                                                        <IonBadge color={(inquiry.status === "new") ? "medium" : ((inquiry.status === "shared") || (inquiry.status === "unreachable"))
                                                            ? "secondary" : (inquiry.status === "converted") ? "success" : ((inquiry.status === "failed") || (inquiry.status === "disqualified")) ? "danger" : "warning"}>
                                                            {t(inquiry.status)}
                                                        </IonBadge>
                                                        <h3>{inquiry.userInfo.name} ({inquiry.type})<br/>{formatDate(inquiry.updatedAt)}</h3>
                                                    </IonLabel>
                                                </IonItem>
                                            )
                                        })) : ( 
                                            <IonItem lines="none" className="red-card">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" 
                                                />
                                                <IonLabel className="list-title"><h3>{t("no_open_enq")}</h3></IonLabel>
                                            </IonItem>
                                        )} 
                                    </IonList>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                   
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default SalesRepReports;
