import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, attach, construct } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';
import usePageUnloadHandler from '../../../components/PageUnloadHandler';

interface Circular {
    _id: string,
    title: string,
    type?: string,
    filename?: string,
    createdAt: Date,
    isActive: boolean
}

const CircularsStaff: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [circulars, setCirculars] = useState < Circular[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [skip, setSkip] = useState < number > (0);
    const [total, setTotal] = useState < number > (0);
    const [rolei, setRolei] = useState < string > ("all");
    const [searchText, setSearchText] = useState < string > ("");
    const [type, setType] = useState < string > ("all");
    const [toDate, setToDate] = useState < string | string[] | undefined > ();
    const [fromDate, setFromDate] = useState < string | string[] | undefined> ();
    const [scrollPosition, setScrollPosition] = useState < number > (0);
    const scrollContent = useRef<HTMLIonContentElement | null> (null);
    const [initialRender, setInitialRender] = useState < boolean > (true);

    // Create an instance of axios with default settings
    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });
   
    const handleScrollEnd = async () => {
        const elem = await scrollContent.current?.getScrollElement();
        if (elem) setScrollPosition(elem.scrollTop);
    };

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    const formatDated = (value: string | string[] | undefined) => {
        if (typeof value === "string") 
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
        }
        else
        {
            return t("pick_date");
        }
    };

    const loadItems = async () => {

        if (((typeof toDate === "string") && (typeof fromDate !== "string")) || ((typeof toDate !== "string") && (typeof fromDate === "string")))
        {
            setMessage(t("dates_mandatory"));
            setIserror(true);
            return;
        }

        
        let toDOB = "";
        let fromDOB = "";

        if ((typeof toDate === "string") && (typeof fromDate === "string"))
        {
            const tDate = new Date(toDate);
            const fDate = new Date(fromDate);
            if (tDate < fDate)
            {
                setMessage(t("dates_invalid"));
                setIserror(true);
                return;
            }     
            else
            {
                toDOB = toDate;
                fromDOB = fromDate;
            }
        }

        let limit = skip+30;

        try {
          
            const result = await api.post(`/circulars/listByStaff/${authInfo.user.instituteID}?skip=${limit}`, {rolei, type, toDOB, fromDOB, searchText});
            if(result.data.circulars && result.data.circulars.length)
            {
                setCirculars([...circulars, ...result.data.circulars]);
                setSkip(limit);
            }

        } catch (error: any) {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        }
        
    }

    const handleSubmit = () => {

        if (((typeof toDate === "string") && (typeof fromDate !== "string")) || ((typeof toDate !== "string") && (typeof fromDate === "string")))
        {
            setMessage(t("dates_mandatory"));
            setIserror(true);
            return;
        }
        
        let toDOB = "";
        let fromDOB = "";

        if ((typeof toDate === "string") && (typeof fromDate === "string"))
        {
            const tDate = new Date(toDate);
            const fDate = new Date(fromDate);
            if (tDate < fDate)
            {
                setMessage(t("dates_invalid"));
                setIserror(true);
                return;
            }     
            else
            {
                toDOB = toDate;
                fromDOB = fromDate;
            }
        }

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.post(`/circulars/listByStaff/${authInfo.user.instituteID}`, {rolei, type, toDOB, fromDOB, searchText});
                setCirculars(result.data.circulars);
                setTotal(result.data.total);
                setSkip(0);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }

    useEffect(() => {

        const storedState = sessionStorage.getItem(history.location.pathname);
        if (history.action === "POP" && storedState) {
            // Data is present in local storage, use it to initialize state
            const { circulars, rolei, total, skip, type, toDate, fromDate, searchText, scrollPosition } = JSON.parse(storedState);
            // Data is present in local storage, use it to initialize state
            setCirculars(circulars);
            setRolei(rolei);
            setTotal(total);
            setSkip(skip);
            setType(type);
            setToDate(toDate);
            setFromDate(fromDate);
            setSearchText(searchText);
            
            setTimeout(() => scrollContent.current?.scrollToPoint(0, scrollPosition, 500), 500);
              
        }
        else
        {
            const fetchUp = async () => {
                setShowLoading(true);
                try {
                    const result = await api.post(`/circulars/listByStaff/${authInfo.user.instituteID}`, {rolei: "all", type: "all", toDOB: "", fromDOB: "", searchText: ""});
                    setCirculars(result.data.circulars);
                    setTotal(result.data.total);
                    setRolei("all");
                    setType("all");
                    setSkip(0); 
                } catch (error: any) {
                    setMessage(error.response?.data.message || t("something_went_wrong"));
                    setIserror(true);
                } finally {
                    setShowLoading(false);
                }
            };

            fetchUp();
        }

        setInitialRender(false);

}, [authInfo.user.instituteID, history.action, history.location.pathname]);

     // Save state to local storage whenever it changes
     useEffect(() => {
        if (!initialRender) 
        {
            const currentState = {
                circulars,
                rolei,
                total,
                skip,
                type,
                toDate,
                fromDate,
                searchText,
                scrollPosition
            };
            sessionStorage.setItem(history.location.pathname, JSON.stringify(currentState));
        
        }
    }, [circulars, rolei, total, skip, type, toDate, fromDate, searchText, scrollPosition, history.location, initialRender]);

    // Effect to handle page reload 
    usePageUnloadHandler();

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/communication`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("view_staff_comm")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                       <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade" ref={scrollContent} scrollEvents={true} onIonScrollEnd={handleScrollEnd}>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("view_staff_comm")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding overflow-x">
                    <IonRow className="min-xl">
                        
                        <IonCol size="2">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("comm_roles")}</IonLabel>
                                            <IonSelect value={rolei}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("comm_roles")}
                                                    onIonChange={
                                                        (e) => setRolei(e.detail.value)
                                                }>
                                                        <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                        <IonSelectOption value="Teacher">{t("teacher")}</IonSelectOption> 
                                                        <IonSelectOption value="Academic">{t("academic_incharge")}</IonSelectOption> 
                                                        {(authInfo.institute.transport) && (<>
                                                        <IonSelectOption value="Driver">{t("driver")}</IonSelectOption> 
                                                        <IonSelectOption value="Transporter">{t("transport_incharge")}</IonSelectOption> 
                                                        </>)}
                                                        {(authInfo.institute.payment) && (
                                                        <IonSelectOption value="Accountant">{t("accountant")}</IonSelectOption>
                                                        )}
                                                        {(authInfo.institute.admission || authInfo.institute.whatsapp) && (
                                                        <IonSelectOption value="AdmissionHead">{t("admission_head")}</IonSelectOption> 
                                                        )}
                                                        {(authInfo.institute.whatsapp) && (
                                                        <IonSelectOption value="SalesRep">{t("sales_rep")}</IonSelectOption> 
                                                        )}
                                                        {(authInfo.institute.inventory) && (
                                                        <IonSelectOption value="Inventory">{t("inventory_manager")}</IonSelectOption> 
                                                        )}
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="2">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("type")}
                                            </IonLabel>
                                            <IonSelect value={type}
                                                cancelText={t("cancel")}
                                                okText={t("ok")}
                                                placeholder={t("type")}
                                                onIonChange={
                                                    (e) => setType(e.detail.value)
                                                }>
                                                <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                <IonSelectOption value="general">{t("general_comm")}</IonSelectOption>
                                                <IonSelectOption value="academic">{t("academic_comm")}</IonSelectOption> 
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        
                        <IonCol size="2">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-from-input">
                                            <IonLabel position="stacked" color="secondary" className="input-label">
                                                {t("comm_from")}
                                            </IonLabel>
                                            <IonInput value={formatDated(fromDate)} readonly={true} />
                                            <IonModal trigger="open-from-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("comm_from")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                    cancelText={t("cancel")}
                                                    doneText={t("done")}
                                                        value={fromDate}
                                                        presentation="date"
                                                        onIonChange={ev => setFromDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="2">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-to-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("comm_to")}
                                            </IonLabel>
                                            <IonInput value={formatDated(toDate)} readonly={true} />
                                            <IonModal trigger="open-to-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("comm_to")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                    cancelText={t("cancel")}
                                                    doneText={t("done")}
                                                        value={toDate}
                                                        presentation="date"
                                                        onIonChange={ev => setToDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="3">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                        
                                    <IonItem detail={false}
                                        lines='none'
                                        button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("search_title")}
                                            </IonLabel>

                                        <IonInput type="text"
                                            value={searchText}
                                            placeholder={`\uD83D\uDD0D\u00A0\u00A0\u00A0${t("search_title")}`}
                                            onIonInput={
                                                (e) => setSearchText(e.detail.value!)
                                        } />
                                                
                                     
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        
                    </IonRow>
                </IonGrid>
                <IonGrid className="ion-no-padding mt-10 mb-60">
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={() => handleSubmit() }
                                color="primary"
                                size="small"
                                className='ion-text-wrap'>{t("manage_comms")}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <div className="ion-table mt-10">

                        <IonRow className="table-title ion-padding">
                            <IonCol sizeLg="4" size="7" className='table-heading'>
                                {t("type")}
                            </IonCol>

                            <IonCol sizeLg="3" size="0" className='table-heading ion-hide-lg-down'>
                            {t("title")}
                            </IonCol>
                            <IonCol size="3" className='table-heading'>
                            {t("date")}
                            </IonCol>

                            <IonCol size="1">
                                <IonIcon 
                                    icon={attach}
                                    color="light"
                                    className='table-icon'
                                    />
                            </IonCol>
                            <IonCol size="1">
                                <IonIcon 
                                    icon={construct}
                                    color="light"
                                    className='table-icon'
                                    />
                            </IonCol>
                        </IonRow>

                            {(circulars.length > 0) ? (circulars.map((circular, i) => {
                                return (
                                    <IonRow key={circular._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}
                                        onClick={() => history.push(`/app/staff/circular/${circular._id}`)}>
                                        
                                        <IonCol sizeLg="4" size="7" className='table-field' >
                                            <IonRow>
                                                <IonCol>
                                                    <IonText className='text-capitalize'>{circular.type}</IonText>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className='ion-hide-lg-up vid-responsive-field-1 text-capitalize'>
                                                <IonCol>
                                                    <IonLabel>
                                                        {circular.title}
                                                    </IonLabel>
                                                </IonCol>
                                            </IonRow>
                                        </IonCol>
                                        
                                        <IonCol sizeLg="3" size="0" className='table-field text-capitalize ion-hide-lg-down'>
                                            {circular.title}
                                        </IonCol>
                                        <IonCol size="3" className='table-field'>
                                            {formatDate(circular.createdAt)}
                                        </IonCol>
                                        
                                        <IonCol size="1" >
                                            {circular.filename && (
                                                <IonIcon 
                                                icon={attach}
                                                color="dark"
                                                className='table-icon mt-10'
                                                onClick={
                                                    () => history.push(`/app/staff/circular/${circular._id}`)
                                                }
                                                />
                                            )}
                                        </IonCol>
                                        <IonCol size="1" >
                                                <IonIcon 
                                                icon={construct}
                                                color="dark"
                                                className='table-icon mt-10'
                                                onClick={
                                                    () => history.push(`/app/staff/circular/${circular._id}`)
                                                }
                                                />
                                        </IonCol>
                                    </IonRow>
                                )
                            })) : ( 
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_comms")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )
                        } 
                    </div>

                    {(circulars.length > 0) &&
                    <IonRow>
                        <IonCol>
                            <IonInfiniteScroll
                                onIonInfinite={async (ev) => {
                                    await loadItems();
                                    ev.target.complete();
                                }}
                                >
                                <IonInfiniteScrollContent></IonInfiniteScrollContent>
                            </IonInfiniteScroll>
                        </IonCol>
                    </IonRow>
                    }

                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                <IonCol className="ion-padding-start footer-stats">
                                    {t("showing")} {total === 0 ? t("no_records") : `1 - ${circulars.length} ${t("of")} ${total}`} 
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-vertical">
                                <IonCol className=''>

                          
                                    <IonButton 
                                        onClick={
                                            () => history.push(`/app/staff/circular/add`)
                                                
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("new_circular")}</IonButton>


                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default CircularsStaff;