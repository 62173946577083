import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonTitle,
    IonToolbar,
    IonFooter,
    isPlatform,
    IonIcon,
    IonText,
    ItemReorderEventDetail,
    IonTextarea,
    IonReorderGroup,
    IonReorder
} from '@ionic/react';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { addCircle, arrowUndoCircle, book, toggle } from 'ionicons/icons';
import {useAuth} from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';

interface ParamTypes {
    courseID: string
}

interface Chapter {
    _id: string,
    chapterName: string,
    isActive: boolean,
    order?: number
}

const CourseDetail: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const history = useHistory();
    const {courseID} = useParams < ParamTypes > ();
    const [chapters, setChapters] = useState < Chapter[] > ([]);
    const [active, setActive] = useState < string > ("");
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [showSaved, setShowSaved] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [text, setText] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const handleSection = (section: string) => {

        const toggleCourse = (isActive: string) => {
            return new Promise((resolve, reject) => {
                api.put(`/courses/${courseID}`, { 'isActive': section }).then(res => {
                    return resolve(res.data.name);
                }).catch(err => reject(err));
            });
        };

        if ((active !== "") && (section !== active))
        {
            setShowLoading(true);
            toggleCourse(section)
            .then(data => {
                let msg: string = "";
                if (section === 'enable')
                {
                    setActive("enable");
                    msg = data+t("now_active");
                }
                    
                if (section === 'disable')
                {
                    setActive("disable");
                    msg = data+t("now_inactive");
                }
                    
                setPass(msg);
                setIspass(true);
            })
            .catch((error) => {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            })
            .finally(() => setShowLoading(false));
        }
    }

    const handleName = (name: string, index: number) => {

        let comps = [...chapters];
        comps[index].chapterName = name;
        setChapters(comps);
    
    }

    const handleDelete = (index: number) => {

        let comps = [...chapters];
        if(comps[index]._id.length > 10)
        {
            comps[index].isActive = !comps[index].isActive;
        }
        else
        {
            if (index > -1) {
                comps.splice(index, 1); // 2nd parameter means remove one item only
              }
        }
        
        setChapters(comps);
    }

    const handleAdd = () => {
        let r = (Math.random() + 1).toString(36).substring(7);
        let comps = [...chapters];
        comps.push({_id: r, chapterName: '', isActive: true});
        setChapters(comps);
    }


    const doReorder = (event: CustomEvent<ItemReorderEventDetail>) => {

        let from = event.detail.from;
        let to = event.detail.to;
        let toCopy = event.detail.to;

        let chaps = [...chapters];

        if (to < from)
        {
            chaps[to] = {...chapters[from]};
            to = to + 1;
            while (to <= from)
            {
                chaps[to] = {...chapters[to-1]};
                to = to + 1;
            }
        }

        if (toCopy > from)
        {
            chaps[toCopy] = {...chapters[from]};
            toCopy = toCopy - 1;
            while (toCopy >= from)
            {
                chaps[toCopy] = {...chapters[toCopy+1]};
                toCopy = toCopy - 1;
            }
        }

        setChapters(chaps);
        
        event.detail.complete();
      }

    
    const handleSubmit = () => {

        if (!name || (name === "")) {
            setMessage(t("course_name_mandatory"));
            setIserror(true);
            return;
        }

        if (!text || (text === "")) {
            setMessage(t("course_desc_mandatory"));
            setIserror(true);
            return;
        }

        if (!chapters || (chapters.length < 1)) {
            setMessage(t("chapters_mandatory"));
            setIserror(true);
            return;
        }

        for (let i = 0; i < chapters.length; i++)
        {
            if (chapters[i].chapterName === "")
            {
                setMessage(t("chapter_name_mandatory"));
                setIserror(true);
                return;
            }
        }

        
  
        const upCourse = ( chapters: Chapter[], desc: string ) => {
          return new Promise((resolve, reject) => {
            api.put('/courses/'+courseID, { name, desc, chapters }).then(res => {
              return resolve(res.data);
            }).catch(err => reject(err));
  
          });
        }

        setShowLoading(true);
        upCourse(chapters, text)
        .then((data: any) => {

            if (Object.hasOwn(data, 'chapters'))
            {
                let res = data.chapters.sort((a: Chapter, b: Chapter) => a.order! - b.order!);
                setChapters(res);
            }
  
            setPass(name+t("has_been_updated"));
            setIspass(true);
          
        })
        .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));
        
    }
    
    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {
                
                const result = await api.get('/courses/' + courseID);

                setName(result.data.name);
                setText(result.data.desc);

                if (result.data.isActive)
                    setActive("enable");
                else
                    setActive("disable");

                 //sort chapters based on order
                 let res = result.data.chapters.sort((a: Chapter, b: Chapter) => a.order! - b.order!);
                 setChapters(res);

                setShowLoading(false);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, courseID, refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary" />
                        <IonBackButton color="secondary" defaultHref="/app" />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("manage_course")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("manage_course")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={showSaved}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setShowSaved(false)
                    }
                    header={t("error")}
                    message={t("save_first")}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className="ion-no-padding">
                
                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                    {t("course_details")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-10">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard className='border-radius-10 active-group'>
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol size="8">
                                                
                                                {t("current_status")}
                                               
                                            </IonCol>
                                            <IonCol size="4" className='ion-text-right ion-padding-horizontal'>
                                            {(active === 'enable') ? ( <IonBadge color="success" className='ion-padding-horizontal'>{t("active")}</IonBadge>) : 
                                            ( <IonBadge color="danger" className='ion-padding-horizontal'>{t("inactive")}</IonBadge>)}
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className="ion-padding-top ion-padding-horizontal">
                                            <IonCol>
                                                <IonSegment value={active} onIonChange={e => handleSection( e.detail.value!.toString() )} mode="ios" className="isactive-segment">
                                                    <IonSegmentButton value="enable">
                                                        <IonLabel>{t("make_active")}</IonLabel>
                                                    </IonSegmentButton>
                                                    <IonSegmentButton value="disable">
                                                        <IonLabel>{t("make_inactive")}</IonLabel>
                                                    </IonSegmentButton>
                                                </IonSegment>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("course_name")}
                                        </IonLabel>
                                            <IonInput type="text"
                                                className="input-field"
                                                placeholder={t("course_name")}
                                                value={name}
                                                onIonInput={
                                                    (e) => setName(e.detail.value!)
                                            }></IonInput>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="floating" className="input-label" color="secondary">
                                            {t("short_desc")}
                                        </IonLabel>
                                        <IonTextarea rows={2} value={text} onIonInput={
                                            (e) => setText(e.detail.value!)
                                        }></IonTextarea>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className='mt-30'>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12" className='border-bottom-sec ion-padding-bottom'>
                               
                                <IonRow>
                                    <IonCol size="8" className='mt-05'>
                                        <IonText className='list-title ion-padding-start'>
                                        {t("academic_terms")}
                                        </IonText>
                                    </IonCol>

                                    <IonCol size="2" className='ion-text-right'>
                                    
                                        <IonIcon size="large"
                                            icon={arrowUndoCircle}
                                            color='secondary'
                                            onClick={() => setRefreshed(Math.random())}
                                        />
                                    
                                    
                                    </IonCol>
                                    <IonCol size="2" className='ion-text-center'>
                                        
                                            <IonIcon size="large"
                                                icon={addCircle}
                                                color='secondary'
                                                onClick={handleAdd}
                                            />
                                        
                                        
                                    </IonCol>
                                </IonRow>
                                
                        </IonCol>
                    </IonRow>

                   <IonRow className='mt-30'>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                        {chapters && (chapters.length > 0) && (
                
                            <IonCard>

                                <IonCardContent className="ion-no-padding">
                                    <IonReorderGroup disabled={false} onIonItemReorder={doReorder}>
                                            {chapters.map((chap, i) => {
                                                return (
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        key={chap._id}>
                                                            <IonReorder slot="start"/>
                                                            <IonInput type="text"
                                                                placeholder={t("term_title")}
                                                                value={chap.chapterName}
                                                                onIonInput={
                                                                    (e) => handleName(e.detail.value!, i)
                                                            }
                                                            readonly={!chap.isActive}
                                                            ></IonInput>
                                                            <IonButtons slot="end">
                                                                <IonButton onClick={() => handleDelete(i)}> 
                                                                    <IonIcon
                                                                    icon={toggle}
                                                                    color={chap.isActive ? "success" : "danger"}
                                                                    />
                                                                </IonButton>
                                                                {chap.isActive && (
                                                                <IonButton onClick={() => (chap._id.length > 10) ? history.push(`/app/courses/topics/${courseID}/${chap._id}`) : setShowSaved(true)}> 
                                                                    <IonIcon
                                                                    icon={book}
                                                                    color="tertiary"
                                                                    />
                                                                </IonButton>
                                                                )}
                                                            </IonButtons>
                                                            
                                                    </IonItem>
                                                )
                                                
                                                })
                                            }

                                    </IonReorderGroup>
                                </IonCardContent>
                            </IonCard>
                            )}
                        </IonCol>
                    </IonRow>


                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("update_schedule")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default CourseDetail;
