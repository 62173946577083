import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonDatetime,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail
} from '@ionic/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {useAuth} from "../../../../components/AuthContext";
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import { PhotoViewer } from '@capacitor-community/photoviewer';
import { star, starOutline } from 'ionicons/icons';

interface Session {
    _id: string,
    name: string,
    isActive: boolean
}

interface ClassInterface {
    _id: string,
    name: string,
    year: string,
    isActive: boolean
}

interface Application {
    _id: string,
    applicationType: string,
    amount: number,
    status: string,
    classInfo: ClassInfo,
    appointmentInfo?: AppointmentInfo,
    response?: string,
    rating?: number,
    childInfo: ChildInfo,
    formData: AppForm[],
    createdAt: Date
}
interface ClassInfo {
    classID: string,
    className: string
}

interface AppointmentInfo {
    dated: Date,
    info: string
}

interface ChildInfo {
    childID: string,
    childName: string
}

interface AppForm {
    name: string,
    category: string,
    value: string
}

interface ParamTypes {
    applicationID: string
}

//unpaid apps option to receive payment by cash
//approve option to select class, add student, and change account to parent
//pending apps option to reject or schedule appointment
//pending appointment (shortlisted) to show reschedule or approve or reject
//rest show status, if appointmentInfo available, show as well

const ApplicationDetail: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const history = useHistory();
    const {applicationID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [application, setApplication] = useState < Application > ();
    const [refreshed, setRefreshed] = useState < number > (0);
    const [showAlert, setShowAlert] = useState < boolean > (false);
    const [showAlert1, setShowAlert1] = useState < boolean > (false);
    const [showAlert2, setShowAlert2] = useState < boolean > (false);
    const [showAlert3, setShowAlert3] = useState < boolean > (false);
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [response, setResponse] = useState < string > ("");
    const [info, setInfo] = useState < string > ("");
    const [selectedDate, setSelectedDate] = useState < string | string[] > (new Date(Date.now()).toISOString());
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [classes, setClasses] = useState < ClassInterface[] > ([]);
    const [classID, setClassID] = useState < string > ("");
    const [session, setSession] = useState < string > ("");
    const [rating, setRating] = useState < number > (0);

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const openImage = (url: string, name: string) => {
        PhotoViewer.show({images: [{url, title: name}]});
    };

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB');
    };

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const formatDated = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' }) +" "+ t("at") +" "+ valueDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
    };

    //Reject
    const handleDelete = () => {

        if (!response || response === "") {
            setMessage(t("response_mandatory"));
            setIserror(true);
            return;
        }
  
        const deleteApp = () => {
            return new Promise((resolve, reject) => {
                api.put(`/applications/${applicationID}`, {status: 'rejected', response}).then(res => {
                    return resolve(true);
                }).catch(err => reject(err));
            });
        }
  
        setShowLoading(true);
        deleteApp()
        .then(() => {
  
            setPass(t("application_rejected"));
            setIspass(true);
        })
        .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));
        
    }

    //Shortlist - Schedule an appointment
    const handleShort = () => {

        if (!info || info === "") {
            setMessage(t("app_info_mandatory"));
            setIserror(true);
            return;
        }

        if (!selectedDate || selectedDate === "") {
            setMessage(t("select_app_date"));
            setIserror(true);
            return;
        }
  
        const shortApp = (appointDate: string | string[], appointInfo: string) => {
            return new Promise((resolve, reject) => {
                api.put(`/applications/${applicationID}`, {status: 'shortlisted', appointDate, appointInfo}).then(res => {
                    return resolve(true);
                }).catch(err => reject(err));
            });
        }
  
        setShowLoading(true);
        shortApp(selectedDate, info)
        .then(() => {
  
            setPass(t("appoint_scheduled"));
            setIspass(true);
        })
        .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));
        
    }

    // create student in erp, (just ask for class) convert admission User account into parent role
    // make sure we have all required student info, if not, ask more info
    const handleApproved = () => {

        if (!classID || classID === "") {
            setMessage((authInfo.institute.type === "school") ? t("select_class") : t("select_batch"));
            setIserror(true);
            return;
        }

        if (!response || response === "") {
            setMessage(t("response_mandatory"));
            setIserror(true);
            return;
        }
  
        const shortApp = () => {
            return new Promise((resolve, reject) => {
                api.put(`/applications/${applicationID}`, {status: 'approved', response, classID}).then(res => {
                    return resolve(true);
                }).catch(err => reject(err));
            });
        }

        setShowLoading(true);
        shortApp()
        .then(() => {
  
            setPass(t("application_approved"));
            setIspass(true);
        })
        .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));

    }

    //Pay By Cash
    const handlePaid = () => {
  
        const paidApp = () => {
            return new Promise((resolve, reject) => {
                api.get(`/applications/paidByCash/${applicationID}`).then(res => {
                    return resolve(true);
                }).catch(err => reject(err));
            });
        }
  
        setShowLoading(true);
        paidApp()
        .then(() => {
  
            setPass(t("application_paid"));
            setIspass(true);
        })
        .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));
        
    }

    const handleSession = (sessionName : string) => {

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${sessionName}`);
                setClasses(result.data);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();
        setSession(sessionName);
        setClassID("");
    }   
    
    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const appp = await api.get(`/applications/${applicationID}`);
                setApplication(appp.data);
                if (appp.data.response)
                {
                    setResponse(appp.data.response);
                }
                if (appp.data.rating)
                {
                    setRating(appp.data.rating);
                }

                const res = await api.get(`/sessions/getAllInstitute/${authInfo.user.instituteID}`);
                setSessions(res.data);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchData();

    }, [authInfo, refreshed, applicationID]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{application && (application.applicationType === "admission") ? t("application_form") : t("workshop_details")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{application && (application.applicationType === "admission") ? t("application_form") : t("workshop_details")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false);
                            setRefreshed(Math.random());
                        }
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />
            
                <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    header={t("confirm")}
                    message={t("reject_app")}
                    buttons={[
                        {
                        text: t('no'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        },
                        {
                        text: t('yes'),
                        handler: () => {
                            handleDelete();
                        }
                        }
                    ]}
                />

                <IonAlert
                    isOpen={showAlert1}
                    onDidDismiss={() => setShowAlert1(false)}
                    header={t("confirm")}
                    message={t("appoint_app")}
                    buttons={[
                        {
                        text: t('no'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        },
                        {
                        text: t('yes'),
                        handler: () => {
                            handleShort();
                        }
                        }
                    ]}
                />

                <IonAlert
                    isOpen={showAlert2}
                    onDidDismiss={() => setShowAlert2(false)}
                    header={t("confirm")}
                    message={t("admit_app")}
                    buttons={[
                        {
                        text: t('no'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        },
                        {
                        text: t('yes'),
                        handler: () => {
                            handleApproved();
                        }
                        }
                    ]}
                />

                <IonAlert
                    isOpen={showAlert3}
                    onDidDismiss={() => setShowAlert3(false)}
                    header={t("confirm")}
                    message={t("paid_app")+application?.amount}
                    buttons={[
                        {
                        text: t('no'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        },
                        {
                        text: t('yes'),
                        handler: () => {
                            handlePaid();
                        }
                        }
                    ]}
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className='mb-60'>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            {(application) && (
                            <IonCard className={(application.applicationType === "workshop") ? "ion-margin-vertical planner-card-3 ion-padding" : "ion-margin-vertical class-card"}>

                                <IonCardContent className="ion-no-padding">
                                        <IonRow>
                                            <IonCol>
                                                <IonRow className="pb-08">
                                                    <IonCol size="8">
                                                        <p><span className="class-name" >{application.childInfo.childName}</span></p>
                                                        <p><span className="class-info-2">{application.classInfo.className}</span></p>
                                                    </IonCol>
                                                    <IonCol size="4" className="ion-text-right">
                                                        <p><span className="class-info-2">{formatDate(application.createdAt)}</span></p>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="pr-16">
                                                    <IonCol size="8" className='mt-05'>
                                                        <p><span className="class-info-3">{t("app_fees")} {application.amount}/-</span></p>
                                                    </IonCol>
                                                    <IonCol size="4" className="ion-text-right">
                                                        {(application.status === "unpaid") && (
                                                            <IonBadge color="tertiary">{t("unpaid")}</IonBadge>
                                                        )}

                                                        {(application.status === "pending") && (
                                                            <IonBadge color="warning">{t("processing")}</IonBadge>
                                                        )}
                                                        {(application.status === "shortlisted") && (
                                                            <IonBadge color="secondary">{t("appointment")}</IonBadge>
                                                        )}
                                                        {(application.status === "approved") && (
                                                            <IonBadge color="success">{t("admitted")}</IonBadge>
                                                        )}
                                                         {(application.status === "rejected") && (
                                                            <IonBadge color="danger">{t("rejected")}</IonBadge>
                                                        )}


                                                        {(application.status === "upcoming") && (
                                                            <IonBadge color="warning">{t("upcoming")}</IonBadge>
                                                        )}
                                                        {(application.status === "completed") && (
                                                            <IonBadge color="success">{t("completed")}</IonBadge>
                                                        )}
                                                        {(application.status === "cancelled") && (
                                                            <IonBadge color="danger">{t("cancelled")}</IonBadge>
                                                        )}
                                                       
                                                    </IonCol>
                                                </IonRow>
                                                {(application.status === "shortlisted") && application.appointmentInfo && (
                                                <>
                                                    <IonRow>
                                                        <IonCol>
                                                            <p><span className="class-info-3">{t("appointment_date")} {formatDated(application.appointmentInfo.dated)}</span></p>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <p><span className="class-info-4">{application.appointmentInfo.info}</span></p>
                                                        </IonCol>
                                                    </IonRow>
                                                </>
                                                )}
                                                {((application.status === "rejected") || (application.status === "approved")) && application.response && (
                                            
                                                    <IonRow>
                                                        <IonCol>
                                                            <p><span className="class-info-4">{application.response}</span></p>
                                                        </IonCol>
                                                    </IonRow>
                                                
                                                )}
                                                {(application.appointmentInfo && (application.applicationType === "workshop")) && (
                                                    <IonRow>
                                                        <IonCol><p><span className="class-info-3">{t("project_start")}: {formatDated(application.appointmentInfo.dated)}</span><br/>
                                                            <span className="class-info-3">{t("duration")}: {application.appointmentInfo.info}</span></p>
                                                        </IonCol>
                                                    </IonRow>
                                                )}
                                            </IonCol>
                                        </IonRow>
                                        {((application.status === "unpaid") || (application.status === "pending") || (application.status === "shortlisted")) && (
                                        <IonRow>
                                            <IonCol>
                                                    {(application.status === "unpaid") && (
                                                        <IonButton onClick={() => setShowAlert3(true)}
                                                            className="first-button"
                                                            fill="clear"
                                                        >
                                                            {t("paid_by_cash")}
                                                        </IonButton>
                                                    )}

                                                    {(application.status === "pending") && (
                                                    <>
                                                        <IonButton id="open-date-input"
                                                            className="first-button"
                                                            fill="clear"
                                                        >
                                                            {t("schedule_appoint")}
                                                        </IonButton>
                                                        <IonButton id="open-reject-input"
                                                            className="fifth-button"
                                                            fill="clear"
                                                        >
                                                            {t("reject")}
                                                        </IonButton>
                                                    </>
                                                    )}

                                                    {(application.status === "shortlisted") && (
                                                    <>
                                                        <IonButton id="open-date-input"
                                                            className="first-button"
                                                            fill="clear"
                                                        >
                                                            {t("reschedule_appoint")}
                                                        </IonButton>
                                                        <IonButton id="open-admit-input"
                                                            className="first-button"
                                                            fill="clear"
                                                        >
                                                            {t("admit_student")}
                                                        </IonButton>
                                                        <IonButton id="open-reject-input"
                                                            className="fifth-button"
                                                            fill="clear"
                                                        >
                                                            {t("reject")}
                                                        </IonButton>
                                                    </>
                                                    )}

                                                    <IonModal trigger="open-date-input" showBackdrop={true} 
                                                        initialBreakpoint={0.95} breakpoints={[0, 0.95]} handle={true}>
                                                        <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                            <IonToolbar>
                                                                <IonTitle>{t("schedule_appoint")}</IonTitle>
                                                            </IonToolbar>
                                                            <IonRow>
                                                                <IonCol>
                                                                    <IonList>
                                                                        <IonItem detail={false}
                                                                            lines='full'
                                                                            button={false}>
                                                                            
                                                                            <IonDatetime
                                                                                cancelText={t("cancel")}
                                                                                doneText={t("ok")}
                                                                                value={selectedDate}
                                                                                presentation="date-time"
                                                                                onIonChange={ev => setSelectedDate(ev.detail.value!)}
                                                                                showDefaultButtons={false}
                                                                                size="cover"
                                                                            />
                                                                        </IonItem>
                                                                        <IonItem detail={false}
                                                                            lines='none'
                                                                            button={false}>
                                                                            <IonLabel position="stacked" color="secondary" className="input-label">{t("appoint_info")}</IonLabel>
                                                                                <IonTextarea rows={3} value={info} onIonInput={
                                                                                                    (e) => setInfo(e.detail.value!)
                                                                                }></IonTextarea>
                                                                        </IonItem>
                                                                    </IonList>
                                                                </IonCol>
                                                            </IonRow>
                                                    
                                                            <IonRow className="mt-30">
                                                                <IonCol className="ion-padding-horizontal ion-text-center">
                                                                    <IonButton onClick={() => setShowAlert1(true)}
                                                                        className="first-button"
                                                                        fill="clear"
                                                                        >{t("schedule")}</IonButton>

                                                                </IonCol>
                                                            </IonRow>
                                                        </IonContent>
                                                        
                                                    </IonModal>

                                                    <IonModal trigger="open-reject-input" showBackdrop={true} 
                                                        initialBreakpoint={0.5} breakpoints={[0, 0.5, 0.9]} handle={true}>
                                                        <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                            <IonToolbar>
                                                                <IonTitle>{t("reject_application")}</IonTitle>
                                                            </IonToolbar>
                                                            <IonRow>
                                                                <IonCol>
                                                                    <IonItem detail={false}
                                                                        lines='none'
                                                                        button={false}>
                                                                        <IonLabel position="stacked" color="secondary" className="input-label">{t("response")}</IonLabel>
                                                                            <IonTextarea rows={3} value={response} onIonInput={
                                                                                                (e) => setResponse(e.detail.value!)
                                                                            }></IonTextarea>
                                                                    </IonItem>
                                                                </IonCol>
                                                            </IonRow>
                                                    
                                                            <IonRow className="mt-30">
                                                                <IonCol className="ion-padding-horizontal ion-text-center">
                                                                    <IonButton onClick={() => setShowAlert(true)}
                                                                        className="first-button"
                                                                        fill="clear"
                                                                        >{t("reject")}</IonButton>

                                                                </IonCol>
                                                            </IonRow>
                                                        </IonContent>
                                                        
                                                    </IonModal>

                                                    <IonModal trigger="open-admit-input" showBackdrop={true} 
                                                        initialBreakpoint={0.75} breakpoints={[0, 0.75, 0.9]} handle={true}>

                                                        <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                            <IonToolbar>
                                                                <IonTitle>{(authInfo.institute.type === "school") ? t("select_admit_class") : t("select_admit_batch")}</IonTitle>
                                                            </IonToolbar>
                                                            <IonRow>
                                                        
                                                                <IonCol size="6">
                                                                    <IonCard>
                                                                        <IonCardContent className="ion-no-padding">
                                                                            <IonItem>
                                                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                                                    {t("class_session")}</IonLabel>
                                                                                    <IonSelect value={session}
                                                                                            className="input-field"
                                                                                            cancelText={t("cancel")}
                                                                                            okText={t("ok")}
                                                                                            placeholder={t("class_session")}
                                                                                            onIonChange={
                                                                                                (e) => handleSession(e.detail.value)
                                                                                        }>

                                                                                            {sessions.map((session) => (
                                                                                                (session.isActive) && 
                                                                                                (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                                                            ))}

                                                                                    </IonSelect>
                                                                                </IonItem>
                                                                        </IonCardContent>
                                                                    </IonCard>
                                                                </IonCol>

                                                                <IonCol size="6">
                                                                    <IonCard>
                                                                        <IonCardContent className="ion-no-padding">
                                                                            <IonItem>
                                                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                                                {(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}</IonLabel>
                                                                                    <IonSelect value={classID}
                                                                                            className="input-field"
                                                                                            cancelText={t("cancel")}
                                                                                            okText={t("ok")}
                                                                                            placeholder={(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}
                                                                                            onIonChange={
                                                                                                (e) => setClassID(e.detail.value)
                                                                                        }>
                                                                                        
                                                                                            {classes.map((classy) => (
                                                                                                (classy.isActive) &&
                                                                                                (<IonSelectOption key={classy._id} value={`${classy._id}`}>{classy.name} ({classy.year})</IonSelectOption>)
                                                                                            ))}
                                                                                    </IonSelect>
                                                                                </IonItem>
                                                                        </IonCardContent>
                                                                    </IonCard>
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol>
                                                                    <IonItem detail={false}
                                                                        lines='none'
                                                                        button={false}>
                                                                        <IonLabel position="stacked" color="secondary" className="input-label">{t("response")}</IonLabel>
                                                                            <IonTextarea rows={3} value={response} onIonInput={
                                                                                                (e) => setResponse(e.detail.value!)
                                                                            }></IonTextarea>
                                                                    </IonItem>
                                                                </IonCol>
                                                            </IonRow>

                                                            <IonRow className="mt-30">
                                                                <IonCol className="ion-padding-horizontal ion-text-center">
                                                                    <IonButton onClick={() => setShowAlert2(true)}
                                                                        className="first-button"
                                                                        fill="clear"
                                                                        >{t("admit_student")}</IonButton>

                                                                </IonCol>
                                                            </IonRow>
                                                        </IonContent>
                                                        
                                                    </IonModal>

                                            </IonCol>
                                        </IonRow>
                                        )}
                                </IonCardContent>
                            </IonCard>
                            )}
                        </IonCol>
                            
                    </IonRow>

                        
                    {application && (application.applicationType === "admission") && (<>
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                    {t("application_details")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">

                            <IonCard>
                                <IonCardHeader>
                                    <IonCardSubtitle className='info-subtitle'>{t("childs_details")}</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">


                                        {(application && application.formData.length > 0) && application.formData.map((admnField, i) => (
                                            (admnField.category === 'Child') &&
                                            (
                                                <IonItem detail={false}
                                                    lines='inset'
                                                    button={false}
                                                    key={i}>
                                                    <IonLabel position="stacked" color="secondary">{admnField.name}</IonLabel>

                                                   
                                                    <IonInput type="text"
                                                        value={admnField.value}
                                                        readonly={true}
                                                    ></IonInput>

                                                </IonItem>
                                            )
                                        ))}

                                    </IonList>
                                </IonCardContent>
                                </IonCard>

                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">

                            <IonCard>
                                <IonCardHeader>
                                    <IonCardSubtitle className='info-subtitle'>{t("mothers_details")}</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">


                                        {(application && application.formData.length > 0) && application.formData.map((admnField, i) => (
                                            (admnField.category === 'Mother') &&
                                            (
                                                <IonItem detail={false}
                                                    lines='inset'
                                                    button={false}
                                                    key={i}>
                                                    <IonLabel position="stacked" color="secondary">{admnField.name}</IonLabel>

                                                   
                                                    <IonInput type="text"
                                                        value={admnField.value}
                                                        readonly={true}
                                                    ></IonInput>

                                                </IonItem>
                                            )
                                        ))}

                                    </IonList>
                                </IonCardContent>
                                </IonCard>

                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">

                            <IonCard>
                                <IonCardHeader>
                                    <IonCardSubtitle className='info-subtitle'>{t("fathers_details")}</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">


                                        {(application && application.formData.length > 0) && application.formData.map((admnField, i) => (
                                            (admnField.category === 'Father') &&
                                            (
                                                <IonItem detail={false}
                                                    lines='inset'
                                                    button={false}
                                                    key={i}>
                                                    <IonLabel position="stacked" color="secondary">{admnField.name}</IonLabel>

                                                   
                                                    <IonInput type="text"
                                                        value={admnField.value}
                                                        readonly={true}
                                                    ></IonInput>

                                                </IonItem>
                                            )
                                        ))}

                                    </IonList>
                                </IonCardContent>
                                </IonCard>

                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">

                            <IonCard>
                                <IonCardHeader>
                                    <IonCardSubtitle className='info-subtitle'>{t("address_details")}</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">


                                        {(application && application.formData.length > 0) && application.formData.map((admnField, i) => (
                                            (admnField.category === 'Address') &&
                                            (
                                                <IonItem detail={false}
                                                    lines='inset'
                                                    button={false}
                                                    key={i}>
                                                    <IonLabel position="stacked" color="secondary">{admnField.name}</IonLabel>

                                                   
                                                    <IonInput type="text"
                                                        value={admnField.value}
                                                        readonly={true}
                                                    ></IonInput>

                                                </IonItem>
                                            )
                                        ))}

                                    </IonList>
                                </IonCardContent>
                                </IonCard>

                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">

                            <IonCard>
                                <IonCardHeader>
                                    <IonCardSubtitle className='info-subtitle'>{t("childs_documents")}</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">


                                        {(application && application.formData.length > 0) && application.formData.map((admnField, i) => (
                                            (admnField.category === 'ChildDocument') &&
                                            (
                                                <IonItem detail={false}
                                                    lines='none'
                                                    button={false}
                                                    key={i}>
                                                    <IonLabel position="stacked" color="secondary">{admnField.name}</IonLabel>
                                  
                                                        <img onClick={() => openImage(`${process.env.REACT_APP_STATIC_S3}/documents/${admnField.value}`, admnField.name)}
                                                        src={`${process.env.REACT_APP_STATIC_S3}/documents/${admnField.value}`} 
                                                        alt={admnField.name}
                                                        width="320" 
                                                        className='ion-padding-vertical'
                                                        />
                                       
                                                </IonItem>
                                            )
                                        ))}

                                    </IonList>
                                </IonCardContent>
                                </IonCard>

                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">

                            <IonCard>
                                <IonCardHeader>
                                    <IonCardSubtitle className='info-subtitle'>{t("other_documents")}</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">


                                        {(application && application.formData.length > 0) && application.formData.map((admnField, i) => (
                                            (admnField.category === 'Document') &&
                                            (
                                                <IonItem detail={false}
                                                    lines='none'
                                                    button={false}
                                                    key={i}>
                                                    <IonLabel position="stacked" color="secondary">{admnField.name}</IonLabel>

                                                            <img onClick={() => openImage(`${process.env.REACT_APP_STATIC_S3}/documents/${admnField.value}`, admnField.name)}
                                                            src={`${process.env.REACT_APP_STATIC_S3}/documents/${admnField.value}`} 
                                                            alt={admnField.name}
                                                            width="320" 
                                                            className='ion-padding-vertical'
                                                            />
                                       
                                                </IonItem>
                                            )
                                        ))}

                                    </IonList>
                                </IonCardContent>
                                </IonCard>

                        </IonCol>
                    </IonRow>
                    </>)}


                    {application && (application.applicationType === "workshop") && (application.status === "completed") && (<>
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                    {t("workshop_feedback")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-10">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                    <IonLabel className='ml-30'>
                                   
                                        {(rating === 1) && (
                                            <>
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium"
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                            </>
                                        )}
                                        {(rating === 2) && (
                                            <>
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary"
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                            </>
                                        )}
                                        {(rating === 3) && (
                                            <>
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary"
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                            </>
                                        )}
                                        {(rating === 4) && (
                                            <>
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary"
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                            </>
                                        )}
                                        {(rating === 5) && (
                                            <>
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary"
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                            </>
                                        )}
                                                                    
                                    </IonLabel>
                        </IonCol>
                    </IonRow>
                    
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    
                                    <IonItem detail={false}
                                        lines='none'
                                        button={false}
                                        >
                                        <IonLabel position="floating" color="dark">{t("feedback_details")}</IonLabel>
                                        <IonTextarea rows={6} autoGrow={true} value={response} readonly={true}></IonTextarea>
                                    </IonItem>
                                            
                                        
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </>
                )}

                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default ApplicationDetail;