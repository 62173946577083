import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../../components/AuthContext';
import {Link, useHistory} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import "swiper/css/navigation";
import '@ionic/react/css/ionic-swiper.css';
import { alertCircleOutline } from 'ionicons/icons';

interface Application {
    _id: string,
    amount: number,
    status: string,
    classInfo: ClassInfo,
    appointmentInfo?: AppointmentInfo,
    childInfo: ChildInfo,
    createdAt: Date
}
interface ClassInfo {
    classID: string,
    className: string
}

interface AppointmentInfo {
    dated: Date,
    info: string
}

interface ChildInfo {
    childID: string,
    childName: string
}

interface Total {
    status: string,
    totalCount: number
}

const categories = ["unpaid", "pending", "shortlisted", "approved", "rejected"];

const ApplicationsBoard: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [applications, setApplications] = useState < Application[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [skip, setSkip] = useState < number > (1);
    const [refreshed, setRefreshed] = useState < number > (0);
    const [total, setTotal] = useState < Total[] > ([]);

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const loadItems = async () => {

        let limit = skip + 1;

        try {
            const result = await api.get(`/applications/listBoard/${authInfo.user.instituteID}?skip=${limit}`);

            if(result.data.applications && result.data.applications.length)
            {
                setApplications([...applications, ...result.data.applications]); 
                setSkip(limit);
            }
                

        } catch (error: any) {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        }
        
    }

    useEffect(() => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/applications/listBoard/${authInfo.user.instituteID}`);
                setApplications(result.data.applications);
                setTotal(result.data.totalCounts);
                setSkip(1);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();
        
    }, [authInfo, refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/admission`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("applications_board")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                       <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("applications_board")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding mb-60 fullscreen-board">
                    <IonRow className="fullscreen-board">
                        <IonCol>
                            <Swiper className="fullscreen-board mb-60"
                                modules={[Navigation]}
                                slidesPerView={1}
                                spaceBetween={0}
                                initialSlide={0}
                                navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
                                loop={false}
                                breakpoints={{
                                    576: {
                                        slidesPerView: 2
                                    },
                                    992: {
                                        slidesPerView: 3
                                    },
                                    1400: {
                                        slidesPerView: 4
                                    }
                                }}>
                                { categories.map((cat, index) => {
                                    return (
                                        <SwiperSlide key={index} className='categorySlide'>
                                            <IonCardSubtitle className='categoryName'>
                                               
                                                {t(cat)} ({total.find((val) => val.status === cat)?.totalCount || 0})
                                               
                                            </IonCardSubtitle>
                                                {applications.map((app, i) => 
                                                    (app.status === cat) && (
                                                    <IonRow key={app._id} className='kanbanItem' >
                                                        <IonCol className='pointer' onClick={
                                                             () => history.push(`/app/application/${app._id}`)
                                                        }>
                                                            <div className='itemList'>
                                                                <IonLabel>
                                                                    <div className='itemTitle'>
                                                                        <h3 className='text-capitalize'>{app.childInfo.childName}</h3>
                                                                    </div>
                                                                    <p className='itemLabels overflow-hide'>
                                                                        {app.classInfo.className}
                                                                    </p>
                                                                    <p> 
                                                                        {formatDate(app.createdAt)}
                                                                        <span className="ml-16">
                                                                            <IonBadge color={(app.status === "unpaid") ? "warning" : (app.status === "pending") ? "secondary" : (app.status === "shortlisted")
                                                                                ? "tertiary" : (app.status === "approved") ? "success" : "danger" }>    
                                                                               {t("rs")} {app.amount} 
                                                                            </IonBadge>
                                                                        </span>
                                                                    </p>
                                                                </IonLabel>
                                                            </div>
                                                        </IonCol>
                                                    </IonRow>
                                                    )
                                                )}

                                                {(total.find((val) => val.status === cat)?.totalCount || 0) === 0 && (
                                                <IonRow className='kanbanItem' >
                                                     <IonCol>
                                                        <IonItem lines="none" className='item-white-shade'>
                                                            <IonIcon icon={alertCircleOutline}
                                                                slot="start"
                                                                color="danger" />
                                                            <IonLabel className="list-title"><h3>{t("no_applications")}</h3></IonLabel>
            
                                                        </IonItem>
                                                     </IonCol>
                                                 </IonRow>
                                                )}
                                                
                                                
                                        </SwiperSlide>
                                    );
                                })}
                            </Swiper>
                            <div className="swiper-button-next"></div>
                            <div className="swiper-button-prev"></div>
                        </IonCol>
                    </IonRow>
                    {(applications.length > 0) &&
                    <IonRow>
                        <IonCol>
                            <IonInfiniteScroll
                                onIonInfinite={async (ev) => {
                                    await loadItems();
                                    ev.target.complete();
                                }}
                                >
                                <IonInfiniteScrollContent></IonInfiniteScrollContent>
                            </IonInfiniteScroll>
                        </IonCol>
                    </IonRow>
                    }

            </IonGrid>
        </IonContent>
    </IonPage>
    );
};

export default ApplicationsBoard;