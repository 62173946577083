import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import { chatbubbleEllipsesOutline, closeCircleOutline, mailOpenOutline, mailUnreadOutline, thumbsDownOutline, thumbsUpOutline } from 'ionicons/icons';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';

const InquiryReports: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [enquiries, setEnquiries] = useState < number > (0);
    const [newLeads, setNewLeads] = useState < number > (0);
    const [openLeads, setOpenLeads] = useState < number > (0);
    const [convertedLeads, setConvertedLeads] = useState < number > (0);
    const [failedLeads, setFailedLeads] = useState < number > (0);
    const [disqualifiedLeads, setDisqualifiedLeads] = useState < number > (0);

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/institutes/enquiries/${authInfo.user.instituteID}`);
                setEnquiries(result.data.total);
                setNewLeads(result.data.newLeads);
                setOpenLeads(result.data.openLeads);
                setConvertedLeads(result.data.convertedLeads);
                setFailedLeads(result.data.failedLeads);
                setDisqualifiedLeads(result.data.disqualifiedLeads);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo]); 

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/crm`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("enquiries_reports")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("enquiries_reports")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding mt-30 mb-60">

                    <IonRow className="ion-padding-vertical max-xl">
                        <IonCol size="12" sizeLg='6' className='ion-padding-horizontal'>
                            <IonCard className="stat-card-7">
                                <IonCardContent className="ion-padding">
                                        <IonRow> 
                                            <IonCol size="9" className='ion-padding'>
                                                <IonRow>
                                                    <IonCol className="reports-info">         
                                                    {enquiries}
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="ion-margin-top reports-label">
                                                    <IonCol>
                                                    {t("total")} {t("enquiries")}
                                                       </IonCol>
                                                </IonRow>
                                            </IonCol>
                                            <IonCol size="3">
                                                <div className="vertical-container">
                                                     <div className="vertical-center"> 
                                                        
                                                        <IonIcon 
                                                            icon={chatbubbleEllipsesOutline}
                                                            className='reports-icon'
                                                        />
                                                      
                                                    </div>
                                                </div>
                                            </IonCol>
                                        </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="12" sizeLg='6' className='ion-padding-horizontal'>
                            <IonCard className="stat-card-6">
                                <IonCardContent className="ion-padding">
                                        <IonRow> 
                                            <IonCol size="9" className='ion-padding'>
                                                <IonRow>
                                                    <IonCol className="reports-info">         
                                                    {newLeads}
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className='ion-margin-top reports-label'>
                                                    <IonCol>
                                                    {t("new_leads")}
                                                    </IonCol>
                                                </IonRow>
                                            </IonCol>
                                            <IonCol size="3">
                                                <div className="vertical-container">
                                                     <div className="vertical-center"> 
                                                        
                                                        <IonIcon 
                                                            icon={mailUnreadOutline}
                                                            className='reports-icon'
                                                        />
                                                      
                                                    </div>
                                                </div>
                                            </IonCol>
                                        </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="12" sizeLg='6' className='ion-padding-horizontal'>
                            <IonCard className="stat-card-1">
                                <IonCardContent className="ion-padding">
                                        <IonRow> 
                                            <IonCol size="9" className='ion-padding'>
                                                <IonRow>
                                                    <IonCol className="reports-info">         
                                                    {openLeads}
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="ion-margin-top reports-label">
                                                    <IonCol>
                                                    {t("open_leads")}
                                                       </IonCol>
                                                </IonRow>
                                            </IonCol>
                                            <IonCol size="3">
                                                <div className="vertical-container">
                                                     <div className="vertical-center"> 
                                                        
                                                        <IonIcon 
                                                            icon={mailOpenOutline}
                                                            className='reports-icon'
                                                        />
                                                      
                                                    </div>
                                                </div>
                                            </IonCol>
                                        </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="12" sizeLg='6' className='ion-padding-horizontal'>
                            <IonCard className="stat-card-4">
                                <IonCardContent className="ion-padding">
                                        <IonRow> 
                                            <IonCol size="9" className='ion-padding'>
                                                <IonRow>
                                                    <IonCol className="reports-info">         
                                                    {convertedLeads}
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className='ion-margin-top reports-label'>
                                                    <IonCol>
                                                    {t("leads_converted")}
                                                    </IonCol>
                                                </IonRow>
                                            </IonCol>
                                            <IonCol size="3">
                                                <div className="vertical-container">
                                                     <div className="vertical-center"> 
                                                        
                                                        <IonIcon 
                                                            icon={thumbsUpOutline}
                                                            className='reports-icon'
                                                        />
                                                      
                                                    </div>
                                                </div>
                                            </IonCol>
                                        </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="12" sizeLg='6' className='ion-padding-horizontal'>
                            <IonCard className="stat-card-5">
                                <IonCardContent className="ion-padding">
                                        <IonRow> 
                                            <IonCol size="9" className='ion-padding'>
                                                <IonRow>
                                                    <IonCol className="reports-info">         
                                                    {failedLeads}
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="ion-margin-top reports-label">
                                                    <IonCol>
                                                    {t("leads_failed")}
                                                       </IonCol>
                                                </IonRow>
                                            </IonCol>
                                            <IonCol size="3">
                                                <div className="vertical-container">
                                                     <div className="vertical-center"> 
                                                        
                                                        <IonIcon 
                                                            icon={thumbsDownOutline}
                                                            className='reports-icon'
                                                        />
                                                      
                                                    </div>
                                                </div>
                                            </IonCol>
                                        </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="12" sizeLg='6' className='ion-padding-horizontal'>
                            <IonCard className="stat-card-2">
                                <IonCardContent className="ion-padding">
                                        <IonRow> 
                                            <IonCol size="9" className='ion-padding'>
                                                <IonRow>
                                                    <IonCol className="reports-info">         
                                                    {disqualifiedLeads}
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className='ion-margin-top reports-label'>
                                                    <IonCol>
                                                    {t("leads_disqualified")}
                                                    </IonCol>
                                                </IonRow>
                                            </IonCol>
                                            <IonCol size="3">
                                                <div className="vertical-container">
                                                     <div className="vertical-center"> 
                                                        
                                                        <IonIcon 
                                                            icon={closeCircleOutline}
                                                            className='reports-icon'
                                                        />
                                                      
                                                    </div>
                                                </div>
                                            </IonCol>
                                        </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                  
                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default InquiryReports;
