import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, time, trash } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';

interface Employee {
    _id: string,
    userInfo: UserInfo,
    isActive: boolean
}

interface UserInfo {
    userID: string,
    name: string
}

interface Department {
    _id: string,
    name: string
}

interface Attendance {
    _id: string,
    status: string,
    createdAt: Date
}

interface ParamTypes {
    employeeID?: string
}

const EmployeeLog: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const {employeeID} = useParams < ParamTypes > ();
    const [selectedEmployee, setSelectedEmployee] = useState <string> ("");
    const [employees, setEmployees] = useState < Employee[] > ([]);
    const [departments, setDepartments] = useState < Department[] > ([]);
    const [department, setDepartment] = useState < string > ("");
    const [attendance, setAttendance] = useState < Attendance[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [showAlert, setShowAlert] = useState < boolean > (false);
    const [attendanceID, setAttendanceID] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [skip, setSkip] = useState < number > (0);

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const loadItems = async () => {

        if (!selectedEmployee || selectedEmployee === "") {
            setMessage(t("select_employee"));
            setIserror(true);
            return;
        }

        let limit = skip+30;

        try {
            const result = await api.get(`/staffAttends/getLogEmployee/${selectedEmployee}?skip=${limit}`);

            if(result.data)
            {
                setAttendance([...attendance, ...result.data]);
                setSkip(limit);
            }
                

        } catch (error: any) {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        }
    }

    const formatDateTime = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'long' }) + ' at ' + valueDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
      };

    const handleDelete = () => {
  
        const deleteTrip = () => {
            return new Promise((resolve, reject) => {
                api.delete(`/staffAttends/${attendanceID}`).then(res => {
                    return resolve(res.data);
                    }).catch(err => reject(err));
    
            });
        }
  
        setShowLoading(true);
        deleteTrip()
        .then(data => {

            let attends = [...attendance];
            let index = attends.findIndex((val) => val._id === attendanceID);
            if (index > -1) {
                attends.splice(index, 1); // 2nd parameter means remove one item only
            }
            setAttendance(attends);

          setPass(t("entry_deleted"));
          setIspass(true);
        })
        .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));
        
    }

    const handleDepartment = (departmentID: string) => {

        setDepartment(departmentID);

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/employees/getByDepartment/${departmentID}`);
                setEmployees(studs.data);
            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        const fetchAll = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/employees/getAllInstitute/${authInfo.user.instituteID}`);
                setEmployees(studs.data);
            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        if (departmentID === 'all')
        {
            fetchAll();
        }
        else
        {
            fetchUp();
        }

        setSelectedEmployee("");
        setAttendance([]);
        
    }

    const handleEmployee = (employeeID : string) => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/staffAttends/getLogEmployee/${employeeID}`);
                setAttendance(result.data);

                setSkip(0);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        fetchUp();
        setSelectedEmployee(employeeID);
    }


    useEffect(() => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const res = await api.get(`/departments/getAllInstitute/${authInfo.user.instituteID}`);
                setDepartments(res.data);
                    
                if (employeeID)
                {
                    const stud = await api.get(`/employees/${employeeID}`);  
                    setDepartment(stud.data.departmentInfo.departmentID);

                    const studs = await api.get(`/employees/getByDepartment/${stud.data.departmentInfo.departmentID}`);
                    setEmployees(studs.data);
                    setSelectedEmployee(employeeID);

                    const result = await api.get(`/staffAttends/getLogEmployee/${employeeID}`);
                    setAttendance(result.data);
                }

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo, employeeID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/staff`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("entry_exit_logs")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("entry_exit_logs")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(true)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    header={t("confirm")}
                    message={t("delete_entry")}
                    buttons={[
                        {
                        text: t("no"),
                        role: 'cancel',
                        cssClass: 'secondary',
                        },
                        {
                        text: t("yes"),
                        handler: () => {
                            handleDelete();
                        }
                        }
                    ]}
                />
                        
                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding max-xl">
                    <IonRow>
                        <IonCol size="6">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("select_department")}</IonLabel>
                                            <IonSelect value={department}
                                                    className="input-field"
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("select_department")}
                                                    onIonChange={
                                                        (e) => handleDepartment(e.detail.value)
                                                }>
                                                     <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                    {departments.map((dep) => (
                                                        (<IonSelectOption key={dep._id} value={`${dep._id}`}>{dep.name}</IonSelectOption>)
                                                    ))}
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>

                        <IonCol size="6">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">                   
                                    
                                    <IonItem
                                    detail={false}
                                    lines='full'
                                    button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">{t("select_emp")}</IonLabel>
                                        <IonSelect value={selectedEmployee}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    className="input-field text-capitalize"
                                                    placeholder={t("select_emp")}
                                                    onIonChange={
                                                        (e) => e.detail.value && handleEmployee(e.detail.value)
                                                }>
                                                    {employees.map((employee) => (
                                                        (employee.isActive) &&
                                                        (<IonSelectOption key={employee._id} value={employee._id} className='text-capitalize'>{employee.userInfo.name}</IonSelectOption>)
                                                    ))}
                                                 
                                        </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <IonGrid className="ion-no-padding max-xl">
                    
                    <IonRow className="mt-30">
                        <IonCol>
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                    {t("employees_log")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    {(attendance.length > 0) && (
                    <IonRow className="ion-margin-vertical">
                        <IonCol>
                          <IonList className="ion-no-padding">
                             {(attendance.map((attend, i) => {
                                    return (
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            key={attend._id}
                                            className={((i%2) === 0) ? '' : 'alt-item'}
                                           >
                                            <IonIcon slot="start"
                                                icon={time}
                                                color={
                                                    (attend.status === 'in')  ? 'success' : 'danger'
                                                }/>
                                                <IonLabel className="list-label text-capitalize ion-text-wrap">
                                                    <h3>
                                                    {(attend.status === 'out') && (
                                                        <p>{t("exit_at")} {formatDateTime(attend.createdAt)}</p>
                                                    )}
                                                    {(attend.status === 'in') && (
                                                        <p>{t("entry_at")} {formatDateTime(attend.createdAt)}</p>
                                                    )}
                                                  </h3>
                                                </IonLabel>
                                                
                                                <IonButton slot="end"
                                                    onClick={() => {
                                                        setAttendanceID(attend._id);
                                                        setShowAlert(true);
                                                    }}
                                                    >
                                                    <IonIcon
                                                     icon={trash}  />
                                                </IonButton>
                                        </IonItem>
                                    )
                                }))
                                } 
                            </IonList>
                        </IonCol>
                    </IonRow>
                    )}
                     {(attendance.length === 0) && ( 
                            <IonRow>
                                <IonCol>
                                    <IonCard className='red-card'>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_records")}</h3></IonLabel>
    
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )} 

                        {(attendance.length > 0) &&
                        <IonRow>
                            <IonCol>
                            <IonInfiniteScroll
                                onIonInfinite={async (ev) => {
                                    await loadItems();
                                    ev.target.complete();
                                }}
                            >
                                <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                            </IonCol>
                        </IonRow>
                            }
                        
                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default EmployeeLog;
