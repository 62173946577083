import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import { arrowBack, attach, trash } from 'ionicons/icons';
import axios from 'axios';
import { Capacitor } from '@capacitor/core';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { SendIntent } from "send-intent";
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';

interface ParamTypes {
    uri?: string
}

const AddBill: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {uri} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [iserror1, setIserror1] = useState < boolean > (false);
    const [message1, setMessage1] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [amount, setAmount] = useState < number > ();
    const [remarks, setRemarks] = useState < string > ("");
    const [mode, setMode] = useState < string > ("");
    const [selectedDate, setSelectedDate] = useState < string | string[] > (new Date().toISOString());
    const [tags, setTags] = useState < string[] > ([]);
    const [uploadFile, setUploadFile] = useState < File | null > (null);
    const [imageURL, setImageURL] = useState < string > ("");
    const [fileExt, setFileExt] = useState < string > ("");

    const api = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      timeout: parseInt(process.env.REACT_APP_API_TO!),
      headers: {
          'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const convertFileSrc = (path: string) => {
        return Capacitor.convertFileSrc(path);
    };

    const fileToDataUri = (file: File) => new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event: any) => {
            resolve(event.target.result);
        };
        reader.readAsDataURL(file);
    });

    const pickFile = async () => {
        try {

           const result = await FilePicker.pickFiles({limit: 1});
           if (result.files.length === 1)
           {
               const file = result.files[0];
               //for native
               if (file && file.path && file.name && file.mimeType) {
                   const webPath = convertFileSrc(file.path);
                   if ((file.mimeType === 'image/jpeg') || (file.mimeType === 'image/png'))
                   {
                       setImageURL(webPath);
                   }
                   else
                    {
                        setImageURL("");
                    }
                   const blob = await fetch(webPath).then((r) => r.blob());
                   const rawFile = new File([blob], file.name, {
                       type: file.mimeType,
                   });
                   setUploadFile(rawFile);
               }
               //for web
               else if (file && file.blob && file.name && file.mimeType) {
                   const rawFile = new File([file.blob], file.name, {
                       type: file.mimeType,
                   });
                   setUploadFile(rawFile);
                   if ((file.mimeType === 'image/jpeg') || (file.mimeType === 'image/png'))
                   {
                       const base64 = await fileToDataUri(rawFile);
                       setImageURL(base64);
                   }
                   else
                    {
                        setImageURL("");
                    }
               }
           }
       } 
       catch(error: any) {

           setMessage(t("no_files_selected"));  
           setIserror(true);
       }
   };

    const formatDate = (value: string | string[]) => {
        if (typeof(value) === "string")
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
    };

    const handleTags = (value : string) => {
        let inputTags = value.toLowerCase();
        let strArray: Array < string > = [];
        strArray = inputTags.split(" ");
        setTags(strArray);
    };

    const handleSubmit = () => {

        if (!mode || mode === "") {
            setMessage(t("tran_mode_mandatory"));
            setIserror(true);
            return;
        }

        if (!remarks || remarks === "") {
            setMessage(t("tran_remarks_mandatory"));
            setIserror(true);
            return;
        }

        if (!amount || amount === 0) {
            setMessage(t("tran_amount_mandatory"));
            setIserror(true);
            return;
        }

        if(!(typeof(selectedDate) === "string"))
        {
            setMessage(t("tran_date_mandatory"));
            setIserror(true);
            return;
        }

        const uploadBill = ( formData: FormData) => {
            return new Promise((resolve, reject) => {
              api.post('/transactions/addBill', formData).then(res => {
                return resolve(res.data.transaction.remarks);
              }).catch(err => reject(err));
    
            });
        }

        const addExpenseTran = (dated: string | string[]) => {
            return new Promise((resolve, reject) => {
                api.post('/transactions/addExpense', { 'instituteID': authInfo.user.instituteID, amount, remarks, mode, dated, 'adminID': authInfo.user._id, tags}).then(res => {
                    return resolve(res.data.transaction.remarks);
                }).catch(err => reject(err));
            });
        }

        if (uploadFile)
        {
            let formData = new FormData();
            formData.append('bill', uploadFile, uploadFile.name);
            formData.append('instituteID', authInfo.user.instituteID);
            formData.append('remarks', remarks);
            formData.append('mode', mode);
            formData.append('amount', amount.toString());
            formData.append('dated', selectedDate);
            formData.append('tags', JSON.stringify(tags));
            formData.append('adminID', authInfo.user._id);

            setShowLoading(true);
            uploadBill(formData)
            .then(data => {

                setPass(data+t("has_been_uploaded"));
                setIspass(true);
            })
            .catch(error => {
                if (uri)
                {
                    if (error.response !== undefined)
                        setMessage1(error.response.data.message);
                    else
                        setMessage1(t("invalid_bill"));
                        
                    setIserror1(true);
                }
                else
                {
                    if (error.response !== undefined)
                        setMessage(error.response.data.message);
                    else
                        setMessage(t("something_went_wrong"));

                    setIserror(true);
                }
            })
            .finally(() => setShowLoading(false));
        }
        else
        {
            setShowLoading(true);
            addExpenseTran(selectedDate)
            .then(data => {
            
                setPass(t("expense_added"));
                setIspass(true);
            })
            .catch(error => {
                if (error.response !== undefined)
                    setMessage(error.response.data.message);
                else
                    setMessage(t("something_went_wrong"));
        
                setIserror(true);
            })
            .finally(() => setShowLoading(false));
        }
    }

    useEffect(() => {

        const getMimeType = (ext: string) => {
            let mime = "";
            if (ext === 'jpg')
                mime = 'image/jpeg';
            if (ext === 'jpeg')
                mime = 'image/jpeg';
            if (ext === 'png')
                mime = 'image/png';
            if (ext === 'pdf')
                mime = 'application/pdf';
            return mime;
        };

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                if (uri)
                {
                    const sharedURI = decodeURIComponent(uri);
                    const myArray = sharedURI.split(".");
                    const len = myArray.length;
                    const ext = myArray[len-1];
                    const name = myArray[len-2]+'.'+ext;
                    const mimeType = getMimeType(ext);

                    setFileExt(ext);

                    if (mimeType === "")
                    {
                        setMessage1(t("bill_format"));
                        setIserror1(true);
                    }
                    else
                    {
                        const webPath = convertFileSrc(sharedURI);
                        const blob = await fetch(webPath).then((r) => r.blob());

                        /*
                        const contents = await Filesystem.readFile({
                            path: sharedURI
                          });

                        const blob = b64toBlob(contents.data, mimeType);
                        */
                        
                        const sharedFile = new File( [blob], name, { type: mimeType } );
                        setUploadFile(sharedFile);

                        if (ext !== 'pdf')
                        {
                            setImageURL(webPath);
                        }

                    }
                }
                setShowLoading(false);

            } catch (error : any) {

                setMessage1(t("invalid_bill"));
                setIserror1(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [uri]);
    
    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonIcon className='pointer table-icon ml-05' icon={arrowBack} color="secondary" onClick={
                            () => uri ? SendIntent.finish() : history.push(`/app/accounting`)
                        } />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("add_bill")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("add_bill")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                
                 <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => uri ? SendIntent.finish() : history.push(`/app/accounting`)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={iserror1}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => SendIntent.finish()
                    }
                    header={t("error")}
                    message={message1}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}
                
                <IonGrid className="ion-no-padding mb-60">

                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {t("bill_details")}
                            </IonTitle>
                        </IonCol>
                    </IonRow>

                    {uri && (
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard className="ion-text-center">
                                <IonCardHeader>
                                    <IonCardSubtitle>{t("bill_preview")}</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    {(imageURL) &&
                                        (
                                            <img src={imageURL} 
                                                alt={t("bill_preview")} 
                                                width="320" />
                                       )
                                    }

                                     {(fileExt) && (fileExt === 'pdf') &&
                                        (<div className="pdf-preview">
                                            <div className="vertical-container">
                                                <div className="vertical-center"> 
                                                    <img src="assets/images/pdf-icon.png" alt="PDF" />
                                                </div>
                                            </div>
                                        </div>)
                                    }
                                  
                                </IonCardContent>        
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    )}
                   
                  
                    {!uri && (
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={true}
                                        lines='none'
                                        color="primary"
                                        button={true}
                                        onClick={pickFile}
                                        >
                                        <IonIcon
                                            slot="start"
                                            icon={attach}
                                        />
                                        <IonLabel>
                                        {(uploadFile) ? t("change_file") : t("attach_file")}
                                        </IonLabel>
                                        
                                    </IonItem>
                                    {(uploadFile) && (
                                    <IonItem detail={true}
                                        detailIcon={trash}
                                        lines='none'
                                        button={true}
                                        onClick={
                                            () => {
                                                setUploadFile(null);
                                                setImageURL("");
                                            }
                                        }
                                    >
                                        <IonBadge color="danger" slot="start">1</IonBadge>
                                        <IonLabel>
                                            {t("remove_files")}
                                        </IonLabel>
                                        
                                    </IonItem>
                                    )}
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    )}
                     {!uri && uploadFile && (
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard className="ion-text-center">
                                <IonCardHeader>
                                    <IonCardSubtitle>{t("bill_preview")}</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    {(imageURL) &&
                                        (
                                            <img src={imageURL} 
                                                alt={t("bill_preview")} 
                                                width="320" />
                                       )
                                    }

                                     {(imageURL === "") &&
                                        (<div className="pdf-preview">
                                            <div className="vertical-container">
                                                <div className="vertical-center"> 
                                                    <img src="assets/images/pdf-icon.png" alt="PDF" />
                                                </div>
                                            </div>
                                        </div>)
                                    }
                                  
                                </IonCardContent>        
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    )}
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-date-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("tran_date")}*
                                            </IonLabel>
                                            <IonInput value={formatDate(selectedDate)} className="input-field" readonly={true} />
                                            <IonModal trigger="open-date-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("tran_date")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                        value={selectedDate}
                                                        presentation="date"
                                                        cancelText={t("cancel")}
                                                        doneText={t("done")}
                                                        onIonChange={ev => setSelectedDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("tran_remarks")}*
                                            </IonLabel>
                                            <IonInput value={remarks} 
                                                placeholder={t("tran_remarks")}
                                                type="text"
                                                className="input-field" 
                                                onIonInput={ev => setRemarks(ev.detail.value!)}
                                            />
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("tran_mode")}*
                                            </IonLabel>
                                            <IonSelect placeholder={t("tran_mode")}
                                                className='input-field' 
                                                style={{'maxWidth': '100%'}} 
                                                cancelText={t("cancel")}
                                                okText={t("ok")}
                                                value={mode}
                                                onIonChange={
                                                    (e) => setMode(e.detail.value!)
                                                }
                                                >
                                                <IonSelectOption value="cash">{t("cash")}</IonSelectOption>
                                                <IonSelectOption value="cheque">{t("cheque")}</IonSelectOption>      
                                                <IonSelectOption value="online">{t("online")}</IonSelectOption>   
                                                
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("tran_amount")}*
                                            </IonLabel>
                                        <IonInput type="number"
                                            value={amount}
                                            placeholder={t("tran_amount")}
                                            className="input-field" 
                                            onIonInput={
                                                (e) => setAmount(parseInt(e.detail.value!))
                                        }></IonInput>
                                    </IonItem>
                                    
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("tran_tags")}
                                            </IonLabel>
                                        <IonInput type="text"
                                            placeholder={t("tran_tags")}
                                            className="input-field" 
                                            value={tags.join(' ')}
                                            onIonInput={
                                                (e) => handleTags(e.detail.value!)
                                        }></IonInput>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12" className="ion-padding-horizontal">
                            <IonText className='info-text'>
                                {t("tags_info")}<br/><br/>
                                {t("sample_tags")}
                            </IonText>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>

            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("add_bill")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>

        </IonPage>
    );
};

export default AddBill;