import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import { alertCircleOutline, medical } from 'ionicons/icons';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';

interface ParamTypes {
    studentID?: string
}

interface Session {
    _id: string,
    name: string,
    isActive: boolean
}

interface ClassInterface {
    _id: string,
    name: string,
    year: string,
    isActive: boolean
}

interface Student {
    _id: string,
    name: string,
    dob: Date,
    gender?: string,
    bloodType?: string,
    isDisabled?: boolean,
    disabilityInfo?: string,
    medicalInfo?: string,
    isActive: boolean
}

const AddIncident: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {studentID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [session, setSession] = useState < string > ("");
    const [classes, setClasses] = useState < ClassInterface[] > ([]);
    const [classi, setClassi] = useState < string > ("");
    const [students, setStudents] = useState < Student[] > ([]);
    const [selectedStudent, setSelectedStudent] = useState <Student> ();
    const [type, setType] = useState <string> ("");
    const [description, setDescription] = useState <string> ("");
    const [treatment, setTreatment] = useState <string> ("");

    const api = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      timeout: parseInt(process.env.REACT_APP_API_TO!),
      headers: {
          'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const formatDated = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB');
    };

    const handleSubmit = () => {

        if(!selectedStudent)
        {
            setMessage(t("student_mandatory"));
            setIserror(true);
            return;
        }

        if(!type || type === "")
        {
            setMessage(t("incident_type_mandatory"));
            setIserror(true);
            return;
        }

        if(!description || description === "")
        {
            setMessage(t("incident_desc_mandatory"));
            setIserror(true);
            return;
        }

        if(!treatment || treatment === "")
        {
            setMessage(t("incident_treat_mandatory"));
            setIserror(true);
            return;
        }
  
        const addIncident = ( studentID: string, studentName: string ) => {
          return new Promise((resolve, reject) => {
            api.post(`incidents/add`, { 'instituteID': authInfo.user.instituteID, studentID, studentName, type, description, treatment }).then(res => {
              return resolve(res.data.incident.type); 
            }).catch(err => reject(err));
          });
        }
  
        setShowLoading(true);
        addIncident(selectedStudent._id, selectedStudent.name)
        .then(data => {
          
            setPass(data+t("has_been_added"));
            setIspass(true);
        })
        .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));
    }

      const handleSession = (sessionName : string) => {

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${sessionName}`);
                setClasses(result.data);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();
        setSession(sessionName);
        setClassi("");
        setSelectedStudent(undefined);
        setStudents([]);
    }

    const handleClassi = (classID : string) => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/students/getByClass/${classID}`);
                setStudents(studs.data);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        fetchUp();
        setClassi(classID);
        setSelectedStudent(undefined);
    }

    const handleStudent = (studentID : string) => {

        const stud = students.find(val => val._id === studentID);
        setSelectedStudent(stud);
        
    }

    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const res = await api.get(`/sessions/getAllInstitute/${authInfo.user.instituteID}`);
                setSessions(res.data);

                if (studentID)
                {
                    const stud = await api.get(`/students/${studentID}`);

                    const sessionID = res.data.find((val: Session) => val.name === stud.data.classInfo.session)?._id;
                    setSession(sessionID);

                    const query = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${sessionID}`);
                    setClasses(query.data);
                    setClassi(stud.data.classID);

                    const studs = await api.get(`/students/getByClass/${stud.data.classID}`);
                    setStudents(studs.data);
                    setSelectedStudent(stud.data);
                }
                else
                {
                    setSession("");
                    setClasses([]);
                    setClassi("");
                    setStudents([]);
                    setSelectedStudent(undefined);
                }

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchData();

    }, [authInfo, studentID]);
      
    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/essentials`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("record_an_incident")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("record_an_incident")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                
                 <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => history.push(`/app/essentials`)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}
                
                <IonGrid className="ion-no-padding">
                    <IonRow>
                        <IonCol sizeLg="6" size="12" className="ion-padding-horizontal">
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("select_student")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol>
                                    <IonRow>
                                        <IonCol size="6">
                                            <IonCard>
                                                <IonCardContent className="ion-no-padding">
                                                    <IonItem>
                                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                            {t("class_session")}</IonLabel>
                                                            <IonSelect value={session}
                                                                    className="input-field"
                                                                    cancelText={t("cancel")}
                                                                    okText={t("ok")}
                                                                    placeholder={t("class_session")}
                                                                    onIonChange={
                                                                        (e) => handleSession(e.detail.value)
                                                                }>

                                                                    {sessions.map((session) => 
                                                                        (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                                    )}

                                                            </IonSelect>
                                                        </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        <IonCol size="6">
                                            <IonCard>
                                                <IonCardContent className="ion-no-padding">
                                                    <IonItem>
                                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}</IonLabel>
                                                            <IonSelect value={classi}
                                                                    className="input-field"
                                                                    cancelText={t("cancel")}
                                                                    okText={t("ok")}
                                                                    placeholder={(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}
                                                                    onIonChange={
                                                                        (e) => handleClassi(e.detail.value)
                                                                }>
                                                                    
                                                                    {classes.map((classy) => (
                                                                        (<IonSelectOption key={classy._id} value={`${classy._id}`}>{classy.name}</IonSelectOption>)
                                                                    ))}
                                                            </IonSelect>
                                                        </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>

                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">                   
                                            
                                            <IonItem
                                            detail={false}
                                            lines='full'
                                            button={false}>
                                                <IonLabel position="stacked" className="input-label" color="secondary">{t("select_student")}</IonLabel>
                                                    <IonSelect value={selectedStudent && selectedStudent._id}
                                                        className="input-field"
                                                        cancelText={t("cancel")}
                                                            okText={t("ok")}
                                                            placeholder={t("select_student")}
                                                            onIonChange={
                                                                (e) => e.detail.value && handleStudent(e.detail.value)
                                                        }>
                                                        { students.map((student) => {
                                                                
                                                            return (<IonSelectOption key={student._id} value={`${student._id}`}>{student.name}</IonSelectOption>);
                                                                
                                                            })
                                                        }
                                                </IonSelect>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("medical_details")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            {selectedStudent ? (
                            <IonRow className="ion-margin-top">
                                <IonCol>
                                    <IonCard className='medical-card'>
                                        <IonCardHeader>
                                            <IonCardTitle className='mt-10'>
                                                {t("student_data")}
                                                <IonIcon 
                                                className='ion-float-right'
                                                color="danger"
                                                size="large"
                                                icon={medical}/>
                                            </IonCardTitle>
                                            
                                        </IonCardHeader>
                                        <IonCardContent className="ion-no-padding">
                                            <IonCardSubtitle className='ion-padding-horizontal border-bottom-sec text-bold'>{t("basic_info")}</IonCardSubtitle>
                                            <IonRow className="ion-padding text-dark">
                                                <IonCol size="5" className='text-bold'>
                                                    {t("student_name")}:<br/>
                                                    {t("student_dob")}:<br/>
                                                    {t("student_blood")}:
                                                </IonCol>
                                                <IonCol size="7" className='text-capitalize'>
                                                    {selectedStudent.name}<br/>
                                                    {formatDated(selectedStudent.dob)}<br/>
                                                    {selectedStudent.bloodType}
                                                </IonCol>
                                            </IonRow>
                                            {(selectedStudent.isDisabled) && (
                                            <>
                                                <IonCardSubtitle className='ion-padding-horizontal border-bottom-sec text-bold'>{t("disability_info")}</IonCardSubtitle>
                                                <IonRow className="ion-padding text-dark">
                                                    <IonCol>
                                                        {selectedStudent.disabilityInfo}
                                                    </IonCol>
                                                </IonRow>
                                            </>
                                            )}

                                                <IonCardSubtitle className='ion-padding-horizontal border-bottom-sec text-bold'>{t("medical_condition")}</IonCardSubtitle>
                                                <IonRow className="ion-padding text-dark">
                                                    <IonCol>
                                                        {selectedStudent.medicalInfo && (selectedStudent.medicalInfo !== "") ? selectedStudent.medicalInfo : t("no_medical_info")}
                                                    </IonCol>
                                                </IonRow>

                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            ) : (
                            <IonRow className="ion-margin-top">
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_medical")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )}
                        </IonCol>
                        <IonCol sizeLg="6" size="12" className="ion-padding-horizontal">
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("incident_details")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("incident_type")}*
                                                </IonLabel>
                                                    <IonSelect value={type}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                        className="input-field" 
                                                        placeholder={t("incident_type")}
                                                        onIonChange={
                                                            (e) => setType(e.detail.value)
                                                    }>
                                                    
                                                        <IonSelectOption value="injury">{t("injury")}</IonSelectOption>
                                                        <IonSelectOption value="illness">{t("illness")}</IonSelectOption>      
                                                        
                                                    </IonSelect>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                lines='full'
                                                button={false}>
                                                <IonLabel position="floating" color="secondary">{t("incident_desc")}*</IonLabel>
                                                <IonTextarea rows={8} value={description} onIonInput={
                                                        (e) => setDescription(e.detail.value!)
                                                }></IonTextarea>
                                            
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                lines='full'
                                                button={false}>
                                                <IonLabel position="floating" color="secondary">{t("incident_treat")}*</IonLabel>
                                                <IonTextarea rows={8} value={treatment} onIonInput={
                                                        (e) => setTreatment(e.detail.value!)
                                                }></IonTextarea>
                                            
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("record_an_incident")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default AddIncident;