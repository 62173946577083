import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSkeletonText,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import QRCode from 'react-qr-code';
import { useTranslation } from "react-i18next";
import { alertCircleOutline } from 'ionicons/icons';
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';

interface ParamTypes {
    classID: string
}

interface Student {
    _id: string,
    name: string,
    parentInfo?: ParentInfo,
    isActive: boolean
}

interface ParentInfo {
    name: string,
    phone: string,
    email: string
}

const ClassIdCards: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const {classID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [students, setStudents] = useState < Student[] > ([]);
   
    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/classes/' + classID);

                setName(result.data.name);

                const stud = await api.get(`/students/getByClass/${classID}`);
                setStudents(stud.data);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, classID]);


    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary" />
                        <IonBackButton color="secondary" defaultHref={`/app/classes`} />
                    </IonButtons>
                   
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary"> 
                        {(name !== "") ? (name) : 
                            ((<IonSkeletonText animated={true} style={{ 'width': '140px', 'marginLeft': '20px' }}></IonSkeletonText>))
                        }
                    </IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{
                        (name !== "") ? (name) : 
                        ((<IonSkeletonText animated={true} style={{ 'width': '140px', 'marginLeft': '20px' }}></IonSkeletonText>))
                        }</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className="ion-no-padding">

                    <IonRow className="ion-margin-vertical">
                        {(students.length > 0)  ? (students.map((student, i) => 
                            (student.isActive) &&
                            (
                                <IonCol sizeLg='3' sizeMd='4' sizeSm='6' size="12">
                                    <IonCard className='receipt-shade'>
                                        <IonCardContent>
                                            <IonRow>
                                                <IonCol className="ion-text-center">
                                                    <QRCode value={`https://prathmic.com/app/qr/student/${student._id}`} size={128} />
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className="mt-60">
                                                <IonCol>
                                                    <IonText className="h2">{t("name")}: {student.name}</IonText><br/>
                                                    <IonText className="h2">{t("class")}: {name}</IonText><br/>
                                                    {student.parentInfo && (<IonText className="h2">{t("phone")}: {student.parentInfo.phone}</IonText>)}
                                                </IonCol>
                                            </IonRow> 

                                        </IonCardContent>
                                    </IonCard> 
                                </IonCol>   
                             )
                            )) : (
                                <IonCol>
                                    <IonCard className='red-card'>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger"
                                                    size="large" />
                                                <IonLabel className="list-title"><h3>{t("no_students")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            )
                        }
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default ClassIdCards;
