import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, receipt } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';
import usePageUnloadHandler from '../../../components/PageUnloadHandler';

interface Invoice {
    _id: string,
    amount: number,
    student: Stud,
    invoiceDate: Date,
    dueDate: Date,
    paidAt: Date
}

interface Stud {
    studentID: string,
    name: string
}

const ScheduledInvoices: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [credits, setCredits] = useState < Invoice[] > ([]);
    const [total, setTotal] = useState < number > (0);
    const [sum, setSum] = useState < number > (0);
    const [skip, setSkip] = useState < number > (0);
    const [scrollPosition, setScrollPosition] = useState < number > (0);
    const scrollContent = useRef<HTMLIonContentElement | null> (null);
    const [initialRender, setInitialRender] = useState < boolean > (true);

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });
   
    const handleScrollEnd = async () => {
        const elem = await scrollContent.current?.getScrollElement();
        if(elem)
        {
            setScrollPosition(elem.scrollTop);
        }
    };

    const formatDated = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    const loadItems = async () => {

        let limit = skip + 30;

        try {
            const result = await api.get(`/invoices/getScheduled/${authInfo.user.instituteID}?skip=${limit}`);

            if(result.data.invoices && result.data.invoices.length)
            {
                setCredits([...credits, ...result.data.invoices]);
                setSkip(limit);
            }

        } catch (error: any) {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        }
    }

    useEffect(() => {

        const storedState = sessionStorage.getItem(history.location.pathname);
        if (history.action === "POP" && storedState) {
            const { credits, total, sum, skip, scrollPosition } = JSON.parse(storedState);
            // Data is present in local storage, use it to initialize state
            setCredits(credits);
            setTotal(total);
            setSum(sum);
            setSkip(skip);
            
            setTimeout(() => scrollContent.current?.scrollToPoint(0, scrollPosition, 500), 500);
        } 
        else
        {

            const fetchData = async () => {
                setShowLoading(true);
                try {

                    const res = await api.get(`/invoices/getScheduled/${authInfo.user.instituteID}`);
                    setCredits(res.data.invoices);
                    setTotal(res.data.total);
                    setSum(res.data.sum);
                    setSkip(0);

                } catch (error: any) {
                    setMessage(error.response?.data.message || t("something_went_wrong"));
                    setIserror(true);
                } finally {
                    setShowLoading(false);
                }
            };

            fetchData();
        }
        
        setInitialRender(false);

    }, [authInfo.user.instituteID, history.action, history.location.pathname]);

    // Save state to local storage whenever it changes
    useEffect(() => {
        if (!initialRender) 
        {
            const currentState = {
            credits,
            total,
            sum,
            skip,
            scrollPosition
            };
            sessionStorage.setItem(history.location.pathname, JSON.stringify(currentState));
        }
    }, [credits, total, sum, skip, scrollPosition, history.location, initialRender]);

    // Effect to handle page reload 
    usePageUnloadHandler();

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/accounting`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("scheduled_invoices")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade" ref={scrollContent} scrollEvents={true} onIonScrollEnd={handleScrollEnd}>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("scheduled_invoices")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                    
                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}
                
                <IonGrid className="ion-no-padding mb-60">
                    <div className="ion-table">
                                <IonRow className="table-title ion-padding">

                                    <IonCol size="1" className='table-heading'>
                                    {t("s_no")}
                                    </IonCol>
                                    <IonCol sizeLg="2" size="3.5" className='table-heading'>
                                    {t("scheduled_on")}
                                    </IonCol>
                                    <IonCol sizeLg="2" size="4" className='table-heading'>
                                    {t("student")}
                                    </IonCol>
                                    <IonCol sizeLg="3.5" size="0" className='table-heading ion-hide-lg-down'>
                                    {t("due_on")}
                                    </IonCol>
                                    <IonCol size="2.5" className='table-heading ion-text-right'>
                                    {t("amount")}
                                    </IonCol>
                                    <IonCol size="1" className='ion-text-right'>
                                        <IonIcon 
                                            icon={receipt}
                                            color="light"
                                            className='table-icon'
                                            />
                                    </IonCol>
                                </IonRow>
                               

                                {(credits.length > 0) ? (credits.map((credit, i) => {
                                    return (
                                        <IonRow key={credit._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}>
                                            
                                                <IonCol size="1" className='table-field action-button' >
                                                    {i+1}.
                                                </IonCol>
                                                <IonCol sizeLg="2" size="3.5" className='table-field'>
                                                    {formatDated(credit.invoiceDate)}
                                                </IonCol>
                                                <IonCol sizeLg="2" size="4" className='table-field text-capitalize'>
                                                    <Link to={`/app/student/ledger/${credit.student.studentID}`} className='link-dark'>{credit.student.name}</Link>
                                                </IonCol>
                                                <IonCol sizeLg="3.5" size="0" className='table-field ion-hide-lg-down'>
                                                    {formatDated(credit.dueDate)}
                                                </IonCol>
                                                <IonCol size="2.5" className='table-field ion-text-right'>
                                                    {credit.amount}
                                                </IonCol>
                                                <IonCol size="1" className='ion-text-right'>
                                                <IonIcon 
                                                        icon={receipt}
                                                        color="dark"
                                                        className='table-icon mt-10'
                                                        onClick={
                                                            () => history.push(`/app/invoice/${credit._id}`)
                                                        }
                                                        />
        
                                                </IonCol>
                                             
                                        </IonRow>
                                    )
                                })) : ( 
                                <IonRow>
                                    <IonCol>
                                        <IonCard>
                                            <IonCardContent>
                                                <IonItem lines="none">
                                                    <IonIcon icon={alertCircleOutline}
                                                        slot="start"
                                                        color="danger" />
                                                    <IonLabel className="list-title"><h3>{t("no_invoices")}</h3></IonLabel>

                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                )
                                } 
                                </div>

                    {(credits.length > 0) &&
                    <IonRow>
                        <IonCol>
                            <IonInfiniteScroll
                                onIonInfinite={async (ev) => {
                                    await loadItems();
                                    ev.target.complete();
                                }}
                            >
                                <IonInfiniteScrollContent></IonInfiniteScrollContent>
                            </IonInfiniteScroll>
                        </IonCol>
                   </IonRow>
                    }
                   
                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                <IonCol size="6" className="ion-padding-start footer-stats">
                                {t("showing")} {total === 0 ? t("no_records") : `1 - ${credits.length} ${t("of")} ${total}`} 
                                </IonCol>
                                <IonCol size="6" className="ion-padding-start footer-stats">
                                {t("total_amount")} {Math.abs(sum)}
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-vertical overflow-x">
                                <IonCol className='min-md'>
                                    <IonButton 
                                        onClick={
                                            () => history.push(`/app/dues/view`)
                                            
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("view_dues")}</IonButton>

                                    <IonButton 
                                        onClick={
                                            () => history.push(`/app/collection`)
                                            
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("collect_fees")}</IonButton>

                                    <IonButton 
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("export_excel")}</IonButton>


                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default ScheduledInvoices;