import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonTitle,
    IonToggle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';

interface ParamTypes {
    studentID: string
}

const StudentProfile: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const {studentID} = useParams < ParamTypes > ();
    const [accountID, setAccountID] = useState < string > ("");
    const [active, setActive] = useState < string > ("");
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [email, setEmail] = useState < string > ("");
    const [additional, setAdditional] = useState < boolean > (false);
    const [address, setAddress] = useState < string > ("");
    const [selectedDate, setSelectedDate] = useState < string | string[] > ('');
    const [studEmail, setStudEmail] = useState < string > ("");
    const [studNewPass, setStudNewPass] = useState < string > ("");
    const [studConfirmPass, setStudConfirmPass] = useState < string > ("");
    const [houses, setHouses] = useState < string[] > ([]);
    const [house, setHouse] = useState < string > ("");
    const [blood, setBlood] = useState < string > ("");
    const [gender, setGender] = useState < string > ("");
    const [category, setCategory] = useState < string > ("general");
    const [disability, setDisability] = useState < boolean > (false);
    const [disableInfo, setDisableInfo] = useState < string > ("");
    const [medicalInfo, setMedicalInfo] = useState < string > ("");
    const [admnNo, setAdmnNo] = useState < string > ("");
    const [enrollNo, setEnrollNo] = useState < string > ("");
    const [readmit, setReadmit] = useState < boolean > (false);
    const [admnDate, setAdmnDate] = useState < string | string[] | undefined > ();
    
    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const formatDate = (value: string | string[] | undefined) => {
        if (typeof(value) === "string")
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
        else
        {
            return t("pick_date");
        }
    };

    const validateEmail = (email : string) => { 
        // eslint-disable-next-line no-control-regex
        const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
        return re.test(String(email).toLowerCase());
    };

    const handleReset = () => {
  
        const upUser = () => {
            return new Promise((resolve, reject) => {
              api.get('/users/reset/'+accountID).then(res => {
                return resolve(res.data.name);
              }).catch(err => reject(err));
            });
        }

        setShowLoading(true);
        upUser()
        .then(data => {

            setPass(data+t("password_reset"));
            setIspass(true);
        
        })
        .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));
        
      }

    const handleSection = (section: string) => {

        if (readmit)
        {
            setMessage(t("readmit_error"));
            setIserror(true);
        }
        else
        {

            const toggleStudent = (isActive: string) => {
                return new Promise((resolve, reject) => {
                    api.put(`/students/${studentID}`, { 'isActive': section }).then(res => {
                        if(accountID !== "")
                        {
                            api.put('/users/'+accountID, { 'isActive': section }).then(resu => {
                                return resolve(resu.data.name);
                            }).catch(err => reject(err));   
                        }
                        else
                        {
                            return resolve(res.data.name);
                        }
                    }).catch(err => reject(err));
                });
            }

            if ((active !== "") && (section !== active))
            {
                setShowLoading(true);

                toggleStudent(section)
                .then(data => {
                        
                    setActive(section);
            
                    setPass(data+t("has_been_updated"));
                    setIspass(true);
                })
                .catch((error) => {
                    setMessage(error.response?.data.message || t("something_went_wrong"));
                    setIserror(true);
                })
                .finally(() => setShowLoading(false));
            }
        }
    }

    const handleSubmit = () => {

        if (!name || name === "") {
            setMessage(t("name_mandatory"));
            setIserror(true);
            return;
        }

        if (!address || address === "") {
            setMessage(t("address_mandatory"));
            setIserror(true);
            return;
        }

        if (!gender || gender === "") {
            setMessage(t("gender_mandatory"));
            setIserror(true);
            return;
        }

        if (!category || category === "") {
            setMessage(t("category_mandatory"));
            setIserror(true);
            return;
        }

        if(disability)
        {
            if (!disableInfo || disableInfo === "") {
                setMessage(t("disability_mandatory"));
                setIserror(true);
                return;
            }
        }

        if (additional)
        {
            if (validateEmail(studEmail) === false) {
                setMessage(t("invalid_student_email"));
                setIserror(true);
                return;
            }
    
            if (studNewPass !== studConfirmPass)
            {
                setMessage(t("password_mismatch"));
                setIserror(true);
                setShowLoading(false);
                return;
            }
    
            if (!studNewPass || studNewPass.length < 8) {
                setMessage(t("invalid_password"));
                setIserror(true);
                setShowLoading(false);
                return;
            }
        }

        if (accountID !== "")
        {
            if (validateEmail(email) === false) {
                setMessage(t("invalid_student_email"));
                setIserror(true);
                return;
            }
        }
  
        const upStudent = (dob: string | string[]) => {
            return new Promise((resolve, reject) => {
                api.put('/students/'+studentID, { name, address, dob, house, gender, category, admnDate, admnNo, enrollNo, blood, disability, disableInfo, medicalInfo }).then(res => {
                    if(accountID !== "")
                    {
                        api.put('/users/'+accountID, { email }).then(resu => {
                            return resolve(resu.data.name);
                        }).catch(err => reject(err));  
                    }
                    else if(additional)
                    {
                        api.post(`/students/register`, { studentID, studEmail, studNewPass }).then(resul => {
                            return resolve(resul.data.student.name);
                        }).catch(err => reject(err));
                    }
                    else
                    {
                        return resolve(res.data.name);
                    }
                }).catch(err => reject(err));
            });
        }

        setShowLoading(true);
        upStudent(selectedDate)
        .then(data => {
  
            setPass(data+t("has_been_updated"));
            setIspass(true);
          
        })
        .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));
        
    }
    
    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/students/' + studentID);
                setName(result.data.name);
                setAddress(result.data.address);
                setSelectedDate(result.data.dob);

                if (result.data.isActive)
                    setActive("enable");
                else
                    setActive("disable");

                if(result.data.accountID)
                {
                    setAccountID(result.data.accountID);
                    const stud = await api.get('/users/' + result.data.accountID);
                    setEmail(stud.data.email);
                }

                const insti = await api.get(`/institutes/${result.data.instituteID}`);

                if(insti.data.houses)
                {
                    setHouses(insti.data.houses);
                }
                if(result.data.admissionAt)
                {
                    setAdmnDate(result.data.admissionAt);
                }
                if(result.data.admnNo)
                {
                    setAdmnNo(result.data.admnNo);
                }
                if(result.data.enrollNo)
                {
                    setEnrollNo(result.data.enrollNo);
                }
                if(result.data.house)
                {
                setHouse(result.data.house);
                }
                if(result.data.gender)
                {
                    setGender(result.data.gender);
                }
                if(result.data.category)
                {
                    setCategory(result.data.category);
                }
                if(result.data.bloodType)
                {
                    setBlood(result.data.bloodType);
                }
                if(result.data.medicalInfo)
                {
                    setMedicalInfo(result.data.medicalInfo);
                }

                setDisability(result.data.isDisabled);
                if(result.data.disabilityInfo)
                {
                    setDisableInfo(result.data.disabilityInfo);
                }

                if (result.data.classID === 'withdrawn' || result.data.classID === 'graduated')
                {
                    setReadmit(true);
                }

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, studentID]);
    
    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("student_profile")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("student_profile")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                
                 <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className="ion-no-padding mb-60">
                    <IonRow>
                        <IonCol size="12" sizeLg="6">
                            <IonRow className="mt-30">
                                <IonCol>
                                    <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                        {t("student_details")}
                                    </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="mt-10">
                                <IonCol>
                                    <IonCard className='border-radius-10 active-group'>
                                        <IonCardContent>
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol size="8">
                                                        
                                                        {t("current_status")}
                                                    
                                                    </IonCol>
                                                    <IonCol size="4" className='ion-text-right ion-padding-horizontal'>
                                                    {(active === 'enable') ? ( <IonBadge color="success" className='ion-padding-horizontal'>{t("active")}</IonBadge>) : 
                                                    ( <IonBadge color="danger" className='ion-padding-horizontal'>{t("inactive")}</IonBadge>)}
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="ion-padding-top ion-padding-horizontal">
                                                    <IonCol>
                                                        <IonSegment value={active} onIonChange={e => handleSection( e.detail.value!.toString() )} mode="ios" className="isactive-segment">
                                                            <IonSegmentButton value="enable">
                                                                <IonLabel>{t("make_active")}</IonLabel>
                                                            </IonSegmentButton>
                                                            <IonSegmentButton value="disable">
                                                                <IonLabel>{t("make_inactive")}</IonLabel>
                                                            </IonSegmentButton>
                                                        </IonSegment>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                        {t("basic_info")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("student_name")}*
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("student_name")}
                                                        value={name}
                                                        onIonInput={
                                                            (e) => setName(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol >
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("student_gender")}*
                                                    </IonLabel>
                                                    <IonSelect value={gender}
                                                        className="input-field"
                                                        placeholder={t("student_gender")}
                                                        cancelText={t("cancel")}
                                                        okText={t("ok")}
                                                        onIonChange={
                                                            (e) => setGender(e.detail.value)
                                                        }>
                                                    
                                                        <IonSelectOption value="male">{t("male")}</IonSelectOption>
                                                        <IonSelectOption value="female">{t("female")}</IonSelectOption> 
                                                        <IonSelectOption value="other">{t("other")}</IonSelectOption> 
                                                    </IonSelect>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol >
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                    lines='full'
                                                    button={false}
                                                    id="open-date-input">
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("student_dob")}*
                                                    </IonLabel>
                                                    <IonInput value={formatDate(selectedDate)} className="input-field" readonly={true} />
                                                    <IonModal trigger="open-date-input" showBackdrop={true} 
                                                        initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                        <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                            <IonToolbar>
                                                                <IonTitle>{t("student_dob")}</IonTitle>
                                                                
                                                            </IonToolbar>
                                                            <IonDatetime
                                                                value={selectedDate}
                                                                presentation="date"
                                                                cancelText={t("cancel")}
                                                                doneText={t("done")}
                                                                onIonChange={ev => setSelectedDate(ev.detail.value!)}
                                                                showDefaultButtons={true}
                                                                size="cover"
                                                            />
                                                        </IonContent>
                                                        
                                                    </IonModal>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol >
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("student_address")}*
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("student_address")}
                                                        value={address}
                                                        onIonInput={
                                                            (e) => setAddress(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol >
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("student_category")}*
                                                    </IonLabel>
                                                    <IonSelect value={category}
                                                        className="input-field"
                                                        placeholder={t("student_category")}
                                                        cancelText={t("cancel")}
                                                        okText={t("ok")}
                                                        onIonChange={
                                                            (e) => setCategory(e.detail.value)
                                                        }>
                                                    
                                                        <IonSelectOption value="general">{t("general")}</IonSelectOption>
                                                        <IonSelectOption value="ews">{t("ews")}</IonSelectOption> 
                                                        
                                                    </IonSelect>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                    lines='full'
                                                    button={false}
                                                    id="open-join-input">
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("admission_date")}
                                                    </IonLabel>
                                                    <IonInput value={formatDate(admnDate)} className="input-field" readonly={true} />
                                                    <IonModal trigger="open-join-input" showBackdrop={true} 
                                                        initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                        <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                            <IonToolbar>
                                                                <IonTitle>{t("admission_date")}</IonTitle>
                                                            </IonToolbar>
                                                            <IonDatetime
                                                                value={admnDate}
                                                                presentation="date"
                                                                cancelText={t("cancel")}
                                                                doneText={t("done")}
                                                                onIonChange={ev => setAdmnDate(ev.detail.value!)}
                                                                showDefaultButtons={true}
                                                                size="cover"
                                                            />
                                                        </IonContent>
                                                    </IonModal>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("student_admn")}
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("student_admn")}
                                                        value={admnNo}
                                                        onIonInput={
                                                            (e) => setAdmnNo(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("enrollment_no")}
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("enrollment_no")}
                                                        value={enrollNo}
                                                        onIonInput={
                                                            (e) => setEnrollNo(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            {(authInfo.institute.type === "school") && (
                            <IonRow>
                                <IonCol >
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                lines='full'
                                                button={false}>
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("student_house")}
                                                </IonLabel>
                                                <IonSelect value={house}
                                                    className="input-field"
                                                    placeholder={t("student_house")}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    onIonChange={
                                                        (e) => setHouse(e.detail.value)
                                                    }>
                                                    { houses.map((house, i) => {
                                                            
                                                        return (<IonSelectOption key={i} value={`${house}`}>{house}</IonSelectOption>);
                                                            
                                                        })
                                                    }
                                                </IonSelect>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow> 
                            )}
                        </IonCol>
                        <IonCol size="12" sizeLg="6">
                            <IonRow className="mt-30">
                                    <IonCol>
                                            <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                                {t("medical_info")}
                                            </IonTitle>
                                    </IonCol>
                                </IonRow>
                                
                                <IonRow className="ion-margin-top">
                                    <IonCol >
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonItem detail={false}
                                                        lines='full'
                                                        button={false}>
                                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                            {t("student_blood")}
                                                        </IonLabel>
                                                        <IonSelect value={blood}
                                                            className="input-field"
                                                            placeholder={t("student_blood")}
                                                            cancelText={t("cancel")}
                                                            okText={t("ok")}
                                                            onIonChange={
                                                                (e) => setBlood(e.detail.value)
                                                            }>
                                                        
                                                            <IonSelectOption value="A+">A+</IonSelectOption>
                                                            <IonSelectOption value="A-">A-</IonSelectOption> 
                                                            <IonSelectOption value="B+">B+</IonSelectOption>
                                                            <IonSelectOption value="B-">B-</IonSelectOption>      
                                                            <IonSelectOption value="O+">O+</IonSelectOption>
                                                            <IonSelectOption value="O-">O-</IonSelectOption>      
                                                            <IonSelectOption value="AB+">AB+</IonSelectOption>
                                                            <IonSelectOption value="AB-">AB-</IonSelectOption>         
                                                            
                                                        </IonSelect>
                                                    </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol >
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonItem detail={false}
                                                    lines='full'
                                                    button={false}
                                                    color="primary"
                                                    >
                                                    <IonLabel className="input-label">{t("student_disabled")}</IonLabel>
                                                    <IonToggle color="light" checked={disability} onIonChange={e => setDisability(e.detail.checked)} />
                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                {disability && (
                                    <IonRow>
                                        <IonCol >
                                            <IonCard>
                                                <IonCardContent className="ion-no-padding">
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}>
                                                        <IonLabel className="input-label" position="floating" color="secondary">
                                                            {t("nature_of_disability")}
                                                        </IonLabel>
                                                        <IonTextarea rows={4} value={disableInfo} onIonInput={
                                                                (e) => setDisableInfo(e.detail.value!)
                                                        }></IonTextarea>
                                                    
                                                    </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                    
                                )}
                                <IonRow>
                                    <IonCol >
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel  className="input-label" position="floating" color="secondary">
                                                        {t("allergies")}
                                                    </IonLabel>
                                                    <IonTextarea rows={4} value={medicalInfo} onIonInput={
                                                            (e) => setMedicalInfo(e.detail.value!)
                                                    }></IonTextarea>
                                                        
                                                    </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                    
                    {/* FOR LMS
                                <IonRow>
                                    <IonCol >
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonItem detail={false}
                                                    lines='full'
                                                    button={false}
                                                    color="tertiary"
                                                    >
                                                    <IonLabel className="input-label" color="secondary">{t("student_account")}</IonLabel>
                                                    <IonToggle color="secondary" checked={additional} onIonChange={e => setAdditional(e.detail.checked)} />
                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                    */}                 
                                {additional && (
                                <>
                                <IonRow className="mt-30">
                                    <IonCol>
                                            <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                                {t("student_lms")}
                                            </IonTitle>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="mt-30">
                                    <IonCol >
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonItem>
                                                    
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("student_email")}
                                                    </IonLabel>
                                                        <IonInput type="text"
                                                            className="input-field"
                                                            placeholder={t("student_email")}
                                                            value={studEmail}
                                                            onIonInput={
                                                                (e) => setStudEmail(e.detail.value!)
                                                        }></IonInput>
                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol >
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonItem>
                                                    
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("set_pass")}
                                                    </IonLabel>
                                                        <IonInput type="text"
                                                            className="input-field"
                                                            placeholder={t("set_pass")}
                                                            value={studNewPass}
                                                            onIonInput={
                                                                (e) => setStudNewPass(e.detail.value !)
                                                        }></IonInput>
                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol >
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonItem>
                                                    
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("confirm_pass")}
                                                    </IonLabel>
                                                        <IonInput type="text"
                                                            className="input-field"
                                                            placeholder={t("confirm_pass")}
                                                            value={studConfirmPass}
                                                            onIonInput={
                                                                (e) => setStudConfirmPass(e.detail.value !)
                                                        }></IonInput>
                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                </>
                                )}


                                {(accountID !== "") && (
                                <>
                                <IonRow className="mt-30">
                                    <IonCol>
                                            <IonTitle className='list-title'>
                                                {t("student_login")}
                                            </IonTitle>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-margin-top">
                                    <IonCol>
                                        <IonList className="ion-no-padding">

                                                <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="fixed" color="secondary">{t("student_email")}</IonLabel>
                                                    <IonInput type="text"
                                                        value={email}
                                                        placeholder={t("student_email")}
                                                        onIonInput={
                                                            (e) => setEmail(e.detail.value!)
                                                    }></IonInput>
                                                </IonItem>  

                                        </IonList>
                                        
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol className="ion-padding-horizontal ion-text-right">
                                        <IonButton onClick={handleReset}
                                            color="dark">{t("reset_password")}</IonButton>

                                    </IonCol>
                                </IonRow>
                                </>
                                )}
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("update_student")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default StudentProfile;
