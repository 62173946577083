import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, construct, personCircle } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';

interface Admin {
    _id: string,
    instituteID: string,
    name: string,
    isActive: boolean
}

interface Institute {
    _id: string,
    name: string,
    isActive: boolean
}

const Admins: React.FC = () => {  
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [admins, setAdmins] = useState < Admin[] > ([]);
    const [institutes, setInstitutes] = useState < Institute[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    useEffect(() => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/institutes/getBySuper/${authInfo.user._id}`);
                setInstitutes(result.data);

                const users = await api.get(`/users/admins/getBySuper/${authInfo.user._id}`);
                setAdmins(users.data);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        fetchUp();

    }, [authInfo]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/home`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("view_admins")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("view_admins")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                
                <IonGrid className="ion-no-padding">

                    <IonRow className="ion-padding">
                        <IonCol size="6" offset="3">
                            <IonCard className="action-card" routerLink={`/app/admins/add`}>
                                <IonCardContent className="ion-no-padding ion-text-center">
                                    
                                        <IonRow className='ion-padding-top'>
                                            <IonCol className="ion-no-padding">
                                                <img alt={t("add_new_admin")} src="assets/images/admin.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("add_new_admin")}</IonButton> 
                                            </IonCol>
                                        </IonRow>
                                    
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-30">
                        <IonCol>
                            <IonTitle className="border-bottom-sec">
                                {t("admins")}
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                  
                    <IonRow className="ion-margin-vertical">
                        <IonCol>
                          <IonList className="ion-no-padding">
                             {(admins.length > 0) ? (admins.map((admin, i) => {
                                    return (
                                        <IonItem detail={true}
                                            detailIcon={construct}
                                            lines='full'
                                            button={true}
                                            key={admin._id}
                                            className={((i%2) === 0) ? '' : 'alt-item'}
                                            onClick={
                                                () => history.push(`/app/admin/${admin._id}`)
                                            }>
                                            <IonIcon slot="start"
                                                icon={personCircle}
                                                color={
                                                    admin.isActive ? 'success' : 'danger'
                                                }/>
                                            <IonLabel className="list-label">
                                                <h3>{admin.name}</h3>
                                                <p>{institutes?.find(m => m._id === admin.instituteID)?.name}</p> 
                                            </IonLabel>
                                        </IonItem>
                                    )
                                })) : ( 
                                    <IonItem lines="none" className='red-card'>
                                        <IonIcon icon={alertCircleOutline}
                                        slot="start"
                                        color="danger" />
                                        <IonLabel className="list-title">
                                                <h3>{t("no_admins")}</h3></IonLabel>

                                    </IonItem>
                                )
                                } </IonList>
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default Admins;
