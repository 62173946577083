
import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonPopover,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import axios from 'axios';
import { informationCircleOutline, locationOutline, time, close, locateSharp, documentTextOutline } from 'ionicons/icons';
import { Geolocation} from '@capacitor/geolocation';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Calendar, Day } from '@hassanmojab/react-modern-calendar-datepicker';
import { Circle, GoogleMap, InfoBox, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../components/AuthContext";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';

import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';


interface LatLon {
    lat: number,
    lon: number
}
interface Employee {
    _id: string,
    userInfo: UserInfo,
    dob: Date
}
interface UserInfo {
    name: string
}
interface Attend {
    year: number,
    month: number,
    day: number,
    className: string
}

interface Attendance {
    _id: string,
    status: string,
    createdAt: Date
}

const libraries = ["places" as "places"];

const MyAttendance: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const history = useHistory();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [employee, setEmployee] = useState < Employee > ();
    const [selectedDate, setSelectedDate] = useState < string > ('');
    const [minDay, setMinDay] = useState < Day > ();
    const [maxDay, setMaxDay] = useState < Day > ();
    const [attend, setAttend] = useState < Attend[] > ([]);
    const [attendance, setAttendance] = useState < Attendance[] > ([]);
    const [mode, setMode] = useState < string > ('');
    const [instiName, setInstiName] = useState < string > ('');
    const [showModal, setShowModal] = useState < boolean > (false);
    const [locate, setLocation] = useState < LatLon > ({lat: parseInt(process.env.REACT_APP_DEFAULT_LAT!), lon: parseInt(process.env.REACT_APP_DEFAULT_LNG!)});
    const [current, setCurrent] = useState < LatLon > ();
    const mapRef = useRef < any > ();

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const options = {
        strokeColor: '#086e7d',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#086e7d',
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        radius: 20,
        zIndex: 1
    }

    const { isLoaded, loadError } = useJsApiLoader({
        id: 'google-map',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY!,
        libraries
    });

    if (loadError)
    {
        setMessage(t("maps_error"));
        setIserror(true);
    }

    const mapStyles = {        
        height: "72vh",
        width: "100%"
    };

    const handleMapLoad = (ref: any) => mapRef.current = ref;

    const fitCenter = () => {
        if(mapRef.current && current) {
            mapRef.current.setCenter({lat: current.lat, lng: current.lon });
            mapRef.current.setZoom(19);
        }
        else if(mapRef.current && locate) {
            mapRef.current.setCenter({lat: locate.lat, lng: locate.lon });
            mapRef.current.setZoom(19);
        }
        
    }

    const getCenter = () => {
        if(current) 
        {
            return {lat: current.lat, lng: current.lon };
        }
        else if(locate) {
            return {lat: locate.lat, lng: locate.lon };
        }
    }

    const handleLocation = async () => {

        if (current && employee)
        {
    
            const upAttend = () => {
                return new Promise((resolve, reject) => {
                    api.post(`/staffAttends/${mode}/${employee._id}`, { 'lat': current.lat, 'lon': current.lon }).then(res => {
                        return resolve(true);
                    }).catch(err => reject(err));
                });
            }
            
            setShowLoading(true);
            upAttend()
            .then(data => {
        
                setPass(t(mode) + t("has_been_marked"));
                setIspass(true);
                
            })
            .catch((error) => {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            })
            .finally(() => setShowLoading(false));
           
        }
    }

    const handleCurrent = async () => {
        if (isPlatform('capacitor'))
        {
            Geolocation.checkPermissions().then(async (res) => {
            if (res.location !== 'granted') {
                Geolocation.requestPermissions().then(async (ress) => {
                    if (ress.location === 'denied') {
                        setMessage(t("location_denied"));
                        setIserror(true);
                    }
                    else if (ress.location === 'granted') {
                        try {
                            const coordinates = await Geolocation.getCurrentPosition();
                            setCurrent({lat: coordinates.coords.latitude, lon: coordinates.coords.longitude});
                            setShowModal(true);

                        }
                        catch(error: any) {

                            setMessage(error.message);
                            setIserror(true);

                        }
                    }
                });
                }
                else {
                    try {
                        const coordinates = await Geolocation.getCurrentPosition();
                       
                        setCurrent({lat: coordinates.coords.latitude, lon: coordinates.coords.longitude});
                        setShowModal(true);

                    }
                    catch(error: any) {

                        setMessage(error.message);
                        setIserror(true);

                    }
                }
            }).catch(err =>  {
                setMessage(t("location_error"));
                setIserror(true);
            });
        }
       
    }

    const formatDateTime = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'long' }) + ' at ' + valueDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
    };

    const formatDate = (value: string) => {
        const monthNames = [t("jan"), t("feb"), t("mar"), t("apr"), t("may"), t("jun"),
                            t("jul"), t("aug"), t("sep"), t("oct"), t("nov"), t("dec")
                        ];

        const valueDate = new Date(value);
        return monthNames[valueDate.getMonth()] +' - '+ valueDate.getFullYear();
    };

    const getDaysInMonth = (year: number, month: number) => {
        return new Date(year, month, 0).getDate();
    };

    const handleSubmit = (theDate: string | string[]) => {

        if (typeof(theDate) === "string" && employee)
        {
            setSelectedDate(theDate);

            const onDate = new Date(theDate);

            const minDate = {
                year: onDate.getFullYear(),
                month: onDate.getMonth()+1,
                day: 1
            }

            setMinDay(minDate);

            const maxDate = {
                year: onDate.getFullYear(),
                month: onDate.getMonth()+1,
                day: getDaysInMonth(onDate.getFullYear(), onDate.getMonth()+1)
            }

            setMaxDay(maxDate);

            const fetchUp = async () => {
                setShowLoading(true);
                try {
                    const studs = await api.get(`/staffAttends/getByEmployee/${employee._id}/${theDate}`);

                    let result: Array < Attend > = [];

                    for (let i = 0; i < studs.data.length ; i++) {
                            let dated: Date = new Date(studs.data[i].dated);
                            let dateA: number = dated.getDate();

                            let calObj: Attend = {year: onDate.getFullYear(), month: onDate.getMonth()+1, day: dateA, className: studs.data[i].status}; 
                            result.push(calObj);
                    
                    }
                    setAttend(result);

                } catch (error: any) {
                    setMessage(error.response?.data.message || t("something_went_wrong"));
                    setIserror(true);
                } finally {
                    setShowLoading(false);
                }
                
            };

            fetchUp();
        }
    }

    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const result = await api.get(`/employees/getByUID/${authInfo.user._id}`);
                setEmployee(result.data);

                const insti = await api.get(`/institutes/${result.data.instituteID}`);
                setInstiName(insti.data.name);
                if (insti.data.lat && insti.data.lon)
                {
                    setLocation({lat: insti.data.lat, lon: insti.data.lon});
                }

                const onDate = new Date(Date.now());
                setSelectedDate(onDate.toISOString());
    
                const minDate = {
                    year: onDate.getFullYear(),
                    month: onDate.getMonth()+1,
                    day: 1
                }
    
                setMinDay(minDate);
    
                const maxDate = {
                    year: onDate.getFullYear(),
                    month: onDate.getMonth()+1,
                    day: getDaysInMonth(onDate.getFullYear(), onDate.getMonth()+1)
                }
    
                setMaxDay(maxDate);
    
                const studs = await api.get(`/staffAttends/getByEmployee/${result.data._id}/${onDate.toISOString()}`);
    
                let res: Array < Attend > = [];
    
                    for (let i = 0; i < studs.data.length ; i++) {
                            let dated: Date = new Date(studs.data[i].dated);
                            let dateA: number = dated.getDate();
    
                            let calObj: Attend = {year: onDate.getFullYear(), month: onDate.getMonth()+1, day: dateA, className: studs.data[i].status}; 
                            res.push(calObj);
                    
                    }

                setAttend(res);

                const attend = await api.get(`/staffAttends/getLogEmployee/${result.data._id}`);
                setAttendance(attend.data);

                const resu = await api.get(`/staffAttends/getOneByEmployee/${authInfo.user._id}`);
                if (resu.data && (resu.data.status === "in"))
                {
                    setMode("exit");
                }
                if (!resu.data)
                {
                    setMode("entry");
                }

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };
      
        fetchData();
     
    }, [authInfo]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("my_attendance")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("my_attendance")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false);
                            setShowModal(false);
                        }
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonModal isOpen={showModal}
                    onDidDismiss={
                        () => setShowModal(false)
                    }>

                    <IonContent className="grey-shade">
                        <IonHeader>
                            <IonToolbar>
                                <IonButtons slot="end">
                                    <IonButton onClick={
                                        () => setShowModal(false)
                                    }>
                                        <IonIcon icon={close} size="large" />
                                    </IonButton>
                                </IonButtons>
                                <IonTitle color="secondary">{t("mark")} {t(mode)} {t("via_location")}</IonTitle>
                            </IonToolbar>
                        </IonHeader>
                        <IonRow className='ion-no-padding ion-no-margin'>
                            <IonCol className='ion-no-padding ion-no-margin'>
                                <IonCard className="white-shade ion-no-padding ion-no-margin">
                                    <IonCardContent className='ion-no-padding ion-no-margin'>
                                        
                                            {isLoaded ? (
                                                <GoogleMap
                                                    onLoad={handleMapLoad}
                                                    mapContainerStyle={mapStyles}
                                                    zoom={19}
                                                    center={getCenter()}
                                                    clickableIcons={false}
                                                    options={{disableDefaultUI: true}}
                                                >      
                                                {current && 
                                                    <Marker zIndex={999} position={{lat: current.lat, lng: current.lon}} icon={"assets/images/dot.gif"} />
                                                }   

                                                    <Marker zIndex={45} position={{lat: locate.lat, lng: locate.lon}} 
                                                        icon={"assets/images/school-gps.png"}/>
                                                    <InfoBox
                                                        options={{closeBoxURL: ''}}
                                                        position={new window.google.maps.LatLng(locate.lat, locate.lon)}
                                                        >
                                                        <div style={{ backgroundColor: '#086e7d', opacity: 1, padding: 2, width: '80px' }}>
                                                            <div style={{ fontSize: 11, color: `#FFFFFF`, fontWeight: 600 }}>
                                                            {instiName}
                                                            </div>
                                                        </div>
                                                    </InfoBox>
                                                    <Circle
                                                        center={{lat: locate.lat, lng: locate.lon}}
                                                        options={options}
                                                    />

                                                    <IonIcon onClick={fitCenter} size="large" icon={locateSharp} className="bottom-right" />

                                                </GoogleMap>
                                            ) : ( 
                                                <div className="load-body">
                                                    <div className="load-wrapper">
                                                        <span className="circle circle-1"></span>
                                                        <span className="circle circle-2"></span>
                                                        <span className="circle circle-3"></span>
                                                        <span className="circle circle-4"></span>
                                                        <span className="circle circle-5"></span>

                                                    </div>
                                                    <div className="load-title">
                                                    {t("loading_maps")}
                                                    </div>
                                                </div>

                                                )}
                                            
                                    

                                    </IonCardContent>
                                </IonCard> 
                            </IonCol>
                        </IonRow>
                        <IonRow className='ion-margin-vertical'>
                            <IonCol className="ion-text-center">
                                <IonButton color="primary" onClick={handleCurrent}>{t("move_dot")}</IonButton>    
                            </IonCol>
                        </IonRow>
                       
                    </IonContent>
                    <IonFooter className='ion-no-padding footer-shade'>
                        <IonGrid>
                            <IonRow>
                                <IonCol className="ion-text-center">
                                    <IonButton size="small" className='first-button' fill="clear" onClick={handleLocation}>{t("mark")} {t(mode)}</IonButton>

                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonFooter>
                </IonModal>

                <IonGrid className="ion-no-padding mb-60">
                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonButton color="primary" expand='block' className="ion-padding-horizontal" onClick={() => history.push('/app/user/leaves')}> 
                                <IonIcon icon={documentTextOutline}
                                    className="note-icon" slot='start'/>{t("my_leaves")}</IonButton>    
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {t("attendance_log")}
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>

                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">
                                        <IonItem detail={false}
                                            lines='full'
                                            button={true}
                                            id="open-date-input">
                                            <IonLabel position="floating" color="secondary">{t("month")}</IonLabel>
                                            <IonInput value={selectedDate === '' ? t("pick_month") : formatDate(selectedDate)} readonly={true} />
                                            <IonPopover trigger="open-date-input" showBackdrop={false} size='cover'>
                                                <IonDatetime
                                                cancelText={t("cancel")}
                                                doneText={t("ok")}
                                                    value={selectedDate}
                                                    presentation="month-year"
                                                    onIonChange={ev => handleSubmit(ev.detail.value!)}
                                                    showDefaultButtons={true}
                                                    size="cover"
                                                />
                                            </IonPopover>
                                        </IonItem>


                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    
                    <IonRow className="ion-margin-top ion-padding">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">

                                    <Calendar 
                                        value={minDay}
                                        minimumDate={minDay}
                                        maximumDate={maxDay}
                                        shouldHighlightWeekends={true}
                                        customDaysClassName={attend}
                                        calendarSelectedDayClassName="selected-day"
                                        calendarClassName="custom-calendar"
                                    />
                            
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonRow>
                                <IonCol size="4" className="box">
                                    <div className="present-info"></div>{t("present")}
                                </IonCol>
                                <IonCol size="4" className="box">
                                    <div className="absent-info"></div>{t("absent")}
                                </IonCol>
                                <IonCol size="4" className="box">
                                    <div className="late-info"></div>{t("late")}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="4" className="box">
                                    <div className="half-info"></div>{t("half")}
                                </IonCol>
                                <IonCol size="4" className="box">
                                    <div className="leave-info"></div>{t("leave")}
                                </IonCol>
                                <IonCol size="4" className="box">
                                    <div className="holiday-info"></div>{t("holiday")}
                                </IonCol>
                            </IonRow>
                            
                        </IonCol>
                    </IonRow>
                    {(mode !== "") && (<>
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard className='note-card-1'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonRow>
                                        <IonCol size="1">
                                            <IonIcon icon={informationCircleOutline}
                                                color="secondary" 
                                                className="note-icon" />
                                        </IonCol>
                                        <IonCol size="11" className='note-text pl-08'>
                                            <p>
                                            {t("attendance_note")}
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonButton color="primary" expand='block' className="ion-padding-horizontal" onClick={handleCurrent}> 
                                <IonIcon icon={locationOutline}
                                    className="note-icon" slot='start'/>{t("mark")} {t(mode)} {t("via_location")}</IonButton>    
                        </IonCol>
                    </IonRow>
                    </>)}
                    {(attendance.length > 0) && (
                    <>
                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonTitle className='title-heading'>
                                {t("my_attend_log")}
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-vertical">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                          <IonList className="ion-no-padding">
                             {(attendance.map((attend, i) => {
                                    return (
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            key={attend._id}
                                            className={((i%2) === 0) ? '' : 'alt-item'}
                                           >
                                            <IonIcon slot="start"
                                                icon={time}
                                                color={
                                                    ((attend.status === 'in')) ? 'success' : 'danger'
                                                }/>
                                                <IonLabel className="list-label text-capitalize ion-text-wrap">
                                                    <h3>
                                                    {(attend.status === 'out') && (
                                                        <p>{t("exit_at")} {formatDateTime(attend.createdAt)}</p>
                                                    )}
                                                    {(attend.status === 'in') && (
                                                        <p>{t("entry_at")} {formatDateTime(attend.createdAt)}</p>
                                                    )}
                                                  </h3>
                                                </IonLabel>
                                                
                                        </IonItem>
                                    )
                                }))
                                } 
                            </IonList>
                        </IonCol>
                    </IonRow>
                    </>
                    )}
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default MyAttendance;
