import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, card, cash, ticket } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchStudent from '../../../components/SwitchStudent';

interface Due {
    _id: string,
    adminID: string,
    amount: number,
    remarks: string,
    receiptID?: string,
    dueDate: Date
}

interface Transaction {
    _id: string,
    mode: string,
    amount: number,
    remarks: string,
    dated: Date
}

interface Student {
    _id: string,
    name: string
}

const Dues: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const [trans, setTrans] = useState < Due[] > ([]);
    const [transactions, setTransactions] = useState < Transaction[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);
    const [student, setStudent] = useState < Student > ();
    const [mode, setMode] = useState < string > ("dues");

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const getDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    useEffect(() => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const student = await api.get(`/students/getByParent/${authInfo.user._id}`);
                setStudent(student.data);

                const result = await api.get(`/dues/getByStudent/${student.data._id}`);
                setTrans(result.data);

                const res = await api.get(`/transactions/getByStudent/${student.data._id}`);
                setTransactions(res.data);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo, refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/home`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("transactions")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="white-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("transactions")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <SwitchStudent refreshed={refreshed} setRefreshed={setRefreshed} />

                <IonGrid className="ion-no-padding">
                    <IonRow>
                        <IonCol>
                            <IonSegment mode="md" scrollable={false} value={mode} onIonChange={e => setMode( e.detail.value!.toString() )} >
                                <IonSegmentButton value="dues" >
                                    <IonLabel>{t("dues")}</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="payments" >
                                    <IonLabel>{t("payments")}</IonLabel>
                                </IonSegmentButton>
                             </IonSegment>
                        </IonCol>
                    </IonRow>

                    {(mode === 'dues') && (
                    <IonRow className="mb-60">
                        <IonCol>
                          <IonList className="ion-no-padding">
                             {(trans.length > 0) ? (trans.map((tran, i) => {
                                    return (
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            key={tran._id}
                                            className='transact-item'
                                           >
                                            <IonIcon slot="start"
                                                icon={ticket}
                                                color={(tran.receiptID) || (tran.amount < 0) ? 'success' : 'danger'}
                                                />
                                            <IonLabel className="transact-label ion-text-wrap">
                                                <h3>{tran.remarks}</h3>
                                                <p>{t("due_on")} {tran.dueDate && getDate(tran.dueDate)}</p>
                                            </IonLabel>
                                            <IonLabel className="ion-text-right transact-amt">
                                                <h3>&#8377; {tran.amount}</h3>
                                                
                                            </IonLabel>
                                        </IonItem>
                                    )
                                })) : ( 
                                    <IonCard className='red-card ion-margin'>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_dues")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                )
                                } </IonList>
                        </IonCol>
                    </IonRow>
                    )}
                    {(mode === 'payments') && (
                    <IonRow className="mb-60">
                        <IonCol>
                          <IonList className="ion-no-padding">
                             {(transactions.length > 0) ? (transactions.map((tran, i) => {
                                    return (
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            key={tran._id}
                                            className='transact-item'
                                           >
                                            <IonIcon slot="start"
                                                icon={tran.mode === "online" ? card : cash}
                                                color='tertiary'
                                                />
                                            <IonLabel className="transact-label">
                                                <h3>{tran.remarks}</h3>
                                                <p>{t("paid_on")} {tran.dated && getDate(tran.dated)}</p>
                                            </IonLabel>
                                            <IonLabel className="ion-text-right transact-amt">
                                                <h3>&#8377; {Math.abs(tran.amount)}</h3>
                                                <p><Link className='transact-link' to={`/app/receipt/${tran._id}`}>{t("view_receipt")}</Link></p>
                                            </IonLabel>
                                        </IonItem>
                                    )
                                })) : ( 
                                    <IonCard className='red-card ion-margin'>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_payments")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                )
                                } </IonList>
                        </IonCol>
                    </IonRow>
                    )}


                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default Dues;
