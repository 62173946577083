import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCheckbox,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToggle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';

interface Session {
    _id: string,
    name: string,
    isActive: boolean
}
interface ClassInterface {
    _id: string,
    sessionID: string,
    name: string,
    year: string,
    isActive: boolean,
    isChecked: boolean
}
interface Department {
    _id: string,
    name: string
}

const AddEmployee: React.FC = () => {
    const {register, authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [phone, setPhone] = useState < string > ("");
    const [email, setEmail] = useState < string > ("");
    const [newPass, setNewPass] = useState < string > ("");
    const [role, setRole] = useState < string > ("");
    const [selectedDate, setSelectedDate] = useState < string | string[] | undefined > ();
    const [gender, setGender] = useState < string > ("");
    const [address, setAddress] = useState < string > ("");
    const [designation, setDesignation] = useState < string > ("");
    const [qualification, setQualification] = useState < string > ("");
    const [joiningDate, setJoiningDate] = useState < string | string[] | undefined > ();
    const [departments, setDepartments] = useState < Department[] > ([]);
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [classes, setClasses] = useState < ClassInterface[] > ([]);
    const [classi, setClassi] = useState < string > ("none");
    const [session, setSession] = useState < string > ("");
    const [addSession, setAddSession] = useState < string > ("");
    const [addClasses, setAddClasses] = useState < ClassInterface[] > ([]);
    const [additional, setAdditional] = useState < boolean > (false);
    const [account, setAccount] = useState < boolean > (false);
    const [license, setLicense] = useState < string > ("");
    const [department, setDepartment] = useState < string > ("");
    const [transport, setTransport] = useState < boolean > (false);
    const [payment, setPayment] = useState < boolean > (false);
    const [admn, setAdmn] = useState < boolean > (false);
    const [crm, setCrm] = useState < boolean > (false);
    const [invent, setInvent] = useState < boolean > (false);
    const [acad, setAcad] = useState < boolean > (false);
    const [roles, setRoles] = useState < string[]> ([]);
    const [bankName, setBankName] = useState < string > ("");
    const [branchName, setBranchName] = useState < string > ("");
    const [accountNo, setAccountNo] = useState < string > ("");
    const [ifscCode, setIfscCode] = useState < string > ("");
    const [enrollNo, setEnrollNo] = useState < string > ("");

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const validateEmail = (email : string) => { 
        // eslint-disable-next-line no-control-regex
        const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
        return re.test(String(email).toLowerCase());
    };

    const validatePhone = (phone : string) => { 
        // eslint-disable-next-line no-control-regex
        const re = /^[0-9]{10}$/;
        return re.test(phone);
    };

    const formatDate = (value: string | string[] | undefined) => {
        if (typeof value === "string") 
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
        else
        {
            return t("pick_date");
        }
    };

    const handleSession = (sessionID : string) => {

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                
                    const classy = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${sessionID}`);
                    let classic: Array < ClassInterface > = [];
                    for (let i = 0; i < classy.data.length; i++) {
                        classic.push({'_id': classy.data[i]._id, 'sessionID': classy.data[i].sessionID, 'name': classy.data[i].name, 'year': classy.data[i].year, 'isActive': classy.data[i].isActive, 'isChecked' : false}); 
                    }
                    setClasses(classic);

                    if (!addClasses.some(e => e.sessionID === sessionID)) {
                        let classicx: Array < ClassInterface > = [...addClasses, ...classic];
                        setAddClasses(classicx);
                    }

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();
        setSession(sessionID);
    }

    const handleClassi = (value: string) => {

        if (value !== 'none')
        {
            let classic: Array < ClassInterface > = [...addClasses];
            const i = classic.findIndex(val => val._id === value);
            if (i > -1) {
                classic[i] = {'_id': addClasses[i]._id, 'sessionID': addClasses[i].sessionID, 'name': addClasses[i].name, 'year': addClasses[i].year, 'isActive': addClasses[i].isActive, 'isChecked' : true};    
                
              }
            setAddClasses(classic);
        }
        else
        {
            setAdditional(false);
        }
        setClassi(value);
    }

    const handleAddSession = (sessionID : string) => {

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                if (!addClasses.some(e => e.sessionID === sessionID)) {
                    const classy = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${sessionID}`);
                    let classic: Array < ClassInterface > = [...addClasses];
                    for (let i = 0; i < classy.data.length; i++) {
                        classic.push({'_id': classy.data[i]._id, 'sessionID': classy.data[i].sessionID, 'name': classy.data[i].name, 'year': classy.data[i].year, 'isActive': classy.data[i].isActive, 'isChecked' : false}); 
                    }
                    setAddClasses(classic);
                }

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();
        setAddSession(sessionID);
    }

    const handleClassChecked = (value: boolean, i : number) => {

        let classic: Array < ClassInterface > = [...addClasses];
        classic[i] = {'_id': addClasses[i]._id, 'sessionID': addClasses[i].sessionID, 'name': addClasses[i].name, 'year': addClasses[i].year, 'isActive': addClasses[i].isActive, 'isChecked' : value};    
        setAddClasses(classic);
    }

    const handleChecked = (value: string) => {

        let rolled = [...roles];

        if(rolled.includes(value))
        {
            const index = rolled.indexOf(value);
            if (index > -1) { // only splice array when item is found
                rolled.splice(index, 1); // 2nd parameter means remove one item only
            }
        }
        else
        {
            rolled.push(value);
            if (value === "Teacher") {
                const index = rolled.indexOf("Academic");
                if (index > -1) { // only splice array when item is found
                    rolled.splice(index, 1); // 2nd parameter means remove one item only
                }
            }
            if (value === "Academic") {
                const index = rolled.indexOf("Teacher");
                if (index > -1) { // only splice array when item is found
                    rolled.splice(index, 1); // 2nd parameter means remove one item only
                }
            }
            if (value === "SalesRep") {
                const index = rolled.indexOf("AdmissionHead");
                if (index > -1) { // only splice array when item is found
                    rolled.splice(index, 1); // 2nd parameter means remove one item only
                }
            }
            if (value === "AdmissionHead") {
                const index = rolled.indexOf("SalesRep");
                if (index > -1) { // only splice array when item is found
                    rolled.splice(index, 1); // 2nd parameter means remove one item only
                }
            }
        }

        setRoles(rolled);
     }

    const handleSubmit = async () => {

        if (!name || name === "") {
            setMessage(t("name_mandatory"));
            setIserror(true);
            return;
        }

        if (!address || address === "") {
            setMessage(t("address_mandatory"));
            setIserror(true);
            return;
        }

        if (!gender || gender === "") {
            setMessage(t("gender_mandatory"));
            setIserror(true);
            return;
        }

        if (!department || department === "") {
            setMessage(t("department_mandatory"));
            setIserror(true);
            return;
        }

        if (typeof selectedDate !== "string")
        {
            setMessage(t("dob_mandatory"));
            setIserror(true);
            return;
        }

        if (validateEmail(email) === false) {
            setMessage(t("invalid_email"));
            setIserror(true);
            return;
        }

        if (validatePhone(phone) === false) {
            setMessage(t("invalid_mobile"));
            setIserror(true);
            return;
        }

        if (account)
        {
            if (!role || role === "") {
                setMessage(t("role_mandatory"));
                setIserror(true);
                return;
            }
    
            if (!newPass || newPass.length < 8) {
                setMessage(t("invalid_password"));
                setIserror(true);
                return;
            }

            if (role === "Driver")
            {
                if (!license || license === "") {
                    setMessage(t("license_mandatory"));
                    setIserror(true);
                    return;
                }
            }

            if (role === "Employee")
            {
                if (roles.length < 2) {
                    setMessage(t("select_multiple_roles"));
                    setIserror(true);
                    return;
                }
            }
        }

        const departmentName = departments.find(val => val._id === department)!.name;

        let joining = "";
        if (typeof joiningDate === "string")
        {
           joining = joiningDate;
        }

        let userID = "none";
        let roleModel = "none";
        let licenseNo = "none";

        setShowLoading(true);
        if (account)
        {
            try {
                const user = await register(email, newPass, name, phone, role, authInfo.user.instituteID, roles);
                userID = user;
                roleModel = role;

                if (role === "Driver")
                {
                    licenseNo = license;
                }

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
        }
              
        const addEmployee = (userID: string, dob: string, classID: string, role: string, departmentID: string) => {
            return new Promise((resolve, reject) => {
                api.post('/employees/add', { 'instituteID': authInfo.user.instituteID, userID, name, role, phone, email, departmentID, departmentName, enrollNo,
                gender, address, dob, joining, designation, qualification, licenseNo, classID, additional, classes: addClasses, accountNo, bankName, branchName, ifscCode }).then(res => {
        
                    return resolve(res.data);
                
                }).catch(err => reject(err));
    
            });
        }       
            
        if (!account || (account && userID !== "none"))
        {
            addEmployee(userID, selectedDate, classi, roleModel, department)
            .then(data => {

                setPass(name+t("has_been_added"));
                setIspass(true);
            
            })
            .catch((error) => {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            })
            .finally(() => setShowLoading(false));
        }

    }

    useEffect(() => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const res = await api.get(`/sessions/getAllInstitute/${authInfo.user.instituteID}`);
                setSessions(res.data);
                setClasses([]);

                const resu = await api.get(`/departments/getAllInstitute/${authInfo.user.instituteID}`);
                setDepartments(resu.data);

                setTransport(authInfo.institute.transport);
                setPayment(authInfo.institute.payment);
                setAdmn(authInfo.institute.admission);
                setCrm(authInfo.institute.whatsapp);
                setInvent(authInfo.institute.inventory);
                setAcad(authInfo.institute.academics);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authInfo]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/staff`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("add_employee")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("add_employee")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                
                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => history.push(`/app/employees`)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding">
                    <IonRow className="ion-padding-vertical max-xl">
                        <IonCol sizeLg="6" size="12">
                            <IonRow className="ion-margin-top">
                                <IonCol>
                                    <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                        {t("basic_info")}
                                    </IonTitle>
                                </IonCol>
                            </IonRow>
                            
                            <IonRow className="ion-margin-top">
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("employee_name")}*
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("employee_name")}
                                                        value={name}
                                                        onIonInput={
                                                            (e) => setName(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("employee_gender")}*
                                                    </IonLabel>
                                                    <IonSelect value={gender}
                                                        className="input-field"
                                                        placeholder={t("employee_gender")}
                                                        cancelText={t("cancel")}
                                                        okText={t("ok")}
                                                        onIonChange={
                                                            (e) => setGender(e.detail.value)
                                                        }>
                                                    
                                                        <IonSelectOption value="male">{t("male")}</IonSelectOption>
                                                        <IonSelectOption value="female">{t("female")}</IonSelectOption> 
                                                        <IonSelectOption value="other">{t("other")}</IonSelectOption> 
                                                        
                                                    </IonSelect>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                    lines='full'
                                                    button={false}
                                                    id="open-date-input">
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("employee_dob")}*
                                                    </IonLabel>
                                                    <IonInput value={formatDate(selectedDate)} className="input-field" readonly={true} />
                                                    <IonModal trigger="open-date-input" showBackdrop={true} 
                                                        initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                        <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                            <IonToolbar>
                                                                <IonTitle>{t("employee_dob")}</IonTitle>
                                                            </IonToolbar>
                                                            <IonDatetime
                                                                value={selectedDate}
                                                                presentation="date"
                                                                cancelText={t("cancel")}
                                                                doneText={t("done")}
                                                                onIonChange={ev => setSelectedDate(ev.detail.value!)}
                                                                showDefaultButtons={true}
                                                                size="cover"
                                                            />
                                                        </IonContent>
                                                    </IonModal>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("employee_address")}*
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("employee_address")}
                                                        value={address}
                                                        onIonInput={
                                                            (e) => setAddress(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("employee_dep")}*
                                                </IonLabel>
                                                    <IonSelect value={department}
                                                            className="input-field"
                                                            placeholder={t("employee_dep")}
                                                            cancelText={t("cancel")}
                                                            okText={t("ok")}
                                                            onIonChange={
                                                                (e) => setDepartment(e.detail.value)
                                                        }>

                                                            {departments.length > 0 && departments.map((dep) => (
                                                                (<IonSelectOption key={dep._id} value={dep._id}>{dep.name}</IonSelectOption>)
                                                            ))}

                                                            
                                                    </IonSelect>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("enrollment_no")}
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("enrollment_no")}
                                                        value={enrollNo}
                                                        onIonInput={
                                                            (e) => setEnrollNo(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("employment_info")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("designation")}
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("designation")}
                                                        value={designation}
                                                        onIonInput={
                                                            (e) => setDesignation(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                    lines='full'
                                                    button={false}
                                                    id="open-join-input">
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("joining_date")}
                                                    </IonLabel>
                                                    <IonInput value={formatDate(joiningDate)} className="input-field" readonly={true} />
                                                    <IonModal trigger="open-join-input" showBackdrop={true} 
                                                        initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                        <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                            <IonToolbar>
                                                                <IonTitle>{t("joining_date")}</IonTitle>
                                                            </IonToolbar>
                                                            <IonDatetime
                                                                value={joiningDate}
                                                                presentation="date"
                                                                cancelText={t("cancel")}
                                                                doneText={t("done")}
                                                                onIonChange={ev => setJoiningDate(ev.detail.value!)}
                                                                showDefaultButtons={true}
                                                                size="cover"
                                                            />
                                                        </IonContent>
                                                    </IonModal>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("qualification")}
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("qualification")}
                                                        value={qualification}
                                                        onIonInput={
                                                            (e) => setQualification(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>

                            <IonRow className="ion-margin-top">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("bank_info")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("account_no")}
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("account_no")}
                                                        value={accountNo}
                                                        onIonInput={
                                                            (e) => setAccountNo(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("ifsc_code")}
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("ifsc_code")}
                                                        value={ifscCode}
                                                        onIonInput={
                                                            (e) => setIfscCode(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("bank_name")}
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("bank_name")}
                                                        value={bankName}
                                                        onIonInput={
                                                            (e) => setBankName(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("branch_name")}
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("branch_name")}
                                                        value={branchName}
                                                        onIonInput={
                                                            (e) => setBranchName(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
           
                        </IonCol>
                        <IonCol sizeLg="6" size="12">
                            <IonRow className="ion-margin-top">
                                <IonCol>
                                    <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                        {t("employee_account")}
                                    </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("employee_phone")}*
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("employee_phone")}
                                                        value={phone}
                                                        onIonInput={
                                                            (e) => setPhone(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("employee_email")}*
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("employee_email")}
                                                        value={email}
                                                        onIonInput={
                                                            (e) => setEmail(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol >
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                lines='full'
                                                button={false}
                                                color="primary"
                                                >
                                                <IonLabel className="input-label">{t("create_account")}</IonLabel>
                                                <IonToggle color="light" checked={account} onIonChange={e => setAccount(e.detail.checked)} />
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            {account && (
                            <>
                            <IonRow className="ion-margin-top">
                                <IonCol >
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("employee_role")}*
                                                    </IonLabel>
                                                    <IonSelect value={role}
                                                        className="input-field"
                                                        cancelText={t("cancel")}
                                                        okText={t("ok")}
                                                        placeholder={t("employee_role")}
                                                        onIonChange={
                                                            (e) => setRole(e.detail.value)
                                                        }>

                                                        <IonSelectOption value="Teacher">{t("teacher")}</IonSelectOption> 
                                                        {acad && (
                                                        <IonSelectOption value="Academic">{t("academic_incharge")}</IonSelectOption> 
                                                        )}
                                                        {transport && (<>
                                                        <IonSelectOption value="Driver">{t("driver")}</IonSelectOption> 
                                                        <IonSelectOption value="Transporter">{t("transport_incharge")}</IonSelectOption> 
                                                        </>)}
                                                        {payment && (
                                                        <IonSelectOption value="Accountant">{t("accountant")}</IonSelectOption>
                                                        )}
                                                        {(admn || crm) && (
                                                        <IonSelectOption value="AdmissionHead">{t("admission_head")}</IonSelectOption> 
                                                        )}
                                                        {(crm) && (
                                                        <IonSelectOption value="SalesRep">{t("sales_rep")}</IonSelectOption> 
                                                        )}
                                                        {invent && (
                                                        <IonSelectOption value="Inventory">{t("inventory_manager")}</IonSelectOption> 
                                                        )}
                                                        <IonSelectOption value="Employee">{t("multiple_roles")}</IonSelectOption>

                                                    </IonSelect>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            {(role === "Employee") && (                      
                            <IonRow>
                                <IonCol >
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonList className="ion-no-padding">
                                               
                                                    <IonItem 
                                                        detail={false}
                                                        lines='full'
                                                        button={false}>
                                                        <IonCheckbox slot="start" value="Teacher" checked={roles.includes("Teacher")} 
                                                            onClick={e => handleChecked("Teacher")}/>
                                                        <IonLabel>{t("teacher")}</IonLabel>
                                                    </IonItem>
                                                    {acad && (
                                                    <IonItem 
                                                        detail={false}
                                                        lines='full'
                                                        button={false}>
                                                        <IonCheckbox slot="start" value="Academic" checked={roles.includes("Academic")} 
                                                            onClick={e => handleChecked("Academic")}/>
                                                        <IonLabel>{t("academic_incharge")}</IonLabel>
                                                    </IonItem>
                                                    )}
                                                    {transport && (
                                                    <IonItem 
                                                        detail={false}
                                                        lines='full'
                                                        button={false}>
                                                        <IonCheckbox slot="start" value="Transporter" checked={roles.includes("Transporter")} 
                                                            onClick={e => handleChecked("Transporter")}/>
                                                        <IonLabel>{t("transport_incharge")}</IonLabel>
                                                    </IonItem>
                                                    )}
                                                    {payment && (
                                                    <IonItem 
                                                        detail={false}
                                                        lines='full'
                                                        button={false}>
                                                        <IonCheckbox slot="start" value="Accountant" checked={roles.includes("Accountant")} 
                                                            onClick={e => handleChecked("Accountant")}/>
                                                        <IonLabel>{t("accountant")}</IonLabel>
                                                    </IonItem>
                                                    )}
                                                    {(admn || crm) && (
                                                    <IonItem 
                                                        detail={false}
                                                        lines='full'
                                                        button={false}>
                                                        <IonCheckbox slot="start" value="AdmissionHead" checked={roles.includes("AdmissionHead")} 
                                                            onClick={e => handleChecked("AdmissionHead")}/>
                                                        <IonLabel>{t("admission_head")}</IonLabel>
                                                    </IonItem>
                                                    )}
                                                    {(crm) && (
                                                    <IonItem 
                                                        detail={false}
                                                        lines='full'
                                                        button={false}>
                                                        <IonCheckbox slot="start" value="SalesRep" checked={roles.includes("SalesRep")} 
                                                            onClick={e => handleChecked("SalesRep")}/>
                                                        <IonLabel>{t("sales_rep")}</IonLabel>
                                                    </IonItem>
                                                    )}
                                                    {invent && (
                                                    <IonItem 
                                                        detail={false}
                                                        lines='full'
                                                        button={false}>
                                                        <IonCheckbox slot="start" value="Inventory" checked={roles.includes("Inventory")} 
                                                            onClick={e => handleChecked("Inventory")}/>
                                                        <IonLabel>{t("inventory_manager")}</IonLabel>
                                                    </IonItem>
                                                    )}
                                                   
                                            </IonList>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )} 
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("set_pass")}*
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("set_pass")}
                                                        value={newPass}
                                                        onIonInput={
                                                            (e) => setNewPass(e.detail.value !)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                           
                           
                            {(role === "Driver") && (
                            <>
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("driver_details")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("driving_license")}
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("driving_license")}
                                                        value={license}
                                                        onIonInput={
                                                            (e) => setLicense(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            </>
                            )}
                            {((role === "Teacher") || ((role === "Employee") && (roles.includes("Teacher")))) && (
                            <>
                            <IonRow className="mt-30">
                                <IonCol>
                                    <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                    {(authInfo.institute.type === "school") ? t("teacher_class") : t("teacher_batch")}
                                    </IonTitle>
                                </IonCol>
                                
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol size="6">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("class_session")}*
                                                </IonLabel>
                                                    <IonSelect value={session}
                                                            className="input-field"
                                                            placeholder={t("class_session")}
                                                            cancelText={t("cancel")}
                                                            okText={t("ok")}
                                                            onIonChange={
                                                                (e) => handleSession(e.detail.value)
                                                        }>

                                                            {sessions.length > 0 && sessions.map((session) => (
                                                                (session.isActive) && 
                                                                (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                            ))}

                                                            
                                                    </IonSelect>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol size="6">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                {(authInfo.institute.type === "school") ? t("select_main_class") : t("select_main_batch")}*
                                                </IonLabel>
                                                    <IonSelect value={classi}
                                                            className="input-field"
                                                            placeholder={(authInfo.institute.type === "school") ? t("select_main_class") : t("select_main_batch")}
                                                            cancelText={t("cancel")}
                                                            okText={t("ok")}
                                                            onIonChange={
                                                                (e) => handleClassi(e.detail.value)
                                                        }>
                                                        <IonSelectOption value="none">{t("none")}</IonSelectOption>
                                                            {session && classes.map((classy) => 
                                                             (classy.isActive) &&
                                                              (<IonSelectOption key={classy._id} value={`${classy._id}`}>{classy.name}</IonSelectOption>)
                                                            )}
                                                    </IonSelect>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>

                            {classi && (classi !== "none") && 
                            (   
                            <IonRow>
                                <IonCol >
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                lines='full'
                                                button={false}
                                                color="primary"
                                                >
                                                <IonLabel className="input-label">{(authInfo.institute.type === "school") ? t("assign_additional_classes") : t("assign_additional_batches")}</IonLabel>
                                                <IonToggle color="light" checked={additional} onIonChange={e => setAdditional(e.detail.checked)} />
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )}

                            {additional && (
                            <>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("class_session")}*
                                                </IonLabel>
                                                    <IonSelect value={addSession}
                                                            className="input-field"
                                                            placeholder={t("class_session")}
                                                            cancelText={t("cancel")}
                                                            okText={t("ok")}
                                                            onIonChange={
                                                                (e) => handleAddSession(e.detail.value)
                                                        }>

                                                            {sessions.length > 0 && sessions.map((session) => (
                                                                (session.isActive) && 
                                                                (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                            ))}

                                                            
                                                    </IonSelect>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            {(addSession !== "") && 
                            (<IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonList>

                                    
                                                {(addSession !== "") && addClasses.map((classii, i) => (
                                        
                                                    (classii.isActive && classii.sessionID === addSession) &&
                                                    (<IonItem key={classii._id}
                                                        detail={false}
                                                        lines='full'
                                                        button={false}>
                                                        <IonCheckbox slot="start" value={classii._id} checked={classii.isChecked} disabled={classii._id === classi}
                                                            onIonChange={e => handleClassChecked(e.detail.checked, i)}/>
                                                        <IonLabel>{classii.name}</IonLabel>
                                                    </IonItem>)
                                                ))}
                                        </IonList>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>)
                            }
                            </>
                            )}

                        </>
                        )}
                        
                        </>
                        )}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-padding-horizontal ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear"
                                >{t("add_employee")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
               
        </IonPage>
    );
};

export default AddEmployee;
