import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import { alertCircleOutline, clipboardOutline, informationCircleOutline, star, starOutline } from 'ionicons/icons';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import {Calendar, Day} from '@hassanmojab/react-modern-calendar-datepicker';
import { useAuth } from "../../../../components/AuthContext";
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';

import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';

interface Report {
    _id: string,
    employee: Employee,
    work: string,
    files?: Attach[],
    response?: string,
    rating?: number
}

interface Employee {
    employeeID: string,
    name: string
}

interface Attach {
    caption: string,
    filename: string
}

interface Attend {
    year: number,
    month: number,
    day: number,
    className: string
}

const ViewReports: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [selectedDate, setSelectedDate] = useState < string > ('');
    const [minDay, setMinDay] = useState < Day > ();
    const [maxDay, setMaxDay] = useState < Day > ();
    const [selectedDay, setSelectedDay] = useState < Day > ();
    const [attend, setAttend] = useState < Attend[] > ([]);
    const [reports, setReports] = useState < Report[] > ([]);

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const formatDated = (value: Day) => {
        return value.day+"/"+value.month+"/"+value.year;
    };

    const formatDate = (value: string) => {
            const monthNames = [t("jan"), t("feb"), t("mar"), t("apr"), t("may"), t("jun"),
                            t("jul"), t("aug"), t("sep"), t("oct"), t("nov"), t("dec")
                            ];

            const valueDate = new Date(value);
            return monthNames[valueDate.getMonth()] +' - '+ valueDate.getFullYear();
    };

    const getDaysInMonth = (year: number, month: number) => {
        return new Date(year, month, 0).getDate();
    };
    
    const handleDate = async (dated : Day) => {

        setSelectedDay(dated);

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const res = await api.post('/dailyReports/getByDay', {'instituteID': authInfo.user.instituteID, 'day': dated});
                if (res.data)
                {
                    setReports(res.data);
                }
                else
                {
                    setReports([]);
                }

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();     
    }

    const handleSubmit = (theDate: string | string[]) => {

        if (typeof(theDate) === "string")
        {
            setSelectedDate(theDate);

            const onDate = new Date(theDate);

            const aajDate = new Date(Date.now());

            const minDate = {
                day: 1,
                month: onDate.getMonth()+1,
                year: onDate.getFullYear()
                
            }

            let maxDate : Day;

            if ((aajDate.getFullYear() === onDate.getFullYear()) && (aajDate.getMonth() === onDate.getMonth()))
            {
                maxDate = {
                    day: onDate.getDate()+1,
                    month: onDate.getMonth()+1,
                    year: onDate.getFullYear()
                }
            }
            else
            {
                maxDate = {
                    day: getDaysInMonth(onDate.getFullYear(), onDate.getMonth()+1),
                    month: onDate.getMonth()+1,
                    year: onDate.getFullYear()
                }
            }

            const fetchUp = async () => {
                setShowLoading(true);
                try {
                    const events = await api.get(`/dailyReports/getByMonth/${authInfo.user.instituteID}/${theDate}`);

                    let result: Array < Attend > = [];

                    for (let i = 0; i < events.data.length ; i++) {
                        let dated: Date = new Date(events.data[i].calendarDate);
                        let dateA: number = dated.getDate();

                        let calObj: Attend = {year: onDate.getFullYear(), month: onDate.getMonth()+1, day: dateA, className: "eventDay"}; 
                        result.push(calObj);
                
                    }
                    setAttend(result);
                    setMinDay(minDate);
                    setMaxDay(maxDate);
                    setSelectedDay(minDate);

                    const res = await api.post('/dailyReports/getByDay', {'instituteID': authInfo.user.instituteID, 'day': minDate});
                    if (res.data)
                    {
                        setReports(res.data);
                    }
                    else
                    {
                        setReports([]);
                    }
                
                } catch (error: any) {
                    setMessage(error.response?.data.message || t("something_went_wrong"));
                    setIserror(true);
                } finally {
                    setShowLoading(false);
                }
                
            };

            fetchUp();
        }
    }

    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const onDate = new Date(Date.now());
                setSelectedDate(onDate.toISOString());
    
                const minDate = {
                    day: 1,
                    month: onDate.getMonth()+1,
                    year: onDate.getFullYear()
                }
    
                const maxDate = {
                    day: onDate.getDate(),
                    month: onDate.getMonth()+1,
                    year: onDate.getFullYear()
                }

                const events = await api.get(`/dailyReports/getByMonth/${authInfo.user.instituteID}/${onDate.toISOString()}`);

                let result: Array < Attend > = [];

                for (let i = 0; i < events.data.length ; i++) {
                    let dated: Date = new Date(events.data[i].calendarDate);
                    let dateA: number = dated.getDate();

                    let calObj: Attend = {year: onDate.getFullYear(), month: onDate.getMonth()+1, day: dateA, className: "eventDay"}; 
                    result.push(calObj);
            
                }
                setAttend(result);
                setMinDay(minDate);
                setMaxDay(maxDate);
                setSelectedDay(maxDate);

                const res = await api.post('/dailyReports/getByDay', {'instituteID': authInfo.user.instituteID, 'day': maxDate});

                setReports(res.data);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };
      
        fetchData();
     
    }, [authInfo]);


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/staff`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("view_reports")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("view_reports")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                
                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding mb-60">
                    <IonRow className='ion-margin-top'>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard className='note-card-1'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonRow>
                                        <IonCol size="1">
                                            <IonIcon icon={informationCircleOutline}
                                                color="secondary" 
                                                className="note-icon" />
                                        </IonCol>
                                        <IonCol size="11" className='note-text pl-08'>
                                            <p>
                                                {t("reports_note")}
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>

                                <IonCardContent className="ion-no-padding">

                                        <IonItem detail={false}
                                            lines='full'
                                            button={true}
                                            id="open-date-input">
                                            <IonLabel position="floating" color="secondary">{t("month")}</IonLabel>
                                            <IonInput value={selectedDate === '' ? t("pick_month") : formatDate(selectedDate)} readonly={true} />
                                            <IonPopover trigger="open-date-input" showBackdrop={false} size='cover'>
                                                <IonDatetime
                                                    cancelText={t("cancel")}
                                                    doneText={t("ok")}
                                                    value={selectedDate}
                                                    presentation="month-year"
                                                    max={new Date(Date.now()).toISOString()}
                                                    onIonChange={ev => handleSubmit(ev.detail.value!)}
                                                    showDefaultButtons={true}
                                                    size="cover"
                                                />
                                            </IonPopover>
                                        </IonItem>

                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    
                    <IonRow className="ion-padding">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                        {(minDay && maxDay) && (
                   
                            <Calendar 
                                value={selectedDay}
                                onChange={handleDate}
                                minimumDate={minDay}
                                maximumDate={maxDay}
                                shouldHighlightWeekends={true}
                                customDaysClassName={attend}
                                calendarClassName="custom-calendar"
                            />
                       
                         )}
                        </IonCol>
                    </IonRow>
                    {(selectedDay && (
                    <>
                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {t("reports_for")} {formatDated(selectedDay)}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                        {
                            (reports.length > 0) ? (reports.map((report) => {
                                return (
                                <IonCard className="tertiary-card" key={report._id} routerLink={`/app/report/${report._id}`} >
                                    <IonCardContent className='ion-no-padding'>
                                        <IonItem detail={true}
                                                lines='none'
                                                button={false}
                                                
                                                >
                                                <IonIcon slot="start"
                                                    icon={clipboardOutline}
                                                    color="tertiary"
                                                    />
                                                    <IonLabel>
                                                            
                                                        <IonRow>
                                                            <IonCol>
                                                            
                                                                {report.employee.name}
                                                                
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow className='vid-responsive-field-2 text-capitalize'>
                                                            <IonCol>
                                                                <IonLabel>
                                                                {!report.rating && (
                                                                        <>
                                                                         <IonIcon
                                                                             icon={starOutline}
                                                                             color="medium" 
                                                                         />
                                                                         <IonIcon
                                                                             icon={starOutline}
                                                                             color="medium"
                                                                         />
                                                                         <IonIcon
                                                                             icon={starOutline}
                                                                             color="medium" 
                                                                         />
                                                                         <IonIcon
                                                                             icon={starOutline}
                                                                             color="medium" 
                                                                         />
                                                                         <IonIcon
                                                                             icon={starOutline}
                                                                             color="medium" 
                                                                         />
                                                                     </>
                                                                    )}
                                                                    {report.rating && (report.rating === 1) && (
                                                                        <>
                                                                         <IonIcon
                                                                             icon={star}
                                                                             color="dark" 
                                                                         />
                                                                         <IonIcon
                                                                             icon={starOutline}
                                                                             color="medium"
                                                                         />
                                                                         <IonIcon
                                                                             icon={starOutline}
                                                                             color="medium" 
                                                                         />
                                                                         <IonIcon
                                                                             icon={starOutline}
                                                                             color="medium" 
                                                                         />
                                                                         <IonIcon
                                                                             icon={starOutline}
                                                                             color="medium" 
                                                                         />
                                                                     </>
                                                                    )}
                                                                    {report.rating && (report.rating === 2) && (
                                                                        <>
                                                                            <IonIcon
                                                                                icon={star}
                                                                                color="dark" 
                                                                            />
                                                                            <IonIcon
                                                                                icon={star}
                                                                                color="dark"
                                                                            />
                                                                            <IonIcon
                                                                                icon={starOutline}
                                                                                color="medium" 
                                                                            />
                                                                            <IonIcon
                                                                                icon={starOutline}
                                                                                color="medium" 
                                                                            />
                                                                            <IonIcon
                                                                                icon={starOutline}
                                                                                color="medium" 
                                                                            />
                                                                        </>
                                                                    )}
                                                                    {report.rating && (report.rating === 3) && (
                                                                        <>
                                                                         <IonIcon
                                                                             icon={star}
                                                                             color="dark" 
                                                                         />
                                                                         <IonIcon
                                                                             icon={star}
                                                                             color="dark"
                                                                         />
                                                                         <IonIcon
                                                                             icon={star}
                                                                             color="dark" 
                                                                         />
                                                                         <IonIcon
                                                                             icon={starOutline}
                                                                             color="medium" 
                                                                         />
                                                                         <IonIcon
                                                                             icon={starOutline}
                                                                             color="medium" 
                                                                         />
                                                                     </>
                                                                    )}
                                                                    {report.rating && (report.rating === 4) && (
                                                                        <>
                                                                            <IonIcon
                                                                                icon={star}
                                                                                color="dark" 
                                                                            />
                                                                            <IonIcon
                                                                                icon={star}
                                                                                color="dark"
                                                                            />
                                                                            <IonIcon
                                                                                icon={star}
                                                                                color="dark" 
                                                                            />
                                                                            <IonIcon
                                                                                icon={star}
                                                                                color="dark" 
                                                                            />
                                                                            <IonIcon
                                                                                icon={starOutline}
                                                                                color="medium" 
                                                                            />
                                                                        </>
                                                                    )}
                                                                    {report.rating && (report.rating === 5) && (
                                                                        <>
                                                                            <IonIcon
                                                                                icon={star}
                                                                                color="dark" 
                                                                            />
                                                                            <IonIcon
                                                                                icon={star}
                                                                                color="dark"
                                                                            />
                                                                            <IonIcon
                                                                                icon={star}
                                                                                color="dark" 
                                                                            />
                                                                            <IonIcon
                                                                                icon={star}
                                                                                color="dark" 
                                                                            />
                                                                            <IonIcon
                                                                                icon={star}
                                                                                color="dark" 
                                                                            />
                                                                        </>
                                                                    )}
                                                                    
                                                                </IonLabel>
                                                                    
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonLabel>
                                            </IonItem>
                                    </IonCardContent>
                                </IonCard>
                                        );
                                        })) : (
                                            <IonCard className='red-card'>
                                                <IonCardContent className='ion-no-margin ion-no-padding ion-padding-vertical'>
                                                    <IonItem lines="none">
                                                        <IonIcon icon={alertCircleOutline}
                                                            slot="start"
                                                            color="danger" />
                                                        <IonLabel className="list-title"><h3>{t("no_reports")}</h3></IonLabel>
                                                    </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        )
                                    }

                        </IonCol>
                    </IonRow>

                </>
                ))}

                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default ViewReports;
